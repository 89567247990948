import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import _ from 'lodash';

import { connect } from 'react-redux'
import { fetchQuote, saveQuote } from '../redux/actions/quote'
import { getQuote } from '../redux/reducers'

export const mapStateToProps = (state, ownProps) => {
  return {
    quote: getQuote(state),
  }
}

export class LoginPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      show: true
    };
  }
  handleClose = () => {
    const show = false;
    this.setState({ show });
  }
  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);

  }

  componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);

  }

  setQuoteState(quote) {
    this.setState({
      // coverages: !!quote.coverages ? quote.coverages : coverages,
      // cyber: !!quote.cyber ? quote.cyber : cyber,
      ...quote
    });
  }

  render() {
    return (
      this.state.show ?
        <Container className="LoginPrompt" fluid>
          <Row className="LoginPrompt__header no-gutters">
            <Col xs={"auto"}><i className="fas fa-exclamation-circle"></i></Col>
            <Col className="col"><strong>Do we know you?</strong></Col>
            <Col xs={"auto"}><i className="fas fa-times-circle" onClick={this.handleClose}></i></Col>
          </Row>
          <Row className="LoginPrompt__bodyWrapper no-gutters">
            <Col>
              <Row className="LoginPrompt__body">
                <Col>An account with <span>{this.state.email}</span> has already been created. Please login to view, edit or create existing or new policies.</Col>
              </Row>
              <Row className="LoginPrompt__buttons no-gutters">
                <Col><Link to="/login" className="btn btn-tertiary success"><i className="fas fa-sign-in-alt"></i>Log In</Link></Col>
              </Row>
            </Col>
          </Row>
        </Container>
        :
        null
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote, saveQuote
})(LoginPrompt);