import { combineReducers } from 'redux';

import {
    FETCH_USER_SUCCESS,
    SET_USER_SUCCESS
} from '../actionTypes';

export const user = (state = {}, action) => {
    switch (action.type) {
        case SET_USER_SUCCESS:
            if (action.response) {
                return {
                    ...state,
                    user: action.response
                };
            }
            return state;
        case FETCH_USER_SUCCESS:
            if (action.response) {
                return {
                    ...state,
                    user: action.response
                };
            }
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    user
});

export const getUser = state => state.user.user;
