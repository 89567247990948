import Axios from "axios";
import { parse } from "fast-xml-parser";
// import xml2js from "xml2js";
//TODO: parse fast-xml-parser is breaking on GetDSSigningURLResult retrieval.  Could possibly be the cause of other breaks elsewhere.  Monitor
export default class FirstAPI {
  static post = async (requestXML, url, raw = false) => {
    const headers = {
      "Content-Type": "text/xml",
    };
    if (raw) {
      return Axios.post(url, requestXML, {
        headers: headers,
        crossDomain: true,
      })
        .then((response) => response.data)
        .catch((e) => this.writeToLogs(e, url, requestXML, "_FirstAPI_post_raw"));
    }
    return Axios.post(url, requestXML, {
      headers: headers,
      crossDomain: true,
    })
      .then((response) => response.data)
      .then((textResponse) => {
        const parsedResponse = parse(textResponse);
        if (JSON.stringify(parsedResponse).includes("NaN")) {
          // console.log({ parsedResponse });
          this.writeToLogs(JSON.stringify(parsedResponse), url, requestXML, "_FirstAPI_post_not_raw_NaN");
        }
        return parsedResponse;
      })
      .catch((e) => this.writeToLogs(e, url, requestXML, "_FirstAPI_post_not_raw"));
  };

  static writeToLogs = async (e, action, requestXML, append = "_FirstAPI_post") => {
    const page = window.location.href;
    const params = JSON.stringify({
      page,
      action,
      requestXML,
      error: e.response.data,
      logger: true,
      first: append,
    });
    Axios.post("https://" + window.location.host + "/logs/", params, {
      headers: { "Content-Type": "application/json" },
      crossDomain: true,
    });
  };

  static getQuoteNumber = async (quote, locale = "EN") => {
    // console.log('importing quote');
    // console.log(quote);

    let quote_xml = `&lt;?xml version="1.0" encoding="utf-8" standalone="yes" ?&gt; &lt;tq:QuoteInfo xmlns:tq="TemporaryQuote"&gt;&lt;tq:Quote&gt;&lt;tq:CustomerInfo Quoting_For="${
      process.env.REACT_APP_FIRST_QUOTING_FOR
    }" Agent_Code="${process.env.REACT_APP_FIRST_AGENT_CODE}" Name_1="${quote.FirstName} ${quote.Last}" Name_2="" Contact_Name="${quote.FirstName} ${
      quote.Last
    }" Address_Line_1="${quote.Street}" Address_Line_2="" City="${quote.City}" Region="${quote.Province}" Postal_Code="${
      quote.PostalCode
    }" Country="CA" E-Mail="${quote.email}" Origination_Code="Advocis" Quote_Profile="Commercial" Main_Phone="" Broker_Contact="${
      process.env.REACT_APP_FIRST_BROKER_CONTACT
    }"  Preferred_Language="${locale === "EN" ? "English" : "Francais"}" Pricing_Program="${
      process.env.REACT_APP_FIRST_PRICING_PROGRAM
    }" Customer_ID="${quote.clientID}" Regular_Continuous_Pay="Regular" Create_Quote="true" /&gt;&lt;tq:Policy_Count&gt;${
      quote.activePolicies.length
    }&lt;/tq:Policy_Count&gt;&lt;tq:Policy Policy_Number="${quote.activePolicies[0]}" Coverage_Code="E&amp;amp;O" Effective_Date="${
      quote.startDate
    }" Policy_Term="12" Days_To_Cancel="5" Carrier_Code="${process.env.REACT_APP_FIRST_CARRIER_CODE}" GA_Code="${
      process.env.REACT_APP_FIRST_MGA_CODE
    }" Premium="${quote.pre_tax_total}" Minimum_Earned_Premium_Percent="" Financed_Taxes_Fees="${
      quote.taxes
    }" Earned_Taxes_Fees=""/&gt;&lt;tq:TermsInfo Billing_Method="ACH" /&gt;&lt;/tq:Quote&gt;&lt;/tq:QuoteInfo&gt;`;

    // console.log(quote_xml);

    let xml = `<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:inp="http://input1.com/">
    <x:Header/>
    <x:Body>
        <inp:ImportQuote>
            <inp:authInfo>
                <inp:UserName>${process.env.REACT_APP_FIRST_QUOTE_USER}</inp:UserName>
                <inp:UserPassword>${process.env.REACT_APP_FIRST_QUOTE_PASSWORD}</inp:UserPassword>
                <inp:PortfolioCode>901</inp:PortfolioCode>
                <inp:UserType>Entity</inp:UserType>
            </inp:authInfo>
            <inp:qiRequest>
                <inp:XmlQuoteImport>${quote_xml}</inp:XmlQuoteImport>
                <inp:Options>
                    <inp:ReturnQuoteInfo>false</inp:ReturnQuoteInfo>
                    <inp:ReturnPFA>false</inp:ReturnPFA>
                </inp:Options>
            </inp:qiRequest>
        </inp:ImportQuote>
    </x:Body>
</x:Envelope>`;
    // console.log(xml);
    let response = await this.post(xml, process.env.REACT_APP_FIRST_QUOTE_URL);
    let error = false;
    if (response) {
      if (response["soap:Envelope"]) {
        if (response["soap:Envelope"]["soap:Body"]) {
          if (response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]) {
            if (response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]["ImportQuoteResult"]) {
              if (
                response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]["ImportQuoteResult"]["QuoteInformation"] &&
                response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]["ImportQuoteResult"]["Errors"] === ""
              ) {
                if (response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]["ImportQuoteResult"]["QuoteInformation"]["QuoteNumber"]) {
                  return {
                    quoteNumber:
                      response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]["ImportQuoteResult"]["QuoteInformation"]["QuoteNumber"],
                  };
                }
              } else {
                return { error: response["soap:Envelope"]["soap:Body"]["ImportQuoteResponse"]["ImportQuoteResult"]["Errors"] };
              }
            }
          }
        }
      }
    }
    return error;
  };

  static getPaymentIframe = async (quote) => {
    // console.log('getting payment iframe');
    let policies = quote.activePolicies.join(",");
    const callBackUrl = new URL(process.env.REACT_APP_FIRST_PAYMENT_CALLBACK_URL);
    callBackUrl.searchParams.append("policies", policies);

    let url = callBackUrl;

    let quote_xml = `
    &lt;CreditPaymentInfoRequest&gt;
&lt;QuoteNumber&gt;${quote.firstQuoteNumber}&lt;/QuoteNumber&gt;
&lt;Amount&gt;${quote.total}&lt;/Amount&gt;
&lt;CustomerId&gt;${quote.clientID}&lt;/CustomerId&gt;
&lt;InsuredName&gt;${quote.FirstName} ${quote.Last}&lt;/InsuredName&gt;
&lt;Email&gt;${quote.email}&lt;/Email&gt;
&lt;Address&gt;${quote.Street}&lt;/Address&gt;
&lt;City&gt;${quote.City}&lt;/City&gt;
&lt;Province&gt;${quote.Province}&lt;/Province&gt;
&lt;Zip&gt;${quote.PostalCode}&lt;/Zip&gt;
&lt;Phone&gt;&lt;/Phone&gt;
&lt;Lang&gt;en&lt;/Lang&gt;
&lt;BrokerEmail&gt;${process.env.REACT_APP_FIRST_BROKER_CONTACT}&lt;/BrokerEmail&gt;
&lt;Callback&gt;${url}&lt;/Callback&gt;
&lt;RedirectUrl&gt;https://www.firstinsurancefunding.ca&lt;/RedirectUrl&gt;
&lt;/CreditPaymentInfoRequest&gt;
`;
    let xml = `<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="https://policypayments.com/NewPolicyPayment/Service">
    <x:Header/>
    <x:Body>
        <ser:GetCreditCardPaymentURL>
            <ser:auth>
                <ser:UserName>${process.env.REACT_APP_FIRST_PAYMENT_USER}</ser:UserName>
                <ser:Password>${process.env.REACT_APP_FIRST_PAYMENT_PASSWORD}</ser:Password>
            </ser:auth>
            <ser:creditPaymentInfo>${quote_xml}</ser:creditPaymentInfo>
        </ser:GetCreditCardPaymentURL>
    </x:Body>
</x:Envelope>`;
    // console.log(xml);
    let response = await this.post(xml, process.env.REACT_APP_FIRST_PAYMENT_URL);
    if (response) {
      if (response["soap:Envelope"]) {
        if (response["soap:Envelope"]["soap:Body"]) {
          if (response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]) {
            if (response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]["GetCreditCardPaymentURLResult"]) {
              if (
                response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]["GetCreditCardPaymentURLResult"]["Errors"] &&
                response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]["GetCreditCardPaymentURLResult"]["Errors"] !== ""
              ) {
                return {
                  error: response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]["GetCreditCardPaymentURLResult"]["Errors"],
                };
              } else if (
                response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]["GetCreditCardPaymentURLResult"]["CreditCardPaymentURL"]
              ) {
                let url =
                  response["soap:Envelope"]["soap:Body"]["GetCreditCardPaymentURLResponse"]["GetCreditCardPaymentURLResult"]["CreditCardPaymentURL"];
                // console.log({ url });
                return { url };
              }
            }
          }
        }
      }
    }
    return false;
  };

  static getFinancingIframe = async (quote) => {
    // console.log('getting finance iframe');
    let policies = quote.activePolicies.join(",");
    const callBackUrl = new URL(process.env.REACT_APP_FIRST_PAYMENT_CALLBACK_URL);
    callBackUrl.searchParams.append("policies", policies);

    let url = callBackUrl;

    let xml = `<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="https://policypayments.com/NewPolicyPayment/Service">
    <x:Header/>
    <x:Body>
        <ser:GetDSSigningURL>
            <ser:auth>
                <ser:UserName>${process.env.REACT_APP_FIRST_PAYMENT_USER}</ser:UserName>
                <ser:Password>${process.env.REACT_APP_FIRST_PAYMENT_PASSWORD}</ser:Password>
            </ser:auth>
            <ser:quoteNumber>${quote.firstQuoteNumber}</ser:quoteNumber>
            <ser:callbackURL>${url}</ser:callbackURL>
        </ser:GetDSSigningURL>
    </x:Body>
</x:Envelope>`;
    let response = await this.post(xml, process.env.REACT_APP_FIRST_PAYMENT_URL, true);
    // let paymentIframeUrl = response.replace('</GetDSSigningURLResult></GetDSSigningURLResponse></soap:Body></soap:Envelope>', '');
    // paymentIframeUrl = paymentIframeUrl.replace('<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><GetDSSigningURLResponse xmlns="https://policypayments.com/NewPolicyPayment/Service"><GetDSSigningURLResult>', '');
    // paymentIframeUrl = paymentIframeUrl.replace('<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body><GetDSSigningURLResponse xmlns="https://policypayments.com/NewPolicyPayment/Service"><GetDSSigningURLResult>', '');
    // console.log(response);
    const rgxp = new RegExp(`.*<.*GetDSSigningURLResult>(.*)</.*GetDSSigningURLResult>.*`);
    const match = response.match(rgxp);
    // console.log({ match });
    if (match && match[1]) {
      return { url: match[1] };
    }
    return false;
  };
}
