import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel, Row, Col, Container } from "react-bootstrap";
import LoaderButton from "../components/loader";
import { clearForms, clearFormLines } from "../redux/actions/forms";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchPricing } from "../redux/actions/pricing";
import { fetchQuote, saveQuote, clearQuote } from "../redux/actions/quote";
import { clearPricing } from "../redux/actions/pricing";
import { getDiscounts, getPricing, getQuote, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";
import moment from "moment";
import "moment/locale/fr";

// import { First } from 'react-bootstrap/PageItem'
// import FirstAPI from '../Assets/api/first'
import EpicAPI from "../Assets/api/epic";
//import EpicAPI from '../Assets/api/epic'

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    discounts: getDiscounts(state),
    pricing: getPricing(state),
  };
};

export class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      Last: "",
      Middle: "",
      email: "",
    };
  }

  async componentDidMount() {
    const { quote, fetchQuote, clearQuote, clearForms, clearFormLines, clearPricing, pricing, fetchPricing, locale, fetchLocale } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();

    if (!locale) fetchLocale();
    fetchPricing();
    // console.log('clearing state data');
    clearForms();
    clearFormLines();
    clearQuote();
    clearPricing();
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setState({ quote });
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.email.trim().length > 0 &&
      this.state.FirstName.length > 0 &&
      this.state.FirstName.trim().length > 0 &&
      this.state.Last.length > 0 &&
      this.state.Last.trim().length > 0
    );
  }

  handleChange = (event) => {
    const str = event.target.value;
    this.setState({
      [event.target.id]: str,
    });
  };

  formatName = (value) => {
    // const regName = /[^a-zA-Z]/g;
    // value = value.replace(regName, "");
    // value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    // value = xmlescape(value.replace(/\s+/g, ""));
    value = value.trim();
    return value;
  };

  handleSubmit = async (event) => {
    const { saveQuote } = this.props;
    //const { FirstName, Last, email } = this.state;
    event.preventDefault();
    let FirstName = this.formatName(this.state.FirstName);
    let Last = this.formatName(this.state.Last);
    let email = this.state.email;
    this.setState({ isLoading: true });

    this.setState({ FirstName, Last, email });

    try {
      saveQuote({ FirstName, Last, email });
      this.setState({ isLoading: false });
      this.props.history.push("/quote");
    } catch (e) {
      // console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { locale } = this.props;
    return (
      <Container className="Landing" fluid>
        <Row>
          <Col lg={6} className="landingFormWrap PrintFormGroup">
            <Row className="landingForm">
              <Col lg={8} xs={10} className="Form">
                <h1 className="chevron">{translateString(locale, "Renew or Get a Quote")}</h1>
                <p>
                  <strong>{translateString(locale, "Who is this policy for?")}</strong>
                </p>
                <ul>
                  <li>{translateString(locale, "Individual E & O for financial advisors & financial planners")}</li>
                  <li>{translateString(locale, "Includes personal corporation coverage (conditions apply)")}</li>
                  <li>
                    {translateString(
                      locale,
                      "Coverage for life insurance/mutual funds, IIROC licensees, Unlicensed Planners/Fee for Service, Licensed Administrative Assistants"
                    )}
                  </li>
                </ul>
                <p>
                  <strong>{translateString(locale, "Start your quote now by just entering the information below.")}</strong>
                </p>
                <form onSubmit={this.handleSubmit}>
                  <Row className="nameFields">
                    <Col sm={6}>
                      <FormGroup controlId="FirstName">
                        <FormLabel>{translateString(locale, "Legal First Name")}</FormLabel>
                        <FormControl autoFocus type="text" value={this.state.FirstName} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup controlId="Last">
                        <FormLabel>{translateString(locale, "Last Name")}</FormLabel>
                        <FormControl type="text" value={this.state.Last} onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup controlId="email">
                    <FormLabel>{translateString(locale, "Your email")}</FormLabel>
                    <FormControl value={this.state.email} onChange={this.handleChange} type="email" />
                  </FormGroup>
                  <LoaderButton
                    block
                    disabled={!this.validateForm()}
                    type="submit"
                    text={translateString(locale, "Renew or Get a Quote Now")}
                    isLoading={this.state.isLoading}></LoaderButton>
                </form>
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="landingTextWrap">
            <Col lg={9} className="landingText">
              <h2 className="h1">{translateString(locale, "Why Choose Advocis?")}</h2>
              <h3>{translateString(locale, "Coverage & Benefits")}</h3>
              <ul>
                <li>
                  {translateString(
                    locale,
                    "Exclusive Loyalty Discount (applies to all insurance coverages, Insuring Agreement A, B, C Trust & Estate Practitioners and Family Enterprise Advisors included, and D)"
                  )}
                </li>
                <li>{translateString(locale, "Automatic Security & Privacy insurance protection with limits up to $250,000 in coverage")}</li>
                <li>
                  {translateString(
                    locale,
                    "A partnership with Advocis Broker Services goes beyond just having access to industry leading risk management E&O coverage. Through our affiliation with Advocis, Advisors will also be able to register for professional practice development courses that will help satisfy all of your continuing education requirements."
                  )}
                </li>
              </ul>
              <a className="btn btn-secondary black" href="https://advocisinsurance.ca/personal-coverage/" target="_blank" rel="noreferrer">
                {translateString(locale, "Learn More")}
              </a>

              <h3>{translateString(locale, "Zero Deductible")}</h3>
              <p>
                {translateString(
                  locale,
                  "If you have a claim involving sales and service of a life insurance product or a mutual fund investment, your claim will not be subject to a deductible."
                )}
              </p>
              <a className="btn btn-secondary black" href="https://advocisinsurance.ca/personal-coverage/" target="_blank" rel="noreferrer">
                {translateString(locale, "Learn More")}
              </a>

              <h3>{translateString(locale, "The Advocis Advantage")}</h3>
              <p>{translateString(locale, "Our program for financial advisors and planners offers up to $10 million of coverage.")}</p>
              <p>
                {translateString(
                  locale,
                  "We work with our partners throughout the year to enhance the coverage offerings of our program, so that your coverage evolves and grows with changes in our industry."
                )}
              </p>
              <a className="btn btn-secondary black" href="https://advocisinsurance.ca/personal-coverage/" target="_blank" rel="noreferrer">
                {translateString(locale, "Learn More")}
              </a>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  clearQuote,
  clearForms,
  clearFormLines,
  clearPricing,
  fetchPricing,
})(Landing);
