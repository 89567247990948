import React, { Component } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import AccountSidebar from "../../components/AccountSidebar";
import { Row, Col, Container } from "react-bootstrap";

export default class AccountInformation extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    // console.log(event);
  }

  render() {
    return (
      <div className="account account--payments">
        <Breadcrumb />

        <Container className="personalInformation" as="section">
          <Container style={{ paddingLeft: "0px" }}>
            <Row noGutters>
              <Col sm="2">{}</Col>
              <Col style={{ paddingLeft: "0.3rem" }}>
                <h1 className="chevron">Personal Information</h1>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col sm={2}>
              <AccountSidebar current="account" />
            </Col>
            <Col sm={10}>
              <Row noGutters>
                <Col sm={3}>
                  <span>Account Holder</span>
                </Col>
                <Col>
                  <span class="userName">Joseph Public</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr className="dotted" />
                </Col>
              </Row>
              <Row noGutters>
                <h4>Contact Information</h4>
              </Row>
              <div className="personalInformationFields">
                <div className="PrintFormGroup">
                  <div
                    data-parent="personalInformation"
                    data-id="emailAddress"
                    className="form-group"
                  >
                    <Row>
                      <Col sm={3}>
                        <label className="form-label" htmlFor="emailAddress">
                          Email Address
                        </label>
                      </Col>
                      <Col>
                        <div className="text FieldWrapper">
                          <input
                            name="emailAddress"
                            type="text"
                            onChange={this.handleChange}
                            id="emailAddress"
                            className="form-control"
                            value=""
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    data-parent="personalInformation"
                    data-id="phoneNumber"
                    className="form-group"
                  >
                    <Row>
                      <Col sm={3}>
                        <label className="form-label" htmlFor="phoneNumber">
                          Phone number
                        </label>
                      </Col>
                      <Col>
                        <div className="text FieldWrapper">
                          <input
                            name="phoneNumber"
                            type="text"
                            onChange={this.handleChange}
                            id="phoneNumber"
                            className="form-control"
                            value=""
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    data-parent="personalInformation"
                    data-id="bestTimeToCall"
                    className="form-group"
                  >
                    <Row>
                      <Col sm={3}>
                        <label className="form-label" htmlFor="bestTimeToCall">
                          Best time to call
                        </label>
                      </Col>
                      <Col>
                        <div className="select FieldWrapper">
                          <select
                            name="bestTimeToCall"
                            placeholder=""
                            id="bestTimeToCall"
                            className="form-control"
                          >
                            <option value="default">Morning</option>
                            <option value="afternoon">Afternoon</option>
                            <option value="evening">Evening</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    data-parent="personalInformation"
                    data-id="preferredContactMethod"
                    className="form-group"
                  >
                    <Row>
                      <Col sm="3">
                        <label
                          class="form-label"
                          htmlFor="preferredContactMethod"
                        >
                          Preferred contact method
                        </label>
                      </Col>
                      <Col>
                        <div className="buttons FieldWrapper">
                          <div role="group" className="radioWrap btn-group">
                            <button
                              name="preferredContactMethod"
                              type="button"
                              className="active btn btn-radioButtons"
                            >
                              Phone
                            </button>
                            <button
                              name="preferredContactMethod"
                              type="button"
                              className="btn btn-radioButtons"
                            >
                              Email
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    data-parent="personalInformation"
                    data-id="receiveEmails"
                    className="form-group"
                  >
                    <Row>
                      <Col sm="3">
                        <label class="form-label" htmlFor="receiveEmails">
                          Receive marketing emails
                        </label>
                      </Col>
                      <Col>
                        <div className="buttons FieldWrapper">
                          <div role="group" className="radioWrap btn-group">
                            <button
                              name="receiveEmails"
                              type="button"
                              className="active btn btn-radioButtons"
                            >
                              Yes
                            </button>
                            <button
                              name="receiveEmails"
                              type="button"
                              className="btn btn-radioButtons"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col>
                      <hr className="dotted" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Discounts</h4>
                    </Col>
                  </Row>
                  <div
                    data-parent="personalInformation"
                    data-id="memberNumber"
                    className="form-group"
                  >
                    <Row>
                      <Col sm={3}>
                        <label className="form-label" htmlFor="memberNumber">
                          Advocis Member #
                        </label>
                      </Col>
                      <Col>
                        <div className="text FieldWrapper">
                          <input
                            name="memberNumber"
                            type="text"
                            onChange={this.handleChange}
                            id="memberNumber"
                            className="form-control"
                            value=""
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    data-parent="personalInformation"
                    data-id="yearLicensed"
                    className="form-group"
                  >
                    <Row>
                      <Col sm="3">
                        <label class="form-label" htmlFor="yearLicensed">
                          Year licensed
                        </label>
                      </Col>
                      <Col>
                        <div className="select FieldWrapper">
                          <select
                            name="yearLicensed"
                            placeholder=""
                            id="yearLicensed"
                            className="form-control"
                          >
                            <option value="default">Select...</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col>
                      <hr className="dotted" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button
                        style={{ marginTop: "1rem" }}
                        class="btn btn-primary"
                      >
                        Save Changes
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
