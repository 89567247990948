const checkout_data =
  [
    {
      Answer1: [{
        details: [{
          controlId: "Answer1",
          formLabel: "In the last five years, have you or any of your employees been the recipient of any allegations of professional negligence in writing or verbally?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      Answer2: [{
        details: [{
          controlId: "Answer2",
          formLabel: "Are you or any of your employeees aware of any facts, circumstances or situations which may reasonably give rise to a claim, other than as advised above?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      Answer3: [{
        details: [{
          controlId: "Answer3",
          formLabel: "In the last five years, has there been, or is there now, pending against you any suit(s), proceedings, claim(s), fact(s), or situations(s) that would have been covered by this insurance?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      Answer4: [{
        details: [{
          controlId: "Answer4",
          formLabel: "Have you ever had any license suspended or cancelled by a regulatory body for improper conduct?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      Answer5: [{
        details: [{
          controlId: "Answer5",
          formLabel: "Are you currently under investigation/review by a regulatory body for improper conduct?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      Answer6: [{
        details: [{
          controlId: "Answer6",
          formLabel: "Have you ever been charged with or convicted of a dishonest or fraudulent act?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      Answer7: [{
        details: [{
          controlId: "Answer7",
          formLabel: "Have you ever been found guilty of any violation of Federal or Provincial insurance or securities law or regulation?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
  ];
export default checkout_data;
