import { combineReducers } from "redux";

import { FETCH_LOCALE_REQUEST, SET_LOCALE_SUCCESS } from "../actionTypes";

export const locale = (state = {}, action) => {
  // console.log(action.type)
  switch (action.type) {
    case FETCH_LOCALE_REQUEST:
    case SET_LOCALE_SUCCESS:
      if (action.response) {
        return {
          ...state,
          locale: action.response,
        };
      }
      // console.log("no locale response")
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  locale,
});

export const getLocale = (state) => state.locale.locale;
