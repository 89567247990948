import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBan,
  faBarcode,
  faBookOpen,
  faBullseye,
  faBurn,
  faCalendarAlt,
  faCaretDown,
  faCaretRight,
  faCheck,
  faCheckSquare,
  faCircleNotch,
  faCog,
  faDumbbell,
  faEdit,
  faExclamationTriangle,
  faHeart,
  faInfoCircle,
  faList,
  faListAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMinus,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faSearch,
  faSpinner,
  faStopwatch,
  faTachometerAlt,
  faThumbsUp,
  faTimes,
  faUserCircle,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./Assets/css/bootstrap-grid.min.css";
import "./Assets/scss/main.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import Routes from "./components/routes";
import Wrap from "./components/wrap";

library.add(
  fab,
  faCheckSquare,
  faTachometerAlt,
  faBookOpen,
  faUserCircle,
  faUtensils,
  faPlusSquare,
  faPlusCircle,
  faCalendarAlt,
  faBurn,
  faThumbsUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faExclamationTriangle,
  faCheck,
  faBan,
  faSpinner,
  faCircleNotch,
  faBullseye,
  faCog,
  faPencilAlt,
  faInfoCircle,
  faTimes,
  faMicrophone,
  faMicrophoneSlash,
  faBarcode,
  faSearch,
  faHeart,
  faList,
  faListAlt,
  faPlus,
  faMinus,
  faDumbbell,
  faStopwatch,
  faCaretRight,
  faCaretDown,
  faEdit
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "",
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {}

  handleLogout() {}

  render() {
    const location = window.location.pathname;
    const childProps = {
      handleLogout: this.handleLogout,
    };
    if (window.location.pathname.indexOf("/certificate/") > -1) {
      return (
        <Router>
          <Routes childProps={childProps} />
        </Router>
      );
    } else {
      return (
        <Router>
          <Wrap wrapClass={"wrap"}>
            <Header childProps={childProps} />
            <Routes childProps={childProps} />
            <Footer childProps={childProps} />
          </Wrap>
        </Router>
      );
    }
  }
}

export default App;
