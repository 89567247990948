const steps =
  [
    {
      name: "Who are you?",
      url: "/quote"
    },
    {
      name: "Coverages",
      url: "/quote/coverages"
    },
    {
      name: "Discounts",
      url: "/quote/discounts"
    },
    {
      name: "Review",
      url: "/quote/review"
    },
    {
      name: "Quote",
      url: "/quote/checkout"
    }
  ]
export default steps;
