export const insertAttachmentXml = (AccountID, PolicyID, AttachmentID, AttachmentSize, fileDescription) => {
  const xml = `<Insert_Attachment xmlns="http://webservices.appliedsystems.com/epic/sdk/2021/01/">
  <AttachmentObject xmlns:a="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
      <a:AccountID>${AccountID}</a:AccountID>
      <a:AccountTypeCode i:nil="true"/>
      <a:AgencyStructures i:nil="true" xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_attachment/"/>
      <a:AttachedDate i:nil="true"/>
      <a:AttachedTos xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_attachment/">
          <b:AttachedToItem>
              <b:AttachedToID>${AccountID}</b:AttachedToID>
              <b:AttachedToType>Account</b:AttachedToType>
              <b:Description/>
              <b:Flag>View</b:Flag>
          </b:AttachedToItem>
      </a:AttachedTos>
      <a:AttachmentID>0</a:AttachmentID>
      <a:Comments/>
      <a:Description>${fileDescription}</a:Description>
      <a:EditedDate i:nil="true"/>
      <a:Files xmlns:b="http://schemas.appliedsystems.com/epic/sdk/2009/07/_account/_attachment/">
          <b:FileDetailItem>
              <b:Extension>.pdf</b:Extension>
              <b:FileName>${"certificate-pol" + PolicyID + ".pdf"}</b:FileName>
              <b:Length>${AttachmentSize}</b:Length>
              <b:TicketName>${AttachmentID}</b:TicketName>
          </b:FileDetailItem>
      </a:Files>
      <a:Folder>Certificates of Insurance</a:Folder>
      <a:GeneratedByCode i:nil="true"/>
      <a:InactiveDate i:nil="true"/>
      <a:IsInactive>false</a:IsInactive>
      <a:IsSystemGenerated>false</a:IsSystemGenerated>
      <a:ReceivedDate i:nil="true"/>
      <a:SecurityAccessLevelCode/>
      <a:SubFolder1/>
      <a:SubFolder2/>
      <a:Summary i:nil="true"/>
      <a:InsertedByCode i:nil="true"/>
      <a:UpdatedByCode i:nil="true"/>
      <a:ClientAccessExpireOnDate i:nil="true"/>
      <a:ClientAccessible>true</a:ClientAccessible>
      <a:SubFolder3/>
      <a:SubFolder4/>
      <a:SubFolder5/>
      <a:AccessedDate i:nil="true"/>
      <a:DoNotPurge i:nil="true"/>
      <a:DoNotPurgeExpireOnDate i:nil="true"/>
      <a:EmployeeSignature i:nil="true"/>
      <a:ReplaceEmployeeSignature>false</a:ReplaceEmployeeSignature>
  </AttachmentObject>
</Insert_Attachment>`;
  return xml;
};
