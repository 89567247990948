import React, { Component } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import AccountSidebar from "../../components/AccountSidebar";
import { Row, Col, Container } from "react-bootstrap";

export default class AccountPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
  }

  render() {
    return (
      <div className="account account--passwordChange passwordChange">
        <Breadcrumb />

        <Container className="passwordChange" as="section">
          <Container style={{ paddingLeft: "0px" }}>
            <Row noGutters>
              <Col sm="2">{}</Col>
              <Col style={{ paddingLeft: "0.3rem" }}>
                <h1 className="chevron">Change Password</h1>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col sm={2}>
              <AccountSidebar current="account" />
            </Col>
            <Col sm={10}>
              <Row>
                <Col>
                  <hr className="dotted" />
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <button
                    style={{ marginTop: "1rem" }}
                    className="btn btn-primary ghost"
                  >
                    EMAIL PASSWORD RESET
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ paddingTop: "1rem" }}>
                    The password reset link will be sent to your current email
                    address on file. Your current email address is &nbsp;
                    <strong>john@smith.com</strong>. To change it, go to the
                    personal information page.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
