import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { clearQuote } from "../redux/actions/quote";

export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advocisMemberId: "",
      disabled: true,
    };
  }
  handleKeyPress = (e) => {
    if (e.key === "Enter" && !this.state.disabled) {
      this.goToAccountPage();
    }
  };
  handleChange = (val) => {
    const advocisMemberId = val.target.value;
    this.setState({ advocisMemberId });
    this.setState({ disabled: !this.validateForm(val.target.value) });
  };
  componentDidMount() {
    clearQuote();
  }
  goToAccountPage = () => {
    this.props.history.push("/account/" + this.state.advocisMemberId);
  };
  validateForm(id) {
    return !!id && id.length === 5;
  }
  render() {
    return (
      <div className="account account--main">
        <Container className="GetQuote">
          <Row>
            <Col md={8}>
              <div className="formDescription Line Green bottom">
                <div className="mobilePricing">
                  <h1 className="chevron">Check Member Policies</h1>
                </div>
              </div>
              <div className="PrintFormGroup">
                <div className="form-group">
                  <label className="form-label" htmlFor="advocisMemberId">
                    Member ID
                  </label>
                  <input
                    type="text"
                    id="advocisMemberId"
                    className="form-control"
                    value={this.state.advocisMemberId}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div className="FormBottomNav Line Green top">
                  <button
                    className="btn btn-primary"
                    onClick={this.goToAccountPage}
                    disabled={this.state.disabled}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
