export const ontarioStatHolidays = [
  "Sun, January 1 2023",
  // "Tue, July 25, 2023",
  "Mon, February 20 2023",
  "Fri, April 7 2023",
  "Mon, April 10 2023",
  "Mon, May 22 2023",
  "Sat, July 1 2023",
  "Mon, August 7 2023",
  "Mon, September 4 2023",
  "Sat, September 30 2023",
  "Mon, October 9 2023",
  "Sat, November 11 2023",
  "Mon, December 25 2023",
  "Tue, December 26 2023",
  "Mon, January 1 2024",
  "Mon, February 19 2024",
  "Fri, March 29 2024",
  "Mon, April 1 2024",
  "Mon, May 20 2024",
  "Mon, July 1 2024",
  "Mon, August 5 2024",
  "Mon, September 2 2024",
  "Mon, September 30 2024",
  "Mon, October 14 2024",
  "Mon, November 11 2024",
  "Wed, December 25 2024",
  "Thu, December 26 2024",
];
