import React, { Component } from "react";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import { Row, Col, Container } from "react-bootstrap";
import _licensingLifeInsuranceData, { mapLicense, mapProvince } from "../../Assets/data/licensingLifeInsuranceData";
import _licensingIIROCData from "../../Assets/data/licensingIIROCData";
import steps from "../../Assets/data/checkoutStepsData";
import PrintFormGroup from "../../components/PrintFormGroup";
import PolicyDetails from "../../components/PolicyDetails";
import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { fetchForms, saveFormLines, saveForms } from "../../redux/actions/forms";
import { getFormLines, getForms, getQuote, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from '../../Assets/helpers/translations/translations';
import _ from "lodash";
import _checkoutData from "../../Assets/data/checkoutData";
import EpicAPI from "../../Assets/api/epic";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state),
  };
};

export class Checkout extends Component {
  constructor(props) {
    super(props);
    // console.log('this.props.quote.licensedAdmin',this.props.quote.licensedAdmin);
    // console.log('_licensingLifeInsuranceData: ', _licensingLifeInsuranceData)
    // console.log('omit result: ',_.omit(_licensingLifeInsuranceData[0], ['mutualFunds']));

    this.state = {
      confirmEmail: false,
      currentStep: 2,
      steps: steps,

      // licensingLifeInsuranceData: this.props.quote.licensedAdmin === true ? [_.omit(_licensingLifeInsuranceData[0], ['mutualFunds'])] : _licensingLifeInsuranceData,

      licensingLifeInsuranceData: (() => {
        let data = _licensingLifeInsuranceData;
        if (this.props.quote && this.props.quote.licensedAdmin === true) {
          data = [_.omit(data[0], ['mutualFunds'])];
        }
        if (this.props.quote && this.props.quote.lifeInsuranceLite === true) {
          data = [_.omit(data[0], ['mutualFunds', 'segregatedFunds', 'gic'])]; 
        }
        return data;
      })(),

      // licensingLifeInsuranceData: _licensingLifeInsuranceData,
      licensingIIROCData: _licensingIIROCData,

      lifeInsuranceLicensing: _licensingLifeInsuranceData[0].lifeInsuranceLicensing[0].details[0].FormControl[0].default,
      accidentSickness: _licensingLifeInsuranceData[0].accidentSickness[0].details[0].FormControl[0].default,
      mutualFunds: _licensingLifeInsuranceData[0].mutualFunds[0].details[0].FormControl[0].default,
      segregatedFunds: _licensingLifeInsuranceData[0].segregatedFunds[0].details[0].FormControl[0].default,
      gic: _licensingLifeInsuranceData[0].gic[0].details[0].FormControl[0].default,

      securities: _licensingIIROCData[0].securities[0].details[0].FormControl[0].default,
      incomeTax: _licensingIIROCData[0].incomeTax[0].details[0].FormControl[0].default,
      executorEstate: _licensingIIROCData[0].executorEstate[0].details[0].FormControl[0].default,
      notaryPublic: _licensingIIROCData[0].notaryPublic[0].details[0].FormControl[0].default,
      commissionerOaths: _licensingIIROCData[0].commissionerOaths[0].details[0].FormControl[0].default,
      feeServicePlanning: _licensingIIROCData[0].feeServicePlanning[0].details[0].FormControl[0].default,
      divorceSpecialist: _licensingIIROCData[0].divorceSpecialist[0].details[0].FormControl[0].default,
      other: _licensingIIROCData[0].other[0].details[0].FormControl[0].default,
      otherText: "",

      prevPage: "/checkout/information",
      nextPage: "/checkout/review",
    };
  }

  componentDidMount() {
    const { quote, fetchQuote, forms, fetchForms, lines } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);

    if (lines && !_.isEmpty(lines)) {
      fetchForms(lines[0]);
      this.setState(lines);
    } else this.getLineIDs();

    if (forms && !_.isEmpty(forms)) this.setFormsState(forms);

  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, forms, lines,  locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
    if (!_.isEqual(lines, prevProps.lines)) this.setState(lines);
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);
    if (!_.isEqual(forms, prevProps.forms)) this.setFormsState(forms);
    //console.log(this.state);
  }

  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
  }

  setFormsState(forms) {
    let checkoutData = _checkoutData;
    // console.log(forms);
    this.setState({
      forms,
      checkoutData,
    });
  }

  getLineIDs = async () => {
    const { quote, saveFormLines } = this.props;
    let lines = [];
    if (quote && quote.activePolicies) {
      for (let p of quote.activePolicies) {
        let lineID = await EpicAPI.getLine(p);
        lines.push(lineID);
      }
    }
    saveFormLines(lines);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleLifeInsuranceChange = (key, val) => {
    const licensingLifeInsuranceData = this.state.licensingLifeInsuranceData;
    licensingLifeInsuranceData[0][key][0].check = val;
    this.setState({ licensingLifeInsuranceData });
    this.handleChange(key, val);
  };

  handlelicensingIIROCDataChange = (key, val) => {
    const _licensingIIROCData = this.state.licensingIIROCData;
    _licensingIIROCData[0][key][0].check = val;
    this.setState({ licensingIIROCData: _licensingIIROCData });
    this.handleChange(key, val);
  };

  validateForm() {
    return true;
  }

  handleTabSelection = (e) => {
    // console.log(this.props.quote);
    const tabLabel = e.target;
    if (!e.target.classList.contains("active")) {
      const tabId = tabLabel.getAttribute("data-tab");
      tabLabel
        .closest(".tabWrapper")
        .querySelectorAll(".tab.active, .label.active")
        .forEach((oldActive) => {
          oldActive.classList.remove("active");
        });
      tabLabel
        .closest(".tabWrapper")
        .querySelectorAll('[data-tab="' + tabId + '"]')
        .forEach((newActive) => {
          newActive.classList.add("active");
        });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(this.state);
    const { saveForms } = this.props;
    const {
      forms,
      lifeInsuranceLicensing,
      accidentSickness,
      mutualFunds,
      segregatedFunds,
      gic,
      securities,
      incomeTax,
      executorEstate,
      notaryPublic,
      commissionerOaths,
      feeServicePlanning,
      divorceSpecialist,
      lifeInsuranceLicensingProvince,
      accidentSicknessProvince,
      mutualFundsProvince,
      segregatedFundsProvince,
      gicProvince,
      securitiesProvince,
      incomeTaxProvince,
      executorEstateProvince,
      notaryPublicProvince,
      commissionerOathsProvince,
      feeServicePlanningProvince,
      divorceSpecialistProvince,
    } = this.state;


    let fields = [];
    let type = "";
    if (lifeInsuranceLicensing) {
      type = mapLicense("Life Insurance");
      _.each(lifeInsuranceLicensingProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (accidentSickness) {
      type = mapLicense("Accident and Sickness");
      // console.group('Accidental Sickness');
      _.each(accidentSicknessProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
        // console.log(type+province);
      });
      // console.groupEnd();
    }
    if (mutualFunds) {
      type = mapLicense("Mutual Funds");
      _.each(mutualFundsProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (segregatedFunds) {
      type = mapLicense("Segregated Funds");
      _.each(segregatedFundsProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (gic) {
      type = mapLicense("GIC");
      _.each(gicProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (securities) {
      type = mapLicense("Securities");
      _.each(securitiesProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (incomeTax) {
      type = mapLicense("Income Tax Preparation");
      _.each(incomeTaxProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (executorEstate) {
      type = mapLicense("Executor of Estates");
      _.each(executorEstateProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (notaryPublic) {
      type = mapLicense("Notary Public");
      _.each(notaryPublicProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (commissionerOaths) {
      type = mapLicense("Commissioner of Oaths");
      _.each(commissionerOathsProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (feeServicePlanning) {
      type = mapLicense("Fee for Service");
      _.each(feeServicePlanningProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }
    if (divorceSpecialist) {
      type = mapLicense("Certified Divorce Specialist");
      _.each(divorceSpecialistProvince, (p) => {
        let province = mapProvince(p);
        fields.push(type + province);
      });
    }

    let _forms = { ...forms };
    // console.log({fields});
    // console.log({forms});
    _.each(_forms.screens, (s) => {
      if (s.ScreenName === "Licensing &amp; Designations") {
        _.each(s.fields, (f) => {
          if (_.includes(fields, f.FieldName)) f.FieldValue = "Y";
          else f.FieldName = "";
        });
      }
    });
    // console.log({_forms});

    this.setState({ isLoading: true });
    try {
      // set isLoading to true during submitting
      this.setState({ isLoading: true });
      saveForms(_forms)
        .then((d) => this.props.history.push(this.state.nextPage))
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { licensingIIROCData, licensingLifeInsuranceData, licensedAdmin } = this.state;  
    // console.log('this.state',this.state);
    // console.log('licensedAdmin value is: ',licensedAdmin);

    const { locale } = this.props;
    return (
      <Container className="Licensing" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
            <div className="formDescription">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                <PolicyDetails />
              </div>
              <h3>{translateString(locale,"Licensing")}</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="licensingFields">
                <div className="tabWrapper">
                  <div className="tabWrapper__labels">
                    <div className="label active" data-tab="1" onClick={this.handleTabSelection}>
                      {translateString(locale, "Life Insurance")}
                    </div>
                    {!licensedAdmin && (
                    <div className="label" data-tab="2" onClick={this.handleTabSelection}>
                      {translateString(locale,"IIROC Licenses (Securities/Mutual Funds)")}
                    </div>
                    )}
                  </div>
                  <div className="tabWrapper__tabs">
                    <div className="tab active" data-tab="1">
                      <p>{translateString(locale,"Please select the areas in which you are licensed:")}</p>
                      {licensingLifeInsuranceData && (
                        <PrintFormGroup parent={"licensing1"} 
                          data={licensingLifeInsuranceData} 
                          handleChange={this.handleChange} />
                      )}
                    </div>
                    {!licensedAdmin && (
                    <div className="tab" data-tab="2">
                      <p>{translateString(locale, "Please select the areas in which you are licensed:")}</p>
                      {licensingIIROCData && <PrintFormGroup parent={"licensing2"} data={licensingIIROCData} handleChange={this.handleChange} />}
                    </div>
                    )}
                  </div>
                </div>
              </div>
              <FormBottomNav
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                prevText={translateString(locale, "Go Back")}
                prevPage={this.state.prevPage}
                className="Line Green top"></FormBottomNav>
              {/* <Link className="tempNext" to={this.state.nextPage}>
                Force Next Step
              </Link> */}
            </form>
          </Col>
          <Col lg={"auto"} className="sideBar">
            <PolicyDetails />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchForms,
  saveForms,
  saveFormLines,
})(Checkout);
