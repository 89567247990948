import _ from 'lodash';

const licensing_life = [
  {
    lifeInsuranceLicensing: [{
      details: [{
        controlId: "lifeInsuranceLicensing",
        FormControl: [{
          type: "toggle",
          formLabel: "Life Insurance",
          default: false,
          dependencies: [{
            lifeInsuranceLicensingProvince: [{
              details: [{
                condition: true,
                controlId: "lifeInsuranceLicensingProvince",
                formLabel: "Please indicate where you are licensed:",
                FormControl: [{
                  type: "checkbox",
                  checkbox: [{
                    options: [
                      "Alberta",
                      "British Columbia",
                      "Manitoba",
                      "New Brunswick",
                      "Newfoundland and Labrador",
                      "Northwest Territories",
                      "Nova Scotia",
                      "Nunavut",
                      "Ontario",
                      "Prince Edward Island",
                      "Québec",
                      "Saskatchewan",
                      "Yukon Territory"
                    ]
                  }],
                }]
              }]
            }]
          }]
        }]
      }]
    }],
    accidentSickness: [{
      details: [{
        controlId: "accidentSickness",
        FormControl: [{
          type: "toggle",
          formLabel: "Accident and Sickness",
          default: false,
          dependencies: [{
            accidentSicknessProvince: [{
              details: [{
                condition: true,
                controlId: "accidentSicknessProvince",
                formLabel: "Please indicate where you are licensed:",
                FormControl: [{
                  type: "checkbox",
                  checkbox: [{
                    options: [
                      "Alberta",
                      "British Columbia",
                      "Manitoba",
                      "New Brunswick",
                      "Newfoundland and Labrador",
                      "Northwest Territories",
                      "Nova Scotia",
                      "Nunavut",
                      "Ontario",
                      "Prince Edward Island",
                      "Québec",
                      "Saskatchewan",
                      "Yukon Territory"
                    ]
                  }],
                }]
              }]
            }]
          }]
        }]
      }]
    }],
    mutualFunds: [{
      details: [{
        controlId: "mutualFunds",
        FormControl: [{
          type: "toggle",
          default: false,
          formLabel: "Mutual Funds",
          dependencies: [{
            mutualFundsProvince: [{
              details: [{
                condition: true,
                controlId: "mutualFundsProvince",
                formLabel: "Please indicate where you are licensed:",
                FormControl: [{
                  type: "checkbox",
                  checkbox: [{
                    options: [
                      "Alberta",
                      "British Columbia",
                      "Manitoba",
                      "New Brunswick",
                      "Newfoundland and Labrador",
                      "Northwest Territories",
                      "Nova Scotia",
                      "Nunavut",
                      "Ontario",
                      "Prince Edward Island",
                      "Québec",
                      "Saskatchewan",
                      "Yukon Territory"
                    ]
                  }],
                }]
              }]
            }]
          }]
        }]
      }]
    }],
    segregatedFunds: [{
      details: [{
        controlId: "segregatedFunds",
        FormControl: [{
          type: "toggle",
          default: false,
          formLabel: "Segregated Funds",
          dependencies: [{
            segregatedFundsProvince: [{
              details: [{
                condition: true,
                controlId: "segregatedFundsProvince",
                formLabel: "Please indicate where you are licensed:",
                FormControl: [{
                  type: "checkbox",
                  checkbox: [{
                    options: [
                      "Alberta",
                      "British Columbia",
                      "Manitoba",
                      "New Brunswick",
                      "Newfoundland and Labrador",
                      "Northwest Territories",
                      "Nova Scotia",
                      "Nunavut",
                      "Ontario",
                      "Prince Edward Island",
                      "Québec",
                      "Saskatchewan",
                      "Yukon Territory"
                    ]
                  }],
                }]
              }]
            }]
          }]
        }]
      }]
    }],
    gic: [{
      details: [{
        controlId: "gic",
        FormControl: [{
          type: "toggle",
          default: false,
          formLabel: "GIC's",
          dependencies: [{
            gicProvince: [{
              details: [{
                condition: true,
                controlId: "gicProvince",
                formLabel: "Please indicate where you are licensed:",
                FormControl: [{
                  type: "checkbox",
                  checkbox: [{
                    options: [
                      "Alberta",
                      "British Columbia",
                      "Manitoba",
                      "New Brunswick",
                      "Newfoundland and Labrador",
                      "Northwest Territories",
                      "Nova Scotia",
                      "Nunavut",
                      "Ontario",
                      "Prince Edward Island",
                      "Québec",
                      "Saskatchewan",
                      "Yukon Territory"
                    ]
                  }],
                }]
              }]
            }]
          }]
        }]
      }]
    }]
  }
];
export default licensing_life;

export function mapProvince(prov) {
  let provinces = [
    { abbrev: "AB", name: "Alberta" },
    { abbrev: "BC", name: "British Columbia" },
    { abbrev: "MB", name: "Manitoba" },
    { abbrev: "NB", name: "New Brunswick" },
    { abbrev: "NL", name: "Newfoundland and Labrador" },
    { abbrev: "NWT", name: "Northwest Territories" },
    { abbrev: "NS", name: "Nova Scotia" },
    { abbrev: "NU", name: "Nunavut" },
    { abbrev: "ON", name: "Ontario" },
    { abbrev: "PEI", name: "Prince Edward Island" },
    { abbrev: "QC", name: "Québec" },
    { abbrev: "SK", name: "Saskatchewan" },
    { abbrev: "YT", name: "Yukon Territory" }
  ];
  let province = _.find(provinces, p => p.name === prov);
  return province ? province.abbrev : prov;
}

export function mapLicense(type) {
  let licenses = [
    { abbrev: "LI", name: "Life Insurance" },
    { abbrev: "AS", name: "Accident and Sickness" },
    { abbrev: "SF", name: "Segregated Funds" },
    { abbrev: "MF", name: "Mutual Funds" },
    { abbrev: "GIC", name: "GIC" },
    { abbrev: "SEC", name: "Securities" },
    { abbrev: "ITP", name: "Income Tax Preparation" },
    { abbrev: "EOE", name: "Executor of Estates" },
    { abbrev: "NP", name: "Notary Public" },
    { abbrev: "COO", name: "Commissioner of Oaths" },
    { abbrev: "FFS", name: "Fee for Service" },
    { abbrev: "CDS", name: "Certified Divorce Specialist" },
  ];
  let license = _.find(licenses, p => p.name === type);
  return license ? license.abbrev : type;
}
