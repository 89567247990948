import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import _ from 'lodash';
import { Link } from "react-router-dom";

import { connect } from 'react-redux'
import { fetchQuote, saveQuote } from '../redux/actions/quote'
import { getQuote } from '../redux/reducers'

export const mapStateToProps = (state, ownProps) => {
  return {
    quote: getQuote(state),
  }
}
export class TalkToBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",

      isLoading: false,
      coupon: "",
    };
  }

  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);
  }

  setQuoteState(quote) {
    this.setState({
      FirstName: !!quote.FirstName ? quote.FirstName : "",
      Last: !!quote.Last ? quote.Last : "",
      email: !!quote.email ? quote.email : "",
    });
  }
  validateForm() {
    return (
      (true)
    );
  }
  handleChange = () => {
    // console.log("test")
  }
  render() {

    return (
      <Container className="ThankYou" as="section">
        <Row noGutters>
          <Col lg={6}>
            <h1 className="chevron">Thank You</h1>
            <h3>We are reviewing your application</h3>
            <div className="description">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</p>
            </div>
          </Col>
        </Row>
        <Row noGutters>
          <Col lg={6}>
            <div className="viewApplication">
              <Link to="/account">View Your Application</Link>
            </div>
            <div className="greenBox triangle">
              <h3>Have any questions?</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.</p>
              <p className="bold phone">1-800-123-4567</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote, saveQuote
})(TalkToBroker);
