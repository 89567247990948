import React, { Component } from "react";
import AuthRoute from "./auth-route";

import Landing from "../pages/landing";
import TalkToBroker from "../pages/talk-to-broker";

import Login from "../pages/login";
import Signup from "../pages/signup";
import ResetPassword from "../pages/reset-password";

import Account from "../pages/account";
import AccountDetails from "../pages/account/account-details";
import AccountPolicy from "../pages/account/policy";
import AccountInformation from "../pages/account/information";
import AccountPassword from "../pages/account/password";
import AccountPayments from "../pages/account/payments";

import Quote from "../pages/quote";
import QuoteCoverages from "../pages/quote/coverages";
import QuoteDiscounts from "../pages/quote/discounts";
import QuoteReview from "../pages/quote/review";
import QuoteEmail from "../pages/quote/email";
import QuoteSave from "../pages/quote/save";

import Checkout from "../pages/checkout";
import CheckoutInformation from "../pages/checkout/information";
import CheckoutLicensing from "../pages/checkout/licensing";
import CheckoutReview from "../pages/checkout/review";
import CheckoutPayment from "../pages/checkout/payment";
import CheckoutPaymentProcess from "../pages/checkout/payment-process";
import CheckoutConfirmation from "../pages/checkout/confirmation";
import CheckoutProcessing from "../pages/checkout/processing";

import Reference from "../pages/reference";

import ThankYou from "../pages/thank-you";
// import CertificateGenerator from "../pages/certificate";
import DynamicCertificates from "../pages/certificate/DynamicCertificates";

export default class Routes extends Component {
  render() {
    const { childProps } = this.props;
    return (
      <div className={""}>
        <AuthRoute exact path="/" component={Landing} props={childProps} />
        <AuthRoute exact path="/talk-to-a-broker" component={TalkToBroker} props={childProps} />

        <AuthRoute exact path="/login" component={Login} props={childProps} />
        <AuthRoute exact path="/signup" component={Signup} props={childProps} />
        <AuthRoute exact path="/reset-password" component={ResetPassword} props={childProps} />

        <AuthRoute exact path="/account" component={Account} props={childProps} />
        <AuthRoute path="/account/policy" component={AccountPolicy} props={childProps} />
        <AuthRoute path="/account/information" component={AccountInformation} props={childProps} />
        <AuthRoute path="/account/password" component={AccountPassword} props={childProps} />
        <AuthRoute path="/account/payments" component={AccountPayments} props={childProps} />
        <AuthRoute path="/account/:slug" component={AccountDetails} props={childProps} />

        {/* <AuthRoute path="/certificate/:slug" component={CertificateGenerator} props={childProps} /> */}
        <AuthRoute path="/certificate-samples" component={DynamicCertificates} props={childProps} />

        <AuthRoute exact path="/quote" component={Quote} props={childProps} />
        <AuthRoute exact path="/quote/coverages" component={QuoteCoverages} props={childProps} />
        <AuthRoute exact path="/quote/discounts" component={QuoteDiscounts} props={childProps} />
        <AuthRoute exact path="/quote/review" component={QuoteReview} props={childProps} />
        <AuthRoute exact path="/quote/email" component={QuoteEmail} props={childProps} />
        <AuthRoute exact path="/quote/save" component={QuoteSave} props={childProps} />

        <AuthRoute exact path="/checkout" component={Checkout} props={childProps} />
        <AuthRoute exact path="/checkout/information" component={CheckoutInformation} props={childProps} />
        <AuthRoute exact path="/checkout/licensing" component={CheckoutLicensing} props={childProps} />
        <AuthRoute exact path="/checkout/review" component={CheckoutReview} props={childProps} />
        <AuthRoute exact path="/checkout/payment" component={CheckoutPayment} props={childProps} />
        <AuthRoute exact path="/checkout/payment-process" component={CheckoutPaymentProcess} props={childProps} />
        <AuthRoute exact path="/checkout/processing" component={CheckoutProcessing} props={childProps} />
        <AuthRoute exact path="/checkout/confirmation" component={CheckoutConfirmation} props={childProps} />

        <AuthRoute exact path="/review" component={ThankYou} props={childProps} />

        {/* <AuthRoute exact path="/reference" component={Reference} props={childProps} /> */}
      </div>
    );
  }
}
