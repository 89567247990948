const address_data =
  [
    {
      addressLine1: [{
        details: [{
          controlId: "addressLine1",
          formLabel: "Street Address",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
            placeholder: "123 Main Street"
          }]
        }]
      }],
      addressLine2: [{
        details: [{
          controlId: "addressLine2",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
            placeholder: "Suite 1000"
          }]
        }]
      }],
      city: [{
        details: [{
          controlId: "city",
          formLabel: "City",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
          }]
        }]
      }],
      province: [{
        details: [{
          controlId: "province",
          formLabel: "Province",
          FormControl: [{
            type: "select",
            options: [
              "Select a Province...",
              "AB",
              "BC",
              "MB",
              "NB",
              "NL",
              "NS",
              "NU",
              "NT",
              "ON",
              "PE",
              "QC",
              "SK",
              "YT"
            ]
          }]
        }]
      }],
      postal: [{
        details: [{
          controlId: "postal",
          formLabel: "Postal",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
          }]
        }]
      }],
      perferredPaymentFrequency: [{
        details: [{
          controlId: "perferredPaymentFrequency",
          formLabel: "Preferred Payment Frequency",
          FormControl: [{
            type: "buttons",
            buttons: [{
              options: ["Monthly", "Yearly"]
            }]
          }]
        }]
      }],
    }
  ];
export default address_data;
