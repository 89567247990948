import { combineReducers } from 'redux';

import {
    FETCH_QUOTE_REQUEST,
    SAVE_QUOTE_SUCCESS
} from '../actionTypes'

export const quote = (state = {}, action) => {
    switch (action.type) {
        case FETCH_QUOTE_REQUEST:
        case SAVE_QUOTE_SUCCESS:
            if (action.response) {
                // console.log(action.response);
                return {
                    ...state,
                    quote: action.response
                };
            }
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    quote
});

export const getQuote = state => state.quote.quote;
