import React, { Component } from 'react';
import LoaderButton from "./loader";
import ToolTip from "./ToolTip";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from '../Assets/helpers/translations/translations';

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
  };
};
export class FormBottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
  }
  componentDidUpdate(prevProps, prevState) {
    const { locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
  }
  getRequired = (requiredFields) => {
    const { locale } = this.props;

    // console.log(requiredFields);
    let fields = <div><h3 className="mt-0">{translateString(locale, "Required Fields")}</h3><ul>{requiredFields.map((field, i) => {
      return (<li key={"requiredField" + i}>{translateString(locale, field)}</li>)
    })}</ul></div>;
    // console.log(fields);
    return fields;
  }

  render() {
    const { locale } = this.props;

    return (
      <div className={"FormBottomNav " + (this.props.className ? this.props.className : null) + (this.props.prevPage === false ? " justify-content-end noPrevPage" : "") + ((this.props.required && this.props.disabled) ? " hasRequiredToolTip" : "")}>
        {this.props.prevPage && (<Link to={this.props.prevPage} className="prevQuote"><i className="fa fa-chevron-left"></i>{this.props.prevText}</Link>)}
        {this.props.required && this.props.disabled && (
          <ToolTip
            textColor="#01A665"
            hoverColor="#01A665"
            tipText={this.getRequired(this.props.required)}
            primaryOrigin="vertical"
            verticalOrigin="bottom"
            horizonalOrigin="right"
            infoID="FormBottonNav"
            size="18px"
            requiredData={true}
          />
        )}
        <LoaderButton
          block
          disabled={this.props.disabled}
          type="submit"
          text={translateString(locale,"Continue")}
          isLoading={this.props.isLoading}
        >
        </LoaderButton>
      </div>
    );
  }
}


export default connect(mapStateToProps, {
  fetchLocale,
})(FormBottomNav);