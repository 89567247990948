let options = {
  options: ["Monthly", "Yearly"],
};
// if (process.env.REACT_APP_PRODUCTION === "false") {
//   options = {
//     options: ["Monthly"],
//   };
// }
const payment_data = [
  {
    timeMethod: [
      {
        details: [
          {
            controlId: "timeMethod",
            formLabel: "How would you like to pay your premiums?",
            FormControl: [
              {
                type: "buttons",
                buttons: [options],
                dependencies: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
export default payment_data;
