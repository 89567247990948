import React, { Component } from "react";
import { connect } from "react-redux";
import ToolTip from "./ToolTip";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import LoaderButton from "./loader";
import { fetchPricing } from "../redux/actions/pricing";
import { getDiscounts, getQuote, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from '../Assets/helpers/translations/translations';
import { fetchQuote, saveQuote } from "../redux/actions/quote";
import _ from "lodash";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    discounts: getDiscounts(state),
  };
};
export class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      acceptedCoupons: [],
      coupon: { type: "null", value: 0 },
      couponInput: "",
      error: null,
      showCoupon: true,
      currentCoupon: null,
      corporation: null,
    };
  }
  handleClassToggle = () => {
    if (this.state.showCoupon) {
      this.setState({ showCoupon: false });
    } else {
      this.setState({ showCoupon: true });
    }
  };
  handleChange = (event) => {
    // console.log({ coups: this.state.acceptedCoupons });
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCouponSubmit = (e) => {
    const { saveQuote, quote } = this.props;
    e.preventDefault();
    // const error = !this.state.acceptedCoupons.includes(this.state.coupon);
    if (this.state.couponInput === "NA") {
      this.setState({ error: true });
      return true;
    }
    if (this.state.couponInput === "") {
      this.setState({ error: false });
      quote.coupon = {
        type: null,
        value: null,
        corporation: null,
      };
      saveQuote({ ...quote });
      return true;
    }
    let error = !_.find(this.state.acceptedCoupons, {
      value: this.state.couponInput.toUpperCase(),
    });
    const discountId = _.findIndex(
      this.state.acceptedCoupons,
      (discount) => discount.value === this.state.couponInput.toUpperCase()
    );
    if (!error) {
      try {
        const couponCode = this.state.acceptedCoupons[discountId];
        const couponValueId = _.findIndex(
          this.state.acceptedCoupons,
          (discount) => discount.type === couponCode.type.replace("Disc", "")
        );
        let corporation = this.state.acceptedCoupons[couponValueId].type;
        const couponValue = this.state.acceptedCoupons[couponValueId].value;
        quote.coupon = {
          type: couponCode.value,
          value: couponValue,
          corporation,
        };
        quote.coupon.value = isNaN(parseFloat(quote.coupon.value))
          ? 0
          : parseFloat(quote.coupon.value);
        // console.log("COUPON HAS BEEN SUBMITTED");
        saveQuote({ ...quote });
      } catch (e) {
        error = true;
      }
    }
    this.setState({ error });
  };
  componentDidUpdate(prevProps, prevState) {
    const { quote, discounts, locale, fetchLocale } = this.props;
    // console.log({ quote, discounts });
    if (!locale) fetchLocale();

    if (!_.isEqual(quote, prevProps.quote)) {
      this.setState({ quote });
    }
    // console.log({ discounts });
    if (!_.isEqual(discounts, prevProps.discounts)) {
      this.setAcceptedCoupons(discounts);
    }
  }
  setAcceptedCoupons = (discounts) => {
    let acceptedCoupons = [];
    // console.log(discounts);
    // discounts.forEach((discount) => {
    //   if (!isNaN(parseFloat(discount.value))) {
    //     acceptedCoupons.push(discount);
    //   }
    // });
    acceptedCoupons = [...discounts];
    this.setState({ acceptedCoupons });
    return true;
  };
  componentDidMount = () => {
    if (
      !document.querySelector(".lowerFooter").classList.contains("fixedPadding")
    ) {
      document.querySelector(".lowerFooter").classList.add("fixedPadding");
    }

    const { discounts, fetchPricing, quote, fetchQuote } = this.props;

    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      this.setState({ quote });
      if (quote.hasOwnProperty("coupon")) {
        this.setState({ couponInput: quote.coupon.type });
        this.setState({ coupon: { ...quote.coupon } });
        this.setState({ error: false });
      }
    }

    if (!discounts || _.isEmpty(discounts)) fetchPricing();
    else this.setAcceptedCoupons(discounts);
  };

  render() {
    const { disabled, quote } = this.props;
    const { locale } = this.props;

    const { error, isLoading, couponInput, acceptedCoupons, showCoupon } = this.state;
    if (_.isEmpty(acceptedCoupons)) return (<div></div>);

    return (
      <>
        <div className={showCoupon ? "Coupon active" : "Coupon"}>
          <h4 className="hasToolTip">{translateString(locale,"Have a Corporate Code?")}</h4>
          <ToolTip
            textColor="rgba(255,255,255,0.8)"
            hoverColor="#fff"
            tipText={translateString(locale,"If you are contracted with a firm that participates in the Advocis Corporate Partnership/Corporate Sponsorship Program, please enter the code provided by this organization. If you have received a code at an ABS or Advocis event, please enter that code.")}
            primaryOrigin="vertical"
            verticalOrigin="top"
            horizonalOrigin="right"
            infoID="couponID"
            size="18px"
          />
          <form onSubmit={this.handleCouponSubmit}>
            <FormGroup controlId="couponInput">
              <FormLabel>{translateString(locale,"Enter it here:")}</FormLabel>
              <FormControl
                autoFocus={false}
                type="text"
                value={couponInput}
                onChange={this.handleChange}
              />
              {error === true ? (
                <i className="fas fa-exclamation-circle error"></i>
              ) : (
                ""
              )}
            </FormGroup>
            {error === true ? (
              <div className="invalidCode">{translateString(locale, "Invalid Code entered")}</div>
            ) : (
              ""
            )}
            <LoaderButton
              block
              disabled={disabled}
              type="submit"
              text={translateString(locale, "Apply Code")}
              variant="coupon"
              isLoading={isLoading}
            ></LoaderButton>
            {error !== true && error !== null && !!quote.coupon && quote.coupon.value && (
              <div className="successCode">
                <i className="fa fa-check"></i>{translateString(locale, "Code Successfully applied")}
                <div style={{ marginTop: "1rem" }}>
                  {!!quote.coupon && !!quote.coupon.corporation && (
                    <span>{quote.coupon.corporation} </span>
                  )}
                  {quote.coupon.value !== 0 ? (
                    (quote.coupon.type) + " - " + quote.coupon.value + "%"
                  ) : (
                    ""
                  )
                  }
                </div>
              </div>
            )}
          </form>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  getQuote,
  saveQuote,
  fetchPricing,
  fetchQuote,
})(Coupon);
