import React, { Component } from "react";

export default class ToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  hoverColor = (e) => {
    e.target.style.color = this.props.hoverColor;
  };
  textColor = (e) => {
    e.target.style.color = this.props.textColor;
  };
  toggleToolTip = (e) => {
    document.querySelectorAll(".ToolTipText").forEach((a) => {
      a.classList.remove("active");
    });
    if (!e.target.classList.contains("ToolTipTextClose")) {
      if (
        e.target.parentElement.parentElement
          .querySelector(".ToolTipText")
          .classList.contains("active")
      ) {
        e.target.parentElement.parentElement
          .querySelector(".ToolTipText")
          .classList.remove("active");
      } else {
        e.target.parentElement.parentElement
          .querySelector(".ToolTipText")
          .classList.add("active");
      }
    }
  };
  render() {
    const {
      primaryOrigin,
      verticalOrigin,
      horizonalOrigin,
      learnMore,
      learnMoreText,
      tipText,
      textColor,
      hoverColor,
      infoID,
      size,
    } = this.props;
    const style = {
      color: textColor ? textColor : "#01A665",
      fontSize: size ? size : "14px",
    };

    return (
      <div className="ToolTip" id={infoID} key={infoID}>
        <div className="ToolTipToggle">
          <i
            className="fa fa-info-circle"
            style={style}
            onClick={this.toggleToolTip}
            onMouseOver={hoverColor ? this.hoverColor : null}
            onMouseLeave={this.textColor}
          ></i>
        </div>
        <div
          className="ToolTipText"
          data-primary_origin={primaryOrigin}
          data-vertical_origin={verticalOrigin}
          data-horizontal_origin={horizonalOrigin}
        >
          <i
            className="far fa-times-circle ToolTipTextClose"
            onClick={this.toggleToolTip}
          ></i>
          {tipText}
          {learnMore && <><br /><a target="_blank" href={learnMore}>{learnMoreText}</a></>}
          
        </div>
      </div>
    );
  }
}
