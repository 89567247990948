import { indexOf, isEmpty } from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";

import { fetchPricing } from "../../redux/actions/pricing";
import { getPricing } from "../../redux/reducers";

import EpicAPI from "../../Assets/api/epic";
import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
import CertificateGeneratorPage from "../../components/CertificateGenerator/CertificateGeneratorPage";
import testQuote from "./testQuote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

export const mapStateToProps = (state, ownProps) => {
  return {
    pricing: getPricing(state),
    locale: getLocale(state),
  };
};

export class DynamicCertificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomForm: {},
      policyID: 124122,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }

  async componentDidMount() {
    const { pricing, fetchPricing } = this.props;
    if (!pricing || isEmpty(pricing)) fetchPricing();
    else this.setState({ pricing });

    const LineID = await EpicAPI.getLine(this.state.policyID)
      .then((lineID) => {
        this.setState({ fetchFail: false });
        return lineID;
      })
      .catch((e) => {
        this.setState({ fetchFail: true });
      });
    console.log(LineID);
    // return { policyID };
    const CustomForm = await EpicAPI.getCustomForm(LineID);
    this.setState({ CustomForm });
    console.log(CustomForm);
  }
  changePolicyID = (id) => {
    this.setState({ policyID: id });
  };
  getPDFs = async (e) => {
    e.preventDefault();
    // EpicAPI.sendEmail(testQuote, this.props.pricing);
  };
  render() {
    const { locale } = this.props;
    if (this.state.policyID !== null) {
      if (!this.state.fetchFail && !isEmpty(this.state.CustomForm)) {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {testQuote.policies.map((loadPolicy, i) => {
                return (
                  <button
                    key={i}
                    className="p-2 text-lg-center"
                    style={loadPolicy.id === this.state.policyID ? { backgroundColor: "#00a664", color: "#fff" } : {}}
                    onClick={() => this.changePolicyID(loadPolicy.id)}>
                    {translateString(locale, "Load Sample")} {loadPolicy.code}
                  </button>
                );
              })}
            </div>
            {/* <form onSubmit={this.getPDFs}>
              <input type="submit" value="sub" />
            </form> */}
            {/* <pre>{JSON.stringify(testQuote, null, 4)}</pre> */}
            {/* {testQuote.policies.map((policy) => {
          return ( */}
            {/* // <div key={policy.id}>{JSON.stringify(this.getCustomForm(policy.id), null, 2)}</div>
            // <div key={policy.id}>{this.getCustomForm(policy)}</div> */}
            <PDFViewer style={{ width: "100%", height: "calc(100vh - 40px)" }}>
              <CertificateGeneratorPage
                CustomForm={this.state.CustomForm}
                quoteDetails={testQuote}
                policy={testQuote.policies[testQuote.activePolicies.indexOf(this.state.policyID)]}
                pricing={this.props.pricing}
                locale={locale}
              />
            </PDFViewer>
            {/* );
        })} */}

            {/* <PDFDownloadLink
          document={<CertificateGeneratorPage CustomForm={this.state.CustomForm} policyID={this.state.policyID} />}
          fileName="test.pdf"
        >
          {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download now!")}
        </PDFDownloadLink> */}
          </>
        );
      } else {
        // return <Redirect to="/" />;
        return (
          <div style={{ margin: "0 auto", textAlign: "center", padding: "40px 0" }}>
            <FontAwesomeIcon icon="circle-notch" spin={true} size="4x" color="#00a664" />
          </div>
        );
      }
    } else {
      return <div className="CertificateGenerator">{translateString(locale, "Policy Not Found")} </div>;
    }
  }
}

export default connect(mapStateToProps, {
  fetchPricing,
})(DynamicCertificates);
