import { FETCH_FORM_FAILURE, FETCH_FORM_REQUEST, FETCH_FORM_SUCCESS, SAVE_FORM_SUCCESS, SAVE_FORM_LINE_SUCCESS } from "../actionTypes";
import EpicAPI from "../../Assets/api/epic";

export const clearForms = () => (dispatch, getState) => {
  dispatch({
    type: SAVE_FORM_SUCCESS,
    response: {},
  });
};

export const saveForms = (formData) => (dispatch, getState) => {
  // console.log("redux saving forms");
  const _state = getState();
  const lines = _state.forms.forms.lines;
  return EpicAPI.updateCustomForm(formData, lines).then((response) => {
    // console.log("redux forms saved", { formData });
    dispatch({
      type: SAVE_FORM_SUCCESS,
      response: formData,
    });
  });
};

export const saveFormLines = (lines) => (dispatch, getState) => {
  // console.log('saving form lines');
  dispatch({
    type: SAVE_FORM_LINE_SUCCESS,
    response: lines,
  });
};

export const clearFormLines = () => (dispatch, getState) => {
  dispatch({
    type: SAVE_FORM_LINE_SUCCESS,
    response: {},
  });
};

export const fetchForms = (LineID) => (dispatch, getState) => {
  // console.log('fetching forms');
  dispatch({
    type: FETCH_FORM_REQUEST,
  });

  return EpicAPI.getCustomForm(LineID)
    .then((response) => {
      dispatch({
        type: FETCH_FORM_SUCCESS,
        response,
      });
      return response;
    })
    .catch((e) => {
      dispatch({
        type: FETCH_FORM_FAILURE,
      });
    });
};
