import React, { Component } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from "@david.kucsai/react-pdf-table";
import { isEmpty } from "lodash";

import { connect } from "react-redux";
import { getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

// export const mapStateToProps = (state, ownProps) => {
//   return {
//     locale: getLocale(state),
//   };
// };


const styles = StyleSheet.create({
  tableWidthSpecific: {
    width: "100%",
    overflow: "hidden",
  },
  tableWrapper: {
    marginBottom: 10,
  },
  table: {
    fontSize: 6,
    fontFamily: "Helvetica",
  },
  extraTable: {
    fontSize: 6,
    marginTop: 5,
    fontFamily: "Helvetica",
  },
  tableHeading: {
    backgroundColor: "#F2F2F2",
  },
  tableHeadingCell: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 6,
    fontFamily: "Helvetica-Bold",
    backgroundColor: "#F2F2F2",
  },
  tableHeadingCellCenter: {
    fontSize: 6,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
  },
  tableRowStart: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#F2F2F2",
    fontSize: 6,
  },
  tableRowCheck: {
    fontSize: 6,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableRowExtra: {
    fontSize: 6,
    fontFamily: "Helvetica-Bold",
    textAlign: "left",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    paddingLeft: 12,
  },
  extraLicensing: {
    fontSize: 6,
    fontFamily: "Helvetica-Bold",
    marginTop: 2,
    marginBottom: 2,    
  },
  licensesHeld: {
    fontFamily: "Helvetica-Bold",
    color: "rgb(0, 166, 100)",
    textTransform: "uppercase",
    display: "inline",
    fontSize: 8,
    marginBottom: 3,
  },
});

const _provinceTableChecks = [
  {
    license: "Life Insurance",
    check: ["Y", "", "", "", "", "", "", "", "", "", "", "", ""],
  },
  {
    license: "Segregated Funds",
    check: ["Y", "", "", "", "", "", "", "", "", "", "", "", ""],
  },
];
const smallCell = parseFloat(0.8 / 13);

export class CertificateProvincialTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing: null,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }


  provinceChecklistMatch = (userProvinces) => {
    const provinces = [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Nunavut",
      "Northwest Territories",
      "Ontario",
      "Prince Edward Island",
      "Québec",
      "Saskatchewan",
      "Yukon Territory",
    ];
    const checklist = ["", "", "", "", "", "", "", "", "", "", "", "", ""];
    provinces.forEach((province, i) => {
      if (userProvinces.includes(province)) {
        checklist[i] = "Y";
      }
    });
    return checklist;
  };

  setProvincialData = (policy, quoteDetails, locale) => {
    const provinceTable = [];
    console.log("policy.description :", policy.description);
    console.log("quoteDetails :", quoteDetails);
    switch (policy.description) {
      case "E &amp; O - Coverage A Lite":
        provinceTable.push({
          license: translateString(locale, "Life Insurance"),
          check:
            quoteDetails.lifeInsuranceLicensing && !isEmpty(quoteDetails.lifeInsuranceLicensingProvince)
              ? this.provinceChecklistMatch(quoteDetails.lifeInsuranceLicensingProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Accident and Sickness"),
          check:
            quoteDetails.accidentSickness && !isEmpty(quoteDetails.accidentSicknessProvince)
              ? this.provinceChecklistMatch(quoteDetails.accidentSicknessProvince)
              : this.provinceChecklistMatch([]),
        });
        break;
      case "E &amp; O - Coverage A":
        provinceTable.push({
          license: translateString(locale, "Life Insurance"),
          check:
            quoteDetails.lifeInsuranceLicensing && !isEmpty(quoteDetails.lifeInsuranceLicensingProvince)
              ? this.provinceChecklistMatch(quoteDetails.lifeInsuranceLicensingProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Accident and Sickness"),
          check:
            quoteDetails.accidentSickness && !isEmpty(quoteDetails.accidentSicknessProvince)
              ? this.provinceChecklistMatch(quoteDetails.accidentSicknessProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Mutual Funds"),
          check:
            quoteDetails.mutualFunds && !isEmpty(quoteDetails.mutualFundsProvince)
              ? this.provinceChecklistMatch(quoteDetails.mutualFundsProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Segregated Funds"),
          check:
            quoteDetails.segregatedFunds && !isEmpty(quoteDetails.segregatedFundsProvince)
              ? this.provinceChecklistMatch(quoteDetails.segregatedFundsProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "GIC's"),
          check:
            quoteDetails.gic && !isEmpty(quoteDetails.gicProvince)
              ? this.provinceChecklistMatch(quoteDetails.gicProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Income Tax Preparation"),
          check:
            quoteDetails.incomeTax && !isEmpty(quoteDetails.incomeTaxProvince)
              ? this.provinceChecklistMatch(quoteDetails.incomeTaxProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Fee for Service Planning"),
          check:
            quoteDetails.feeServicePlanning && !isEmpty(quoteDetails.feeServicePlanningProvince)
              ? this.provinceChecklistMatch(quoteDetails.feeServicePlanningProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Certified Divorce Specialist"),
          check:
            quoteDetails.divorceSpecialist && !isEmpty(quoteDetails.divorceSpecialistProvince)
              ? this.provinceChecklistMatch(quoteDetails.divorceSpecialistProvince)
              : this.provinceChecklistMatch([]),
        });
        break;
      // return provinceTable;
      case "E &amp; O - Coverage D":
        provinceTable.push({
          license: translateString(locale, "Securities"),
          check:
            quoteDetails.securities && !isEmpty(quoteDetails.securitiesProvince)
              ? this.provinceChecklistMatch(quoteDetails.securitiesProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Life Insurance"),
          check:
            quoteDetails.lifeInsuranceLicensing && !isEmpty(quoteDetails.lifeInsuranceLicensingProvince)
              ? this.provinceChecklistMatch(quoteDetails.lifeInsuranceLicensingProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Accident and Sickness"),
          check:
            quoteDetails.accidentSickness && !isEmpty(quoteDetails.accidentSicknessProvince)
              ? this.provinceChecklistMatch(quoteDetails.accidentSicknessProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Mutual Funds"),
          check:
            quoteDetails.mutualFunds && !isEmpty(quoteDetails.mutualFundsProvince)
              ? this.provinceChecklistMatch(quoteDetails.mutualFundsProvince)
              : this.provinceChecklistMatch([]),
        });
        break;

      // return provinceTable;
      case "E &amp; O - Coverage B":
        provinceTable.push({
          license: translateString(locale, "Securities"),
          check:
            quoteDetails.securities && !isEmpty(quoteDetails.securitiesProvince)
              ? this.provinceChecklistMatch(quoteDetails.securitiesProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Income Tax Preparation"),
          check:
            quoteDetails.incomeTax && !isEmpty(quoteDetails.incomeTaxProvince)
              ? this.provinceChecklistMatch(quoteDetails.incomeTaxProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Executor of Estate"),
          check:
            quoteDetails.executorEstate && !isEmpty(quoteDetails.executorEstateProvince)
              ? this.provinceChecklistMatch(quoteDetails.executorEstateProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Notary Public"),
          check:
            quoteDetails.notaryPublic && !isEmpty(quoteDetails.notaryPublicProvince)
              ? this.provinceChecklistMatch(quoteDetails.notaryPublicProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Commissioner of Oaths"),
          check:
            quoteDetails.commissionerOaths && !isEmpty(quoteDetails.commissionerOathsProvince)
              ? this.provinceChecklistMatch(quoteDetails.commissionerOathsProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Fee for Service Planning"),
          check:
            quoteDetails.feeServicePlanning && !isEmpty(quoteDetails.feeServicePlanningProvince)
              ? this.provinceChecklistMatch(quoteDetails.feeServicePlanningProvince)
              : this.provinceChecklistMatch([]),
        });
        provinceTable.push({
          license: translateString(locale, "Certified Divorce Specialist"),
          check:
            quoteDetails.divorceSpecialist && !isEmpty(quoteDetails.divorceSpecialistProvince)
              ? this.provinceChecklistMatch(quoteDetails.divorceSpecialistProvince)
              : this.provinceChecklistMatch([]),
        });
        break;
      // return provinceTable;
      case "E &amp; O - Coverage C":
      case "Security &amp; Privacy Liability":
      default:
        break;
      // return provinceTable;
    }
    return provinceTable;
  };
  setExtraLicensing = (policy, quoteDetails, locale) => {
    // if (!quoteDetails.feeServicePlanning && !quoteDetails.divorceSpecialist && !quoteDetails.other) return "";
    const extraLicensing = [];
    if (policy.description === "E &amp; O - Coverage B") {
      if (quoteDetails.other) {
        extraLicensing.push({
          license: translateString(locale, "Other"),
          check: quoteDetails.otherText,
        });
      }
    }
    if (extraLicensing.length > 0) {
      return (
        <View style={styles.extraTable}>
          <Table style={styles.extraTable} data={extraLicensing}>
            {/* <TableHeader style={styles.tableHeading}>
              <TableCell weighting={1} style={styles.tableHeadingCell}>
                Product
              </TableCell>
            </TableHeader> */}
            <TableBody>
              <DataTableCell weighting={0.2} getContent={(r) => r.license} style={styles.tableRowStart} />
              <DataTableCell weighting={0.8} getContent={(r) => r.check} style={styles.tableRowExtra} />
            </TableBody>
          </Table>
        </View>
      );
    } else {
      return <></>;
    }
  };
  render() {
    const { policy, quoteDetails, locale } = this.props;
    const provinceTableChecks = this.setProvincialData(policy, quoteDetails, locale);
    const extraTable = this.setExtraLicensing(policy, quoteDetails, locale);
    if (isEmpty(provinceTableChecks) && extraTable.length > 0) return extraTable;
    if (isEmpty(provinceTableChecks)) return <></>;

    // console.log("provinceTableChecks :", provinceTableChecks);

    return (
      <View style={styles.tableWidthSpecific}>
        <Text style={styles.licensesHeld}>{translateString(locale, "Provinces licences held")}</Text>
        <View style={styles.tableWrapper}>
          <Table style={styles.table} data={provinceTableChecks}>
            <TableHeader style={styles.tableHeading}>
              <TableCell weighting={0.2} style={styles.tableHeadingCell}>
              {translateString(locale, "Product")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "AB")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "BC")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "MB")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "NB")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "NL")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "NS")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "NU")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "NT")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "ON")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "PE")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "QC")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "SK")}
              </TableCell>
              <TableCell weighting={smallCell} style={styles.tableHeadingCellCenter}>
                {translateString(locale, "YT")}
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell weighting={0.2} getContent={(r) => r.license} style={styles.tableRowStart} />
               <DataTableCell weighting={smallCell} getContent={(r) => r.check[0] ? r.check[0].toString() : " "} style={styles.tableRowCheck} />
               <DataTableCell weighting={smallCell} getContent={(r) => r.check[1] ? r.check[1].toString() : " "  } style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[2] ? r.check[2].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[3] ? r.check[3].toString() : " " } style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[4] ? r.check[4].toString() : " " } style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[5] ? r.check[5].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[6] ? r.check[6].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[7] ? r.check[7].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[8] ? r.check[8].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[9] ? r.check[9].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[10] ? r.check[10].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[11] ? r.check[11].toString() : " "} style={styles.tableRowCheck} />
              <DataTableCell weighting={smallCell} getContent={(r) => r.check[12] ? r.check[12].toString() : " "} style={styles.tableRowCheck} />
            </TableBody>
          </Table>
          {extraTable}
        </View>
      </View>
    );
  }
}

// export default connect(mapStateToProps, {
//   fetchLocale,
// })(CertificateProvincialTable);
export default CertificateProvincialTable;
