import React, { Component } from 'react';
import FormTopNav from '../../components/FormTopNav';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import Coupon from '../../components/Coupon';
import LoaderButton from '../../components/loader';
import steps from '../../Assets/data/stepsData';
import PricingDetails from '../../components/PricingDetails';

import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchQuote, saveQuote } from '../../redux/actions/quote';
import { getQuote, getPricing, getLocale } from '../../redux/reducers';
import { fetchLocale } from '../../redux/actions/locale';
import { translateString, translateJSXElement } from '../../Assets/helpers/translations/translations';
import EpicAPI from '../../Assets/api/epic';
import { calculateDiscount } from '../../Assets/data/pricing';
import xmlescape from 'xml-escape';

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state)
  };
};
export class QuoteReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      prevPage: '/quote/discounts',
      nextPage: '/quote/email',
      currentStep: 3,
      steps: steps,
      quote: null,
      coverages: [],
      fetchingQuote: true,
      fetchingCoverages: true,
      errorSaving: false
    };
  }
  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      this.setState({ quote, fetchingQuote: false });
      if (quote.hasOwnProperty('coupon')) {
        this.setState({ coupon: { ...quote.coupon } });
      }
    }
    setTimeout(() => {
      this.setState({ fetchingQuote: false });
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, locale, fetchLocale } = this.props;

    if (!locale) fetchLocale();
    if (!!quote && !!quote.coupon) {
      this.setCoupon();
    }
    if (!_.isEqual(quote, prevProps.quote)) {
      // console.log(quote);
      this.setState({ quote, fetchingQuote: false });
    }
    if (
      !_.isEqual(this.state.quote, prevState.quote) ||
      (prevState.quote && prevState.quote.coupon && !_.isEqual(this.state.quote.coupon, prevState.quote.coupon))
    ) {
      // console.log('quote state changed')
      this.getCoverages();
    }
    if (!_.isEqual(this.props.quote, this.state.quote)) {
      this.setState({ quote, fetchingQuote: false });
    }
  }

  setCoupon = () => {
    const { quote } = this.props;
    if (!!quote.coupon && !_.isEqual(quote.coupon, this.state.coupon)) {
      this.setState({ coupon: quote.coupon });
    }
  };

  validateForm() {
    return true;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCoupon = (coupon) => {
    coupon = this.state.coupon + ' ' + coupon;
    this.setState({ coupon });
  };

  currencyFormat = (num, locale, decimals = 2) => {
    if (locale === 'EN') return '$ ' + num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    else
      return (
        num
          .toFixed(decimals)
          .replace(/\./g, ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' $'
      );
  };

  handleBuy = async () => {
    this.setState({ isLoading: true });
    const proceed = await this.processQuotes();
    if (proceed) {
      this.props.history.push('/checkout');
    }
  };

  handleSave = async () => {
    await this.processQuotes().then((d) => console.log(d));
    this.props.history.push('/save');
  };

  processQuotes = async () => {
    const { saveQuote } = this.props;
    const { quote } = this.state;

    let branchCode = quote.location === 'QC' ? 'QUE' : 'ABS';

    if (!quote) return;
    let quotes = [];
    let start_date = moment(quote.startDate);
    let end_date = moment(quote.startDate).add(1, 'year');
    start_date = start_date.utc().format();
    end_date = end_date.utc().format();

    let manitoba = quote.manitoba === 'Yes';
    if (quote.lifeInsurance && quote.lifeInsurancePrice) {
      let code;
      let description = 'E &amp; O - Coverage A';
      switch (quote.lifeInsurancePrice) {
        case 1000000:
          code = manitoba ? 'EA1M' : 'EA1';
          break;
        case 2000000:
          code = manitoba ? 'EA2M' : 'EA2';
          break;
        case 3000000:
          code = manitoba ? 'EA3M' : 'EA3';
          break;
        case 4000000:
          code = manitoba ? 'EA4M' : 'EA4';
          break;
        case 5000000:
          code = manitoba ? 'EA5M' : 'EA5';
          break;
        case 10000000:
          code = manitoba ? 'EA10' : 'EA10';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    if (quote.lifeInsuranceLite && quote.lifeInsuranceLitePrice) {
      let code;
      let description = 'E &amp; O - Coverage A Lite';
      switch (quote.lifeInsuranceLitePrice) {
        case 1000000:
          code = manitoba ? 'A1LM' : 'A1L';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    if (quote.iiroc && quote.iirocPrice) {
      let code;
      let description = 'E &amp; O - Coverage B';
      switch (quote.iirocPrice) {
        case 1000000:
          code = 'EB1';
          break;
        case 2000000:
          code = 'EB2';
          break;
        case 3000000:
          code = 'EB3';
          break;
        case 4000000:
          code = 'EB4';
          break;
        case 5000000:
          code = 'EB5';
          break;
        case 10000000:
          code = 'EB10';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    if (quote.unlicensedPlanners && quote.unlicensedPlannersPrice) {
      let code;
      let description = 'E &amp; O - Coverage C';
      switch (quote.unlicensedPlannersPrice) {
        case 1000000:
          code = 'EC1';
          break;
        case 2000000:
          code = 'EC2';
          break;
        case 3000000:
          code = 'EC3';
          break;
        case 4000000:
          code = 'EC4';
          break;
        case 5000000:
          code = 'EC5';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    if (quote.licensedAdmin && quote.licensedAdminPrice) {
      let code;
      let description = 'E &amp; O - Coverage D';
      switch (quote.licensedAdminPrice) {
        case 1000000:
          code = manitoba ? 'ED1M' : 'ED1';
          break;
        case 2000000:
          code = manitoba ? 'ED2M' : 'ED2';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    if (quote.directorsAndOfficers && quote.directorsAndOfficersPrice) {
      let code;
      let description = 'Directors &amp; Officers Liability';
      switch (quote.directorsAndOfficersPrice) {
        case 500000:
          code = 'DO1';
          break;
        case 1000000:
          code = 'DO2';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    if (quote.cyberPrivacy) {
      let code;
      let description = 'Security &amp; Privacy Liability';
      switch (quote.cyberPrivacyPrice) {
        case 25000:
          code = 'SP1';
          break;
        case 50000:
          code = 'SP2';
          break;
        case 100000:
          code = 'SP3';
          break;
        case 250000:
          code = 'SP4';
          break;
        default:
          break;
      }
      if (code) {
        quotes.push({ location: quote.location, code, description, start_date, end_date });
      }
    }
    let _quote = { ...quote };
    _quote.activePolicies = [];
    _quote.policies = [];
    // console.log(quotes);
    //let clientID = 82639; //S2 - 82628;
    //_quote.clientID = clientID;
    for (let q of quotes) {
      // let res = await EpicAPI.insertPolicy(quote.clientID, q, quote.renewal);
      let res = await EpicAPI.insertPolicy(quote.clientID, q, quote.renewal, branchCode);
      if (res) {
        if (q.code && q.code.substr(0, 2) === 'EC') {
          const lineID = await EpicAPI.getLine(res);
          const _forms = await EpicAPI.getCustomForm(lineID);
          console.log(lineID);
          let changed = false;
          if (_forms && !_.isEmpty(_forms) && quote) {
            const DesignationsScreen =
              _forms.screens[
                _forms.screens.findIndex((screens) => {
                  return screens.ScreenName === 'Licensing &amp; Designations';
                })
              ];
            if (quote.otherDesignations === true && quote.TextBox3) {
              const textBox3 = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'TextBox3')];
              textBox3.FieldValue = quote.TextBox3;
              changed = true;
            }
            if (quote.unlicensedPlannersCheckbox && quote.unlicensedPlannersCheckbox.length > 0) {
              quote.unlicensedPlannersCheckbox.forEach((unlicensedPlannerCheck) => {
                let field = false;
                switch (unlicensedPlannerCheck) {
                  case 'Certified Financial Planner, CFP ®':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'PFP')];
                    break;
                  case 'Chartered Life Underwriter, CLU ®':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'FEA')];
                    break;
                  case 'Certified Health Insurance Specialist, CHS™':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'FDS')];
                    break;
                  case 'Chartered Financial Consultant, CH.F.C.':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'RFP')];
                    break;
                  case 'Trust and Estate Practitioners, TEP':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'FCSI')];
                    break;
                  case 'Canadian Investment Management, CIM':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'CIM')];
                    break;
                  case 'Fellow of the Canadian Securities Institute, FCSI®':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'TEP')];
                    break;
                  case 'Registered Financial Planner, R.F.P.':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'CHFC')];
                    break;
                  case 'Financial Divorce Specialist, FDS':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'CHS')];
                    break;
                  case 'Family Enterprise Advisor, FEA':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'CLU')];
                    break;
                  case 'Personal Financial Planner, PFP ®':
                    field = DesignationsScreen.fields[DesignationsScreen.fields.findIndex((field) => field.FieldLabel === 'CFP')];
                    break;
                  default:
                    break;
                }
                if (field) {
                  changed = true;
                  field.FieldValue = 'Y';
                }
              });
            }

            // FieldLabel: 'TextBox3'
            // FieldLabel: 'PFP'
            // FieldLabel: 'FEA'
            // FieldLabel: 'FDS'
            // FieldLabel: 'RFP'
            // FieldLabel: 'FCSI'
            // FieldLabel: 'CIM'
            // FieldLabel: 'TEP'
            // FieldLabel: 'CHFC'
            // FieldLabel: 'CHS'
            // FieldLabel: 'CLU'
            // FieldLabel: 'CFP'
            // console.log("_forms && quote");
          }
          // console.log({ changed, _forms });
          if (changed === true) {
            await EpicAPI.updateCustomForm(_forms, [lineID]);
            console.log('updateCustomForm');
            console.log('changed');
          }
        }
        await EpicAPI.updatePolicyNumber(res);
        let corp_partner = null;
        if (quote && quote.coupon && quote.coupon.corporation) {
          corp_partner = quote.coupon.corporation;
        }
        await EpicAPI.updateLine(res, corp_partner, q.code.toLowerCase().indexOf('sp') === 0);
        // console.log({ res });
        _quote.activePolicies.push(res);
        _quote.policies.push({ id: res, ...q });
        if (res) {
          // console.log("success");
          this.setState({ error: false });
        } else {
          // console.log("failed");
          this.setState({ error: true });
        }
      } else {
        this.setState({ errorSaving: true });

        return false;
      }
    }
    saveQuote(_quote);
    return true;
  };

  getCoverages = () => {
    const { quote } = this.props;
    if (!quote) {
      this.setState({ fetchingCoverages: false });
      return;
    }
    // console.log('getting coverages and discounts')
    // console.log('quote here',quote);

    let { couponDiscount, memberDiscount, licensedDiscount, couponPct, memberPct, licensedPct } = calculateDiscount(this.props.quote, 0, 0);
    this.setState({ couponDiscount, memberDiscount, licensedDiscount, couponPct, memberPct, licensedPct });

    let coverages = [];

    if (quote.lifeInsurance && quote.lifeInsurancePrice) {
      let label = 'Life Insurance / Mutual Funds - E&O';
      // let label = 'Life Insurance';

      let limit = quote.lifeInsurancePrice;
      coverages.push({ label, limit });
    }
    if (quote.lifeInsuranceLite && quote.lifeInsuranceLitePrice) {
      let label = 'Life Insurance / Mutual Funds - E&O Lite';
      // let label = 'Life Insurance';

      let limit = quote.lifeInsuranceLitePrice;
      coverages.push({ label, limit });
    }
    if (quote.iiroc && quote.iirocPrice) {
      let label = 'IIROC Licensees (Securities/Mutual Funds)';
      let limit = quote.iirocPrice;
      coverages.push({ label, limit });
    }
    if (quote.unlicensedPlanners && quote.unlicensedPlannersPrice) {
      let label = 'Unlicensed Planners/Fee for Service';
      let limit = quote.unlicensedPlannersPrice;
      coverages.push({ label, limit });
    }
    if (quote.licensedAdmin && quote.licensedAdminPrice) {
      let label = 'Licensed Administrative Assistants';
      let limit = quote.licensedAdminPrice;
      coverages.push({ label, limit });
    }
    if (quote.directorsAndOfficers && quote.directorsAndOfficersPrice) {
      let label = 'Directors and Officers';
      let limit = quote.directorsAndOfficersPrice;
      coverages.push({ label, limit });
    }
    if (quote.cyberPrivacy) {
      let label = 'Cyber/Privacy and Network';
      let limit = quote.cyberPrivacyPrice;
      coverages.push({ label, limit });
    }
    this.setState({ coverages, fetchingCoverages: false });
  };

  render() {
    const { quote, steps, currentStep, prevPage, coverages, fetchingCoverages, fetchingQuote } = this.state;
    const { locale } = this.props;

    let { memberPct, licensedPct } = calculateDiscount(this.props.quote, 0, 0);
    if (!fetchingQuote && !quote) return <Redirect to='/quote' />;
    if (!fetchingCoverages && _.size(coverages) === 0) return <Redirect to='/quote' />;
    let start_date = '';
    if (quote) {
      start_date = moment(quote.startDate);
    }
    let new_member = false;
    if (quote && quote.firstLicensed) {
      let year_diff = moment().diff(quote.firstLicensed, 'years');
      if (year_diff <= 3) {
        new_member = true;
      }
    }
    let dateTranslation;
    if (locale === 'EN') {
      dateTranslation = moment(start_date).locale('en').format('MMMM D, YYYY');
    } else {
      dateTranslation = moment(start_date).locale('fr').format('MMMM D, YYYY');
    }
    return (
      <Container className='Review' as='section'>
        <Row noGutters>
          <Col className='quoteFormWrap'>
            <FormTopNav steps={steps} currentStep={currentStep} />
            <div className='mobilePricing'>
              <h1 className='chevron'>{translateString(locale, 'Get a Quote')}</h1>
              <PricingDetails />
            </div>
            <h3>{translateString(locale, 'Review')}</h3>
            {quote && (
              <div className='reviewFields'>
                <Row noGutters>
                  <Col lg={3} className='reviewTitle'>
                    {translateString(locale, 'Coverage Start Date:')}
                  </Col>
                  <Col className='quoteReview'>
                    <Row noGutters>
                      <Col>{dateTranslation}</Col>
                    </Row>
                  </Col>
                  <Col xs={'auto'}>
                    <Link to='/quote' className='btn btn-tertiary edit'>
                      {translateString(locale, 'Edit')}
                    </Link>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col lg={3} className='reviewTitle'>
                    {translateString(locale, 'Coverages:')}
                  </Col>
                  <Col className='coveragesReview'>
                    {coverages.map((c, i) => (
                      <Row noGutters key={i}>
                        <Col>{translateString(locale, c.label)}</Col>
                        <Col xs={'auto'}>
                          {this.currencyFormat(c.limit, locale)} {translateString(locale, 'Limit')}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col xs={'auto'}>
                    <Link to='/quote/coverages' className='btn btn-tertiary edit'>
                      {translateString(locale, 'Edit')}
                    </Link>
                  </Col>
                </Row>
                <Row className='row no-gutters' noGutters>
                  <Col lg={3} className='reviewTitle'>
                    {translateString(locale, 'Discounts:')}
                  </Col>
                  <Col className='discountReview'>
                    {quote.coupon && quote.coupon.value > 0 ? (
                      <Row noGutters>
                        <Col>
                          <i className='far fa-check-circle'></i>
                          {translateString(locale, 'Coupon Code:')} {quote.coupon.type} <span className='amount'>({quote.coupon.value}%)</span>
                        </Col>
                      </Row>
                    ) : null}
                    {quote.advocisMember === 'yes' && quote.memberId !== '' && memberPct > 0 && (
                      <Row noGutters>
                        <Col>
                          <i className='far fa-check-circle'></i>Advocis Member - ID# {quote.memberId} <span className='amount'>({memberPct}%)</span>
                        </Col>
                      </Row>
                    )}
                    {new_member && licensedPct > 0 && (
                      <Row noGutters>
                        <Col>
                          <i className='far fa-check-circle'></i>
                          {translateString(locale, 'New Advisor Discount')} <span className='amount'>({licensedPct}%)</span>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xs={'auto'}>
                    <Link to='/quote/discounts' className='btn btn-tertiary edit'>
                      {translateString(locale, 'Edit')}
                    </Link>
                  </Col>
                </Row>
              </div>
            )}
            <div className='FormBottomNav Line Green top'>
              <Link to={prevPage} className='prevQuote'>
                <i className='fa fa-chevron-left'></i>
                {translateString(locale, 'Go Back')}
              </Link>

              {this.state.errorSaving ? (
                <div className='error d-flex flex-column'>
                  <div className='alertText bold' style={{ marginBottom: 4 }}>
                    {translateString(locale, 'An error has occurred.')}
                  </div>
                  <Button variant='primary' href='https://advocisinsurance.ca/contact/'>
                    {translateString(locale, 'Contact a Broker')}
                  </Button>
                </div>
              ) : (
                <LoaderButton
                  block
                  type='submit'
                  text={translateString(locale, 'Continue')}
                  btnClass='primary'
                  isLoading={this.state.isLoading}
                  onClick={this.handleBuy}
                />
              )}
            </div>
          </Col>
          <Col lg={'auto'} className='quotePricing sideBar'>
            <Coupon handleCoupon={this.handleCoupon} />
            <PricingDetails />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote
})(QuoteReview);
