const address =
  [
    {
      Street: [{
        details: [{
          controlId: "Street",
          formLabel: "Street Address",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
            placeholder: "123 Main Street",
            maxLength: "40"
          }]
        }]
      }],
      /*addressLine2: [{
        details: [{
          controlId: "addressLine2",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
            placeholder: "Suite 1000"
          }]
        }]
      }],*/
      City: [{
        details: [{
          controlId: "City",
          formLabel: "City",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
            maxLength: "20"
          }]
        }]
      }],
      Province: [{
        details: [{
          controlId: "Province",
          formLabel: "Province",
          FormControl: [{
            type: "select",
            options: [
              "Select a Province...",
              "AB",
              "BC",
              "MB",
              "NB",
              "NL",
              "NS",
              "NU",
              "NT",
              "ON",
              "PE",
              "QC",
              "SK",
              "YT"
            ]
          }]
        }]
      }],
      PostalCode: [{
        details: [{
          controlId: "PostalCode",
          formLabel: "Postal Code",
          FormControl: [{
            autoFocus: false,
            type: "text",
            value: "",
            maxLength: "7"
          }]
        }]
      }],
    }
  ];
export default address;
