
import {
    SET_LOCALE_SUCCESS,
    FETCH_LOCALE_REQUEST
} from '../actionTypes'

export const setLocale = (locale) => (dispatch, getState) => {
    // console.log('setting locale: ', locale);
    dispatch({
        type: SET_LOCALE_SUCCESS,
        response: locale
    });
};
export const fetchLocale = () => (dispatch, getState) => {
    // console.log('fetching locale');
    dispatch({
        type: FETCH_LOCALE_REQUEST
    });
};
