const info_ownership = [
  {
    legalName: [
      {
        details: [
          {
            controlId: "legalName",
            formLabel:
              "Legal Name of Incorporated Firm. Please make sure to enter the full legal name e.g. 192168 Ontario Inc. operating as John Smith Financial",
            FormControl: [
              {
                autoFocus: false,
                type: "text",
                value: "",
                placeholder: "",
              },
            ],
          },
        ],
      },
    ],
    corporationOwned: [
      {
        details: [
          {
            controlId: "corporationOwned",
            formLabel: "Is the corporation named above owned solely by you or jointly with your unlicensed spouse?",
            FormControl: [
              {
                type: "buttons",
                buttons: [
                  {
                    value: "Yes",
                    options: ["Yes", "No"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    employedAgents: [
      {
        details: [
          {
            controlId: "employedAgents",
            formLabel:
              "Do you employ any other licensed or unlicensed agents, or accept business from other licensed agents? (Note that licensed administrative assistants are considered “licensed agents” whereas unlicensed administrative assistants are not considered “unlicensed agents”.)",
            FormControl: [
              {
                type: "buttons",
                buttons: [
                  {
                    value: "Yes",
                    options: ["Yes", "No"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export default info_ownership;
