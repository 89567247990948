import React, { Component } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import PrintFormGroup from "../../components/PrintFormGroup";
import AccountSidebar from "../../components/AccountSidebar";
import accountAddressData from "../../Assets/data/accountAddressData";
// import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "../../styles/style.css";

export default class AccountPayments extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
  }

  render() {
    return (
      <div className="account account--payments">
        <Breadcrumb />

        <Container className="accountPayments" as="section">
          <Container style={{ "padding-left": "0px" }}>
            <form onSubmit={this.handleSubmit}>
              <Row noGutters>
                <Col sm="2">{}</Col>
                <Col style={{ "padding-left": "0.3rem" }}>
                  <h1 className="chevron">Payments</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AccountSidebar current="account" />
                </Col>
                <Col sm={10}>
                  <Row noGutters>
                    <Col className="quoteFormWrap">
                      <hr className="dotted" />
                      <h4>Default Billing Address</h4>
                      <div className="accountPaymentsFields">
                        <PrintFormGroup
                          parent="accountPayments"
                          data={accountAddressData}
                          handleChange={this.handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row noGutters>
                <Col sm={2}>&nbsp;</Col>
                <Col cm={10}>
                  <hr className="dotted" />
                  <h4>&nbsp;Payment Preferences</h4>
                </Col>
              </Row>
              <div className="PrintFormGroup">
                <div
                  data-parent="accountPayments"
                  data-id="preferredPaymentType"
                  className="form-group"
                >
                  <Row>
                    <Col sm={2}>&nbsp;</Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={3}>
                          <label
                            className="form-label"
                            htmlFor="preferredPaymentType"
                          >
                            Preferred Payment Type
                          </label>
                        </Col>
                        <Col>
                          <div className="buttons FieldWrapper">
                            <div role="group" className="radioWrap btn-group">
                              <button
                                name="preferredPaymentType"
                                type="button"
                                className="btn btn-radioButtons"
                              >
                                Credit Card
                              </button>
                              <button
                                name="preferredPaymentType"
                                type="button"
                                className="btn btn-radioButtons"
                              >
                                Pre-Authorized Payment
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>&nbsp;</Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={3}>
                          <p>Bank Accounts and Cards</p>
                        </Col>
                        <Col>
                          <p>Card ending in 0008</p>
                          <p>Card ending in 0008</p>
                          <p>Card ending in 0008</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>&nbsp;</Col>
                    <Col sm={10}>
                      <Row>
                        <Col sm={3}>Default Payment Method</Col>
                        <Col>
                          <div className="select FieldWrapper">
                            <select
                              name="defaultPaymentMethod"
                              placeholder=""
                              id="defaultPaymentMethod"
                              className="form-control"
                            >
                              <option value="default">Select...</option>
                              <option value="008">Card ending in 0008</option>
                              <option value="009">Card ending in 0009</option>
                              <option value="010">Card ending in 0010</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col sm={2}>&nbsp;</Col>
                  <Col>
                    <hr className="dotted" />
                    <button
                      style={{ marginTop: "1rem" }}
                      className="btn btn-primary"
                    >
                      Save Changes
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
              </div>
            </form>
          </Container>
        </Container>
      </div>
    );
  }
}
