import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import _ from 'lodash';
import { getPricing } from '../../redux/reducers';
import { getPricingSplits } from '../../Assets/data/pricing';
import { loadPerClaimAggregate } from '../../Assets/data/pricing';
import quote from '../../redux/reducers/quote';

import { connect } from 'react-redux';
import { getLocale } from '../../redux/reducers';
import { fetchLocale } from '../../redux/actions/locale';
import { translateString, translateJSXElement } from '../../Assets/helpers/translations/translations';

const styles = StyleSheet.create({
  coverage: {
    marginBottom: 8,
    fontFamily: 'Helvetica'
  },
  coverageInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15
  },
  coverageInfoH1: {
    fontSize: 10,
    fontWeight: 'bold',
    width: '15%',
    fontFamily: 'Helvetica-Bold'
  },
  coverageInfoText: {
    width: '85%',
    fontSize: 7,
    color: '#000000'
  },
  tableHeading: {
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold'
  },
  rowBoldTan: {
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    fontWeight: 'bold',
    color: '#000000',
    backgroundColor: '#F2F2F2',
    fontFamily: 'Helvetica-Bold'
  },
  rowBold: {
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Helvetica-Bold'
  },
  row: {
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    fontWeight: 'normal',
    color: '#000000'
  },
  row1: {
    width: '15%'
  },
  row2: {
    width: '65%'
  },
  row3: {
    width: '20%'
  },
  retro: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  retroText: {
    fontSize: 8,
    marginLeft: 3,
  },
  retroBold: {
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold'
  }
});
// export const mapStateToProps = (state, ownProps) => {
//   return {
//     locale: getLocale(state),
//   };
// };

export class CertificateCoverageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing: null
    };
  }
  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }

  formatMoney = (a, locale) => {
    if (locale === 'EN') return '$ ' + a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    else
      return (
        a
          .toString()
          .replace(/\./g, ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' $'
      );
  };
  getCoverageTitle = (policy) => {
    if (policy.description.indexOf('E &amp; O - ') > -1) {
      return translateString(this.props.locale, policy.description.split('E &amp; O - ')[1]);
    } else {
      return translateString(this.props.locale, 'Endorsement ') + '#1'; //policy.code.substr(-1)
    }
  };
  getCoverageDescription = (policy, quoteDetails, locale) => {
    const description = [];
    if (quoteDetails) {
      switch (policy.description) {
        case 'E &amp; O - Coverage A':
          // if (quoteDetails.lifeInsurance) {
          description.push(translateString(locale, 'Life Insurance'));
          // }
          // if (quoteDetails.accidentSickness) {
          description.push(translateString(locale, 'Accident and Sickness'));
          description.push(translateString(locale, 'Disability & Critical Illness'));
          description.push(translateString(locale, 'Group Benefits'));
          // }
          // if (quoteDetails.mutualFunds) {
          description.push(translateString(locale, 'Mutual Funds'));
          description.push(translateString(locale, 'Term Deposits'));
          // }
          // if (quoteDetails.gic) {
          description.push(translateString(locale, 'GICs'));
          // }
          // if (quoteDetails.segregatedFunds) {
          description.push(translateString(locale, 'Segregated Funds'));
          // }
          description.push(translateString(locale, 'Pension Plans (incl. RRSP and RRIF)'));
          description.push(translateString(locale, 'Fee for Service'));
          description.push(translateString(locale, 'Financial Divorce Specialist'));
          description.push(translateString(locale, 'Personal Income Tax Preparation'));
          return description.join(', ');
        case 'E &amp; O - Coverage B':
          // if (quoteDetails.securities) {
          description.push(translateString(locale, 'Securities'));
          // }
          description.push(translateString(locale, 'Mutual Funds'));
          // if (quoteDetails.incomeTax) {
          description.push(translateString(locale, 'Income Tax Preparation'));
          description.push(translateString(locale, 'Executors'));
          // }
          // if (quoteDetails.notaryPublic) {
          description.push(translateString(locale, 'Notaries Public'));
          // }
          // if (quoteDetails.commissionerOaths) {
          description.push(translateString(locale, 'Commissioner of Oaths'));
          // }
          // if (quoteDetails.feeServicePlanning) {
          description.push(translateString(locale, 'Fee for Service Planning'));
          // }
          // if (quoteDetails.divorceSpecialist) {
          description.push(translateString(locale, 'Financial Divorce Specialists'));
          // }
          // if (quoteDetails.other) {
          //   description.push(quoteDetails.otherText);
          // }
          return description.join(', ');
        case 'E &amp; O - Coverage C':
          return translateString(locale, 'Unlicensed Planners - Fee for Service');
        case 'E &amp; O - Coverage D':
          return translateString(locale, 'Licensed Administrative Assistant');
        case 'Security &amp; Privacy Liability':
          return translateString(
            locale,
            'Security and Privacy Liability, Regulatory Proceeding Coverage, Privacy Breach Costs, Cyber Extortion Threat Coverage, Reward Payments Coverage'
          );
        default:
          return '';
      }
    }
    return '';
  };
  getCoverageDeductable = (policy, locale) => {
    switch (policy.code.substr(0, 2)) {
      case 'EA':
        return translateString(locale, '$0 each Claim');
      case 'EB':
        return translateString(locale, '$5,000 each Claim');
      case 'EC':
        return translateString(locale, '$1,000 each Claim');
      case 'ED':
        return translateString(locale, '$0 each Claim');
      default:
        return translateString(locale, '$0 each Claim');
    }
  };
  getCoverageDetails = (policy, pricing, locale) => {
    const { per, agg } = loadPerClaimAggregate(policy, pricing);
    // console.log({ pricing });
    // console.log({ per, agg });
    switch (policy.code.substr(0, 2)) {
      case 'SP':
        return [
          {
            liabilityLimit: this.formatMoney(per, locale),
            coverageDescription: translateString(locale, 'Each Claim'),
            deductable: translateString(locale, '$5,000 each Claim'),
            bolded: false
          },
          {
            liabilityLimit: this.formatMoney(agg, locale),
            coverageDescription: translateString(locale, 'Aggregate each Named Insured per Policy Term'),
            deductable: '',
            bolded: false
          },
          {
            liabilityLimit: '$5,000',
            coverageDescription: translateString(locale, 'Reward Payments'),
            deductable: 'NIL',
            bolded: false
          },
          {
            liabilityLimit: '$5,000,000',
            coverageDescription: translateString(locale, 'Shared Policy Aggregate for all Named Insureds'),
            deductable: '',
            bolded: false
          }
        ];
      default:
        const coverageDetails = [];
        coverageDetails.push({
          liabilityLimit: this.formatMoney(per, locale),
          coverageDescription: translateString(locale, 'Per Claim Errors & Omissions Liability - Claims Made'),
          deductable: this.getCoverageDeductable(policy, locale),
          bolded: false
        });
        coverageDetails.push({
          liabilityLimit: this.formatMoney(agg, locale),
          coverageDescription: 'Aggregate Per Policy Period',
          deductable: '',
          bolded: false
        });
        if (policy.code.substr(0, 2) === 'EC') {
          coverageDetails.push({
            liabilityLimit: '$50,000,000',
            coverageDescription: translateString(locale, 'Policy Aggregate for all B & C certificate holders in the plan'),
            deductable: '',
            bolded: false
          });
        }
        coverageDetails.push({
          liabilityLimit: translateString(locale, 'Included'),
          coverageDescription: translateString(locale, 'Fraudulent Acts Endorsement for provinces where it is a regulatory requirement'),
          deductable: translateString(locale, '$1,000 each Claim'),
          bolded: true
        });
        coverageDetails.push({
          liabilityLimit: '$35,000',
          coverageDescription: translateString(locale, 'Disciplinary Defence Costs Extension Per Certificate, Per Claim, Per Policy Period'),
          deductable: translateString(locale, 'As above and as applicable'),
          bolded: false
        });
        coverageDetails.push({
          liabilityLimit: '$250,000',
          coverageDescription: translateString(locale, 'Disciplinary Defence Costs Extension Shared Policy Aggregate for all Certificates Holders'),
          deductable: '',
          bolded: false
        });
        return coverageDetails;
    }
  };
  render() {
    const { policy, pricing, quoteDetails, quote, locale } = this.props;
    const coverageDetails = this.getCoverageDetails(policy, pricing, locale);

    const title = this.getCoverageTitle(policy);
    const description = this.getCoverageDescription(policy, quoteDetails, locale);
    return (
      <>
        <View style={styles.coverage}>
          <View style={styles.coverageInfo}>
            <Text style={styles.coverageInfoH1}>{title}</Text>
            <Text style={styles.coverageInfoText}>
              {description}
              {/* 
              Life Insurance, Accident and Sickness, Disability & Critical Illness, Group Benefits, Pension Plans (incl. RRSP and RRIF), GICs,
              Segregated Funds, Mutual Funds Term Deposits, Fee for Service, Financial Divorce Specialist, Personal Income Tax Preparation 
              */}
            </Text>
          </View>
          <View>
            <View style={styles.rowBoldTan}>
              <Text style={styles.row1}>{translateString(locale, 'Liability Limit')}</Text>
              <Text style={styles.row2}>{translateString(locale, 'Coverage Description')}</Text>
              <Text style={styles.row3}>{translateString(locale, 'Deductible')}</Text>
            </View>
            {coverageDetails.map((row, i) => {
              const rowType = row.bolded ? styles.rowBold : styles.row;
              return (
                <View style={rowType} key={i}>
                  <Text style={styles.row1}>{row.liabilityLimit}</Text>
                  <Text style={styles.row2}>{row.coverageDescription}</Text>
                  <Text style={styles.row3}>{row.deductable}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.retro}>
            <Text style={styles.retroBold}>{translateString(locale, 'Retroactive Date')}:</Text>
            <Text style={styles.retroText}>{translateString(locale, 'Not applicable')}</Text>
          </View>
        </View>
      </>
    );
  }
}

// export default connect(mapStateToProps, {
//   fetchLocale,
// })(CertificateCoverageDetails);
export default CertificateCoverageDetails;
