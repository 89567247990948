import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  Modal
} from "react-bootstrap";

export default class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      show: false
    };
  }

  handleClose = event => {
    this.setState({ show: false });
  };
  handleShow = event => {
    this.setState({ show: true });
  };

  handleModalSubmit = event => {
    event.preventDefault();
    console.log("Ready for Logic!");
    this.handleClose();
  };

  render() {
    return (
      <React.Fragment>
        <span
          className={"modal-trigger" + this.props.className}
          onClick={this.handleShow}
        >
          {" "}<span className="link">{this.props.trigger}</span>
        </span>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          show={this.state.show}
        >
          <Modal.Body>
            <form onSubmit={this.handleModalSubmit}>
              <div className="formDescription">
                Please re-enter your correct email address below and we’ll send
                the quote again.
              </div>
              <FormGroup controlId="email">
                <FormLabel>New Email Address</FormLabel>
                <FormControl autoFocus type="text" />
              </FormGroup>
              <Button variant="primary" type="submit">
                Send Again
              </Button>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
