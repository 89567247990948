// the tax govt ids are pulled from a Get_Lookup of type ActiveGovernmentPayableVendors
// the tax contact ids are pulled from a Get_Contact call with the govt_id as account id and type 'VEND', though they seem to be the same as the govt_id
const rates = {
  AB: [],
  BC: [],
  MB: [
    { label: 'Manitoba RST', tax: 8, contact_id: 65584, govt_id: 65584 },
  ],
  NB: [],
  NL: [
    { label: 'Newfoundland and Labrador RST', tax: 15, contact_id: 65585, govt_id: 65585 }
  ],
  NS: [],
  NU: [],
  NT: [],
  ON: [
    { label: 'Ontario RST', tax: 8, contact_id: 65586, govt_id: 65586 }
  ],
  PE: [],
  QC: [
    { label: 'Quebec RST', tax: 9, contact_id: 65590, govt_id: 65590 }
  ],
  SK: [],
  YT: [],
}
export default rates;
