import React, { Component } from "react";
import PrintFormLogic from './PrintFormLogic';

export default class PrintFormGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (event, val) => {
    // this.handleConditional(event);
    // console.log(event, val);
  };

  render() {

    //map with 2 diff data types
    return (
      <div className="PrintFormGroup">
        {
          this.props.data.map((field, i) => {
            if (field.FormControl) {
              // if (this.props.dependency) {
              //   if (field.condition) {
              //     this.setState({ condition: field.condition })
              //   }
              // }

              return <PrintFormLogic
                data-condition={field.condition ? field.condition : null}
                condition={field.condition ? field.condition : null}
                activeValue={this.props.activeValue ? this.props.activeValue : null}
                data-parent={this.props.parent}
                key={this.props.parent + "Group_" + i}
                controlId={field.controlId}
                data-id={field.controlId}
                data={field}
                className={this.props.className ? this.props.className : null}
                i={i}
                handleChange={this.handleChange}
              >
                {this.props.children}
              </PrintFormLogic>
            } else {
              return (
                <React.Fragment key={i}>
                  {
                    Object.keys(field).map((fieldKey, j) => {
                      const fieldGroup = field[fieldKey][0].details[0];
                      // if (this.props.dependency) {
                      //   if (fieldGroup.condition) {
                      //     this.setState({ condition: fieldGroup.condition })
                      //   }
                      // }
                      return (
                        // <React.Fragment key={j}>
                        // </React.Fragment>
                        <PrintFormLogic
                          data-condition={fieldGroup.condition ? fieldGroup.condition : null}
                          condition={fieldGroup.condition ? fieldGroup.condition : null}
                          activeValue={this.props.activeValue ? this.props.activeValue : (fieldGroup.activeValue ? fieldGroup.activeValue : null)}
                          parent={this.props.parent}
                          key={this.props.parent + "Group_" + j}
                          j={j}
                          controlId={fieldGroup.controlId}
                          data-id={fieldGroup.controlId}
                          data={fieldGroup}
                          className={this.props.className ? this.props.className : null}
                          handleChange={this.handleChange}
                        >
                          {this.props.children}
                        </PrintFormLogic>

                      );
                    })
                  }
                </React.Fragment>
              )
            }
          })
        }
      </div>
    )

    // <FormGroup controlId={this.props.data.controlId} className={this.props.data.className ? this.props.data.className : null}>
    //   {this.props.children}
    //   {this.props.data.formLabel ? <FormLabel>{this.props.data.formLabel}</FormLabel> : null}
    //   {this.getFormControl()}
    // </FormGroup>
  }
}
