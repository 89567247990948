import React, { Component } from "react";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import _ from "lodash";
import {
  loadPricing,
  reversePolicyLookup,
} from "../Assets/data/pricing";
import { fetchPricing } from "../redux/actions/pricing";
import { saveQuote, clearQuote } from "../redux/actions/quote";
import { getPricing, getQuote, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from '../Assets/helpers/translations/translations';

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state),
  };
};
export class PolicyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lower: 0,
      upper: 0,
      premium: 0,
      tax: 0,
      premiumRate: "Annually",
      pricing: null,
      policyData: null,
    };
  }
  setPolicyData = () => {
    const { user, policy } = this.props;
    const policyValues = reversePolicyLookup(policy.policy_type_code);
    const policyData = {
      name: user.account_name,
      email: user.account_value.account_email,
      location: user.account_value.address.state_or_province_code,
      startDate: dateFormat(policy.expiration_date, "yyyy-mm-dd"),
      ...policyValues.quoteCoverageObject,
      clientID: user.client_id,
      City: user.account_value.address.city,
      PostalCode: user.account_value.address.zip_or_postal_code,
      Street:
        user.account_value.address.street_1 +
        " " +
        user.account_value.address.street_2 +
        " " +
        user.account_value.address.street_3,
      Province: user.account_value.address.state_or_province_code,
      renewal: true
    };
    this.setState({ policyData });
    this.setState({ lower: policyValues.lower });
    this.setState({ upper: policyValues.upper });
    const { pricing, fetchPricing } = this.props;
    if (!pricing || _.isEmpty(pricing)) fetchPricing();
    else this.setState({ pricing });

    let prices = loadPricing(policyData, pricing)[0];
    this.setState({ premium: prices.amount });
  };

  passPolicyData = () => {
    const { saveQuote } = this.props;
    // console.log("polyData", this.state.policyData);
    clearQuote();
    saveQuote(this.state.policyData);
    this.props.handleRenewal();
    // console.log(this.state.policyData);
  };
  // getPricing = () => {
  //   const { quote, pricing } = this.props;
  //   console.log("loading pricing", quote);
  //   let prices = loadPricing(quote, pricing);
  //   console.log(prices);
  //   // this.setState({ prices });
  // };
  // getTaxes = () => {
  //   const { policyData } = this.state;
  //   let tax;
  //   if (policyData && policyData.location) {
  //     console.log(taxRates);
  //     console.log(taxRates[policyData.location]);
  //     tax = taxRates[policyData.location];
  //   }
  //   this.setState({ tax });
  // };
  componentDidMount() {
    // console.log("user, policy", this.props.user, this.props.policy);
    this.setPolicyData();
  }
  
  async componentDidUpdate(prevProps, prevState) {
    const { locale, fetchLocale  } = this.props;

    if (!locale) fetchLocale();
  }
  getLimits = () => {};

  render() {
    const { locale } = this.props;
    const { premium, premiumRate } = this.state;
    const policy = this.props.policy;
    return (
      <div className="policy-card">
        <div className="policy-card__top-bar">
          <span>{translateString(locale, "Policy")} {policy.policy_id}</span>
        </div>
        <div className="policy-card__body">
          <div className="policy-card__header">
            <h4>{_.unescape(policy.description)}</h4>
            <p className="text-grey">
              {this.state.lower} {translateString(locale, "per claim limit")}
              <br />
              {this.state.upper} {translateString(locale, "aggregate claim limit")}
            </p>
          </div>
          <div className="policy-card__info-links">
            <p>
              <a href="/" className="download-link">
              {translateString(locale, "Download Certificate")}
              </a>
            </p>
            <p>
              <a href="/" className="download-link">
              {translateString(locale, "Policy Details")}
              </a>
            </p>
          </div>
          <div className="policy-card__premium-details">
            <h4>{translateString(locale, "Premium")}</h4>
            <p className="text-primary">
              {" "}
              ${premium} {premiumRate}{" "}
            </p>
            <p className="text-grey policy-card__remained">
            {translateString(locale, "(Paid Annually, Balance of $295.32 reminaing)")}
            </p>
            <div className="policy-card__expiry-date">
              <p>
              {translateString(locale, "Expiry Date:")}{" "}
                <span>
                  {dateFormat(
                    policy.expiration_date,
                    "mmmm dS, yyyy, h:MM:ss TT"
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        {policy.policy_type_code.substr(0, 2) !== "SP" ? (
          <div className="policy-card__footer">
            <button onClick={this.passPolicyData} className="btn btn-primary">
            {translateString(locale, "Renew Policy")}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  getQuote,
  saveQuote,
  fetchPricing,
})(PolicyCard);
