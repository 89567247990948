import _ from "lodash";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import steps from "../../Assets/data/stepsData";
import Coupon from "../../components/Coupon";
import EmailModal from "../../components/EmailModal";
import FormTopNav from "../../components/FormTopNav";
import PricingDetails from "../../components/PricingDetails";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { getQuote } from "../../redux/reducers";

export const mapStateToProps = (state, ownProps) => {
  return {
    quote: getQuote(state),
  };
};
export class QuoteSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentStep: 5,
      steps: steps,
      error: false,
    };
  }
  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setState({ quote });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setState({ quote });
    if (!_.isEqual(this.state.quote, prevState.quote)) {
      await this.handleSaveQuote();
    }
  }

  handleSaveQuote = async () => {
    // const { quote } = this.state;
    // console.log(quote);
    //await this.processQuotes();
  };

  validateForm() {
    return true;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleCoupon = (coupon) => {
    coupon = this.state.coupon + " " + coupon;
    this.setState({ coupon });
  };

  handleModalSubmit = (event) => {
    event.preventDefault();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
      this.props.history.push(this.state.nextPage);
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { showPrice, steps, currentStep, error } = this.state;

    return (
      <Container className="Email" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={steps} currentStep={currentStep} />
            <Row className="QuoteSent" noGutters>
              <Col>
                <Row noGutters>
                  <Col>
                    <div className="mobilePricing">
                      <h1 className="chevron">Get a Quote</h1>
                      {showPrice ? <PricingDetails /> : null}
                    </div>
                    {!error && <h3>Quote Saved!</h3>}
                    {error && <h3>We had an issue saving your quote.</h3>}
                  </Col>
                </Row>
                {!error && (
                  <Row noGutters>
                    <Col>
                      <div>
                        <p>
                          We’ve saved your quote. Use the link we just sent to{" "}
                          <span className="bold greenText underline">
                            {this.state.email}
                          </span>{" "}
                          to set your password and you can access your
                          information at any time.
                        </p>
                      </div>
                      <div className="bold greenText underline">
                        <EmailModal
                          for="email"
                          trigger="Not the right email address?"
                        />
                      </div>
                    </Col>
                    <Col xs={"auto"} className="policy">
                      <Link to="/policy" className="btn btn-tertiary edit">
                        Review Policy Details
                      </Link>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {!error && (
              <Row className="emailCtas">
                <Col lg={4}>
                  <div className="ctaCopy">
                    Click <span className="greenText bold">BUY NOW</span> below
                    if you would like to purchase this policy right now.
                  </div>
                  <Link to="/checkout" className="btn btn-primary">
                    Buy Now
                  </Link>
                </Col>
                <Col lg={4}>
                  <div className="ctaCopy">
                    Click <span className="greenText bold">EMAIL QUOTE</span>{" "}
                    below to immediately send your quote straight to your inbox.
                  </div>
                  <Link to="/quote/email" className="btn btn-primary ghost">
                    Email Quote
                  </Link>
                </Col>
              </Row>
            )}
            <div className="FormBottomNav Line Green top">
              <Link className="prevQuote" to="/quote/review">
                <i className="fa fa-chevron-left"></i>Go Back
              </Link>
            </div>
          </Col>
          <Col lg={"auto"} className="quotePricing sideBar">
            <Coupon handleCoupon={this.handleCoupon} />
            <PricingDetails />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchQuote,
  saveQuote,
})(QuoteSave);
