import moment from 'moment';
import EpicAPI from "../../Assets/api/epic";


export default async function getUtcTimestamp() {

  const maxRetries = 3;
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      // new api
      const utcTimeApi = await fetch('https://timeapi.io/api/timezone/zone?timeZone=UTC');
      const utcObj = await utcTimeApi.json();
      // console.log('utcObj: ', utcObj);
      if (!utcObj.currentLocalTime) throw new Error('Invalid response');

      //timestamp should be a value like 2024-10-30T16:41:43Z
      const timestamp = moment(utcObj.currentLocalTime + 'Z').add(1, 'minute').utc().format();
      // console.log('timestamp: ', timestamp);
      return timestamp;

    } catch (error) {
      attempts++;
      console.error(`Attempt ${attempts} failed: ${error.message}`);
      await EpicAPI.writeToLogs(error,'getUtcTimestamp', JSON.stringify({ 'attempts': attempts }), '_getUtcTimestamp');
      if (attempts === maxRetries) {
        const currenTimestamp = moment().add(1, "minutes").utc().format();
        console.error('API Max retries reached. Returning current time + 1 minute.', currenTimestamp);
        return currenTimestamp; 
      }
      // Wait for 1 second before the next attempt
      await new Promise(resolve => setTimeout(resolve, 1000)); 
    }
  }
}