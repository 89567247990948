// const testQuote = {
//   FirstName: "Ryan",
//   Last: "Sanford",
//   email: "ryan+tc3@nerder.com",
//   startDate: "2022-07-28",
//   location: "BC",
//   Middle: "",
//   clientID: 86086,
//   insertedTransactions: false,
//   cyberPrivacy: true,
//   cyberPrivacyPrice: 25000,
//   lifeInsurance: true,
//   lifeInsurancePrice: 1000000,
//   iiroc: true,
//   iirocPrice: 1000000,
//   unlicensedPlanners: true,
//   unlicensedPlannersPrice: 1000000,
//   unlicensedPlannersCheckbox: ["Certified Health Insurance Specialist, CHS™"],
//   licensedAdmin: true,
//   licensedAdminPrice: 1000000,
//   advocisMember: "no",
//   firstLicensed: "2018",
//   activePolicies: [123237, 123238, 123239, 123240, 123241],
//   policies: [
//     {
//       id: 123237,
//       location: "BC",
//       code: "EA1",
//       description: "E &amp; O - Coverage A",
//       start_date: "2022-07-28T06:00:00Z",
//       end_date: "2023-07-28T06:00:00Z",
//     },
//     {
//       id: 123238,
//       location: "BC",
//       code: "EB1",
//       description: "E &amp; O - Coverage B",
//       start_date: "2022-07-28T06:00:00Z",
//       end_date: "2023-07-28T06:00:00Z",
//     },
//     {
//       id: 123239,
//       location: "BC",
//       code: "EC1",
//       description: "E &amp; O - Coverage C",
//       start_date: "2022-07-28T06:00:00Z",
//       end_date: "2023-07-28T06:00:00Z",
//     },
//     {
//       id: 123240,
//       location: "BC",
//       code: "ED1",
//       description: "E &amp; O - Coverage D",
//       start_date: "2022-07-28T06:00:00Z",
//       end_date: "2023-07-28T06:00:00Z",
//     },
//     {
//       id: 123241,
//       location: "BC",
//       code: "SP1",
//       description: "Security &amp; Privacy Liability",
//       start_date: "2022-07-28T06:00:00Z",
//       end_date: "2023-07-28T06:00:00Z",
//     },
//   ],
//   Answer1: "no",
//   Answer2: "no",
//   Answer3: "no",
//   Answer4: "no",
//   Answer5: "no",
//   Answer6: "no",
//   Answer7: "no",
//   flaggedUser: false,
//   Street: "fdsa",
//   City: "Calgary",
//   Province: "AB",
//   PostalCode: "a1a 1a1",
//   client_data: {
//     postal: "a1a 1a1",
//     province: "AB",
//     city: "Calgary",
//     address: "fdsa",
//   },
//   legalName: "",
//   companyList: ["firm 1"],
//   selectedCompanies: ["firm 1"],
//   lifeInsuranceProvince: [
//     "Alberta",
//     "British Columbia",
//     "Manitoba",
//     "New Brunswick",
//     "Newfoundland and Labrador",
//     "Northwest Territories",
//     "Nova Scotia",
//     "Nunavut",
//     "Ontario",
//     "Prince Edward Island",
//     "Québec",
//     "Saskatchewan",
//     "Yukon Territory",
//   ],
//   accidentSickness: true,
//   accidentSicknessProvince: ["Alberta", "British Columbia", "Manitoba", "New Brunswick"],
//   mutualFunds: true,
//   mutualFundsProvince: ["Newfoundland and Labrador", "Northwest Territories", "Nova Scotia", "Nunavut"],
//   segregatedFunds: true,
//   segregatedFundsProvince: ["Ontario", "Prince Edward Island", "Québec", "Saskatchewan"],
//   gic: true,
//   gicProvince: ["Yukon Territory"],
//   securities: true,
//   securitiesProvince: [
//     "Alberta",
//     "British Columbia",
//     "Manitoba",
//     "New Brunswick",
//     "Newfoundland and Labrador",
//     "Northwest Territories",
//     "Nova Scotia",
//     "Nunavut",
//     "Ontario",
//     "Prince Edward Island",
//     "Québec",
//     "Saskatchewan",
//     "Yukon Territory",
//   ],
//   incomeTax: true,
//   incomeTaxProvince: ["British Columbia", "New Brunswick", "Northwest Territories", "Nunavut", "Prince Edward Island", "Saskatchewan"],
//   executorEstate: true,
//   executorEstateProvince: ["Alberta", "Manitoba", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Québec", "Yukon Territory"],
//   notaryPublic: true,
//   notaryPublicProvince: ["British Columbia", "New Brunswick", "Northwest Territories", "Nunavut", "Prince Edward Island", "Saskatchewan"],
//   commissionerOaths: true,
//   commissionerOathsProvince: [
//     "Alberta",
//     "British Columbia",
//     "Manitoba",
//     "New Brunswick",
//     "Newfoundland and Labrador",
//     "Northwest Territories",
//     "Nova Scotia",
//     "Nunavut",
//     "Ontario",
//     "Prince Edward Island",
//     "Québec",
//     "Saskatchewan",
//     "Yukon Territory",
//   ],
//   feeServicePlanning: true,
//   feeServicePlanningProvince: [
//     "Alberta",
//     "British Columbia",
//     "Manitoba",
//     "New Brunswick",
//     "Newfoundland and Labrador",
//     "Northwest Territories",
//     "Nova Scotia",
//   ],
//   divorceSpecialist: true,
//   divorceSpecialistProvince: ["Nunavut", "Ontario", "Québec", "Prince Edward Island", "Saskatchewan", "Yukon Territory"],
//   other: true,
//   otherText: "Full coverage for Intentional accidents",
//   pre_tax_total: 2810.67,
//   total: 2810.67,
//   taxes: 0,
//   pre_discount_total: 2810.67,
//   firstQuoteNumber: null,
//   taxable_total: 1529.67,
// };
const testQuote = {
  FirstName: "Ryan",
  Last: "Sanford",
  email: "ryan+spspsp@nerder.com",
  manitoba: "No",
  location: "BC",
  startDate: "2023-09-21",
  Middle: "",
  clientID: 86455,
  insertedTransactions: true,
  cyberPrivacy: true,
  cyberPrivacyPrice: 50000,
  lifeInsurance: true,
  lifeInsurancePrice: 2000000,
  licensedAdmin: false,
  advocisMember: "no",
  firstLicensed: "2019",
  activePolicies: [124122, 124123],
  policies: [
    {
      id: 124122,
      location: "BC",
      code: "EA2",
      description: "E &amp; O - Coverage A",
      start_date: "2023-09-21T06:00:00Z",
      end_date: "2024-09-21T06:00:00Z",
    },
    {
      id: 124123,
      location: "BC",
      code: "SP2",
      description: "Security &amp; Privacy Liability",
      start_date: "2023-09-21T06:00:00Z",
      end_date: "2024-09-21T06:00:00Z",
    },
  ],
  Answer1: "no",
  Answer2: "no",
  Answer3: "no",
  Answer4: "no",
  Answer5: "no",
  Answer6: "no",
  Answer7: "no",
  DecD1: null,
  DecD2: null,
  DecD3: null,
  DecD4: null,
  DecD5: null,
  DecD6: null,
  DecD7: null,
  flaggedUser: false,
  Street: "1550 - 8th Street SW, Fourth Floor",
  City: "Calgary",
  Province: "AB",
  PostalCode: "T2R 1K1",
  client_data: {
    postal: "T2R 1K1",
    province: "AB",
    city: "Calgary",
    address: "1550 - 8th Street SW, Fourth Floor",
  },
  legalName: "",
  companyList: ["test1", "test2"],
  selectedCompanies: ["test1", "test2"],
  lifeInsuranceLicensing: true,
  accidentSickness: true,
  mutualFunds: true,
  segregatedFunds: true,
  gic: true,
  lifeInsuranceLicensingProvince: ["Alberta"],
  accidentSicknessProvince: ["New Brunswick", "Newfoundland and Labrador"],
  mutualFundsProvince: ["Ontario", "Prince Edward Island"],
  segregatedFundsProvince: ["New Brunswick", "Nunavut"],
  gicProvince: ["Ontario", "Québec"],
  pre_tax_total: 1125,
  total: 1125,
  taxes: 0,
  pre_discount_total: 1125,
  taxable_total: 715,
  timeMethod: "Monthly",
};
export default testQuote;
