import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from '../Assets/helpers/translations/translations';



export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
  };
};
export class FormTopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidUpdate(prevProps, prevState) {
    const { locale, fetchLocale  } = this.props;

    if (!locale) fetchLocale();
  }
  render() {
    const { locale } = this.props;
    const display = this.props.display !== undefined ? this.props.display : true;
    return (
      <div className={"FormTopNav" + (display ? "" : " d-none" )}>
        <div className="nav">
          {
            this.props.steps.map((step, i) => {
              const bar = !(i === 0) ? <div className="stepBar"></div> : "";
              if (i === this.props.currentStep) {
                return (
                  <div key={"step" + i} className="step active">
                    {bar}
                    <div className="stepNum">{i + 1}</div>
                    <div className="stepName">{translateString(locale,step.name)}</div>
                  </div>
                );
              } else if (i === this.props.currentStep) {
                return (
                  <div key={"step" + i} className="step active">
                    {bar}
                    <div className="stepNum">{i + 1}</div>
                    <div className="stepName">{translateString(locale,step.name)}</div>
                  </div>
                );
              } else if (i > this.props.currentStep) {
                return (
                  <div key={"step" + i} className="step inactive">
                    {bar}
                    <div className="stepNum"></div>
                    <div className="stepName">{translateString(locale,step.name)}</div>
                  </div>
                );
              } else {
                return (
                  <div key={"step" + i} className="step complete">
                    {bar}
                    <Link to={step.url} className="stepNum"><i className="fa fa-check"></i></Link>
                    <div className="stepName">{translateString(locale,step.name)}</div>
                  </div>
                );
              }
            })
          }
        </div>
        <div className="message">
          {
            this.props.currentStep >= this.props.steps.length ?
              <span className="done">{translateString(locale,"Done!")}</span>
              :
              null
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
})(FormTopNav);
