import React, { Component } from 'react';
import FormTopNav from '../../components/FormTopNav';
import { Link } from "react-router-dom";
import Coupon from "../../components/Coupon";
import EmailModal from "../../components/EmailModal";
import steps from '../../Assets/data/stepsData';
import PricingDetails from "../../components/PricingDetails";
import { Row, Col, Container } from "react-bootstrap";

import _ from 'lodash';
import { connect } from 'react-redux'
import { fetchQuote, saveQuote } from '../../redux/actions/quote'
import { getQuote } from '../../redux/reducers'

export const mapStateToProps = (state, ownProps) => {
  return {
    quote: getQuote(state),
  }
}
export class QuoteEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentStep: 5,
      steps: steps
    };
  }
  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);

  }

  setQuoteState(quote) {
    this.setState({
      ...quote
    });
  }
  validateForm() {
    return (
      true
    )
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };
  handleCoupon = coupon => {
    coupon = this.state.coupon + " " + coupon;
    this.setState({ coupon });
  }

  handleModalSubmit = event => {
    event.preventDefault();
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
      this.props.history.push(this.state.nextPage)
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { showPrice, steps, currentStep } = this.state;

    return (
      <Container className="Email" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav
              steps={steps}
              currentStep={currentStep}
            />
            <Row className="row QuoteSent" noGutters>
              <Col>
                <Row noGutters>
                  <Col>
                    <div className="mobilePricing">
                      <h1 className="chevron">Get a Quote</h1>
                      {showPrice ?
                        <PricingDetails />
                        :
                        null
                      }
                    </div>
                    <h3>Quote Sent!</h3>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col>
                    <div><p>We've sent your quote to {this.state.email}.
                      <EmailModal
                        for="email"
                        trigger="Not the right email address"
                      />?</p>
                    </div>
                  </Col>
                  <Col xs={"auto"} className="policy">
                    <Link to="/policy" className="btn btn-tertiary edit">Review Policy Details</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="emailCtas" noGutters>
              <Col lg={4}>
                <div className="ctaCopy">
                  Click <span className="greenText bold">BUY NOW</span> below if you would like to purchase this policy right now.
                  </div>
                <Link to="/checkout" className="btn btn-primary">Buy Now</Link>
              </Col>
              <Col lg={4}>
                <div className="ctaCopy">
                  Click <span className="greenText bold">SAVE QUOTE</span> below if you want to create an account and save the quote for later review and/or purchase.
                  </div>
                <Link to="/quote/save" className="btn btn-primary ghost">Save Quote</Link>
              </Col>
            </Row>
            <div className="FormBottomNav Line Green top">
              <Link className="prevQuote" to="/quote/review"><i className="fa fa-chevron-left"></i>Go Back</Link>
            </div>
          </Col>
          <Col lg={"auto"} className="quotePricing sideBar">
            <Coupon
              handleCoupon={this.handleCoupon}
            />
            {showPrice ?
              <PricingDetails />
              :
              null
            }
          </Col>
        </Row>
      </Container >
    );
  }
}
export default connect(mapStateToProps, {
  fetchQuote, saveQuote
})(QuoteEmail);
