import React, { Component } from "react";
import FormTopNav from "../../components/FormTopNav";
import { Row, Col, Container } from "react-bootstrap";
import steps from "../../Assets/data/checkoutStepsData";
import EpicAPI from "../../Assets/api/epic";
import { connect } from "react-redux";

import { getQuote, getPricing, getForms, getFormLines, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

import { fetchPricing } from "../../redux/actions/pricing";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { fetchForms, saveFormLines, saveForms } from "../../redux/actions/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state),
  };
};

export class CheckoutProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      currentStep: 5,
      isLoading: false,
      completed: [],
      errors: [],
      progress: "While we gather all policy details.",
    };
    this.handlingTransactions = false;
  }

  componentDidMount() {
    const { quote, pricing } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.handleTransactions();
    if (!pricing || _.isEmpty(pricing)) fetchQuote();
    else this.handleTransactions();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { quote, pricing, fetchLocale } = this.props;
    console.log(quote);
    if (!_.isEqual(quote, prevProps.quote) && !_.isEmpty(quote)) this.handleTransactions();
    if (!_.isEqual(pricing, prevProps.pricing) && !_.isEmpty(pricing)) this.handleTransactions();
  };

  async handleTransactions() {
    const { quote, pricing, saveQuote, locale } = this.props;
    const completed = [];
    if (!this.handlingTransactions) {
      if (!!quote && !_.isEmpty(quote) && !!pricing && !_.isEmpty(pricing) && !quote.insertedTransactions) {
        this.handlingTransactions = true;
        let _client = await EpicAPI.getClient({ id: quote.clientID });
        if (_client) {
          let postal = quote.PostalCode.toUpperCase().replace(/\W/g, "").replace(/(...)/, "$1 ");
          let client = {
            client_id: quote.clientID,
            name: quote.account_name ? quote.account_name : _client.account_name,
            code: quote.client_lookup_code ? quote.client_lookup_code : _client.client_lookup_code,
            city: quote.City,
            province: quote.Province,
            postal: postal,
            address: quote.Street,
          };
          this.setState({ progress: translateString(locale, "Updating Client Status") });
          await EpicAPI.updateClientInsured(quote.clientID);
          this.setState({ completed: [translateString(locale, "Updated Client Status")] });
          this.setState({ progress: translateString(locale, "Inserting Transactions") });
          await EpicAPI.insertTransactions(quote, client, pricing);
          this.setState({ completed: [...this.state.completed, translateString(locale, "Inserted Transactions")] });
          /* InsertAttachments for each completed Policy */
          const attachments = [];
          let i = 0;
          for (let policy of quote.policies) {
            this.setState({
              progress: translateString(locale, "Generating and Attaching Certificate for Policy ") + policy.id + " - " + policy.code,
            });
            attachments[i] = await this.uploadAttachmentProcess(policy, quote, pricing, locale);
            this.setState({
              completed: [
                ...this.state.completed,
                translateString(locale, "Generating and Attaching Certificate for Policy ") + policy.id + " - " + policy.code,
              ],
            });
            i++;
          }

          // const uploadPromises = quote.policies.map(async (policy, i) => {
          //   this.setState({
          //     progress: translateString(locale, "Generating and Attaching Certificate for Policy ") + policy.id + " - " + policy.code,
          //   });
          //   return this.uploadAttachmentProcess(policy, quote, pricing, locale);
          // });
          
          // const attachments = await Promise.all(uploadPromises);

          // Check if attachments are valid and not empty
          if (attachments && attachments.length > 0) {
            this.setState({ progress: translateString(locale, "Sending Email with Certificate Information") });
            await EpicAPI.sendEmail(attachments, quote, locale);
            this.setState({ completed: [...this.state.completed, translateString(locale, "Sent certificate email.")] });

            this.setState({ progress: translateString(locale, "Redirecting") });
            await saveQuote({ ...quote, insertedTransactions: true });
            setTimeout(() => {
              this.props.history.push("/checkout/confirmation");
            }, 4000);
          } else {
            // Handle the case where attachments are empty or invalid
            this.setState({ progress: translateString(locale, "No valid attachments found. Cannot send email.") });
            await EpicAPI.writeActivityToLogs("No valid attachments found. Cannot send email.", "handleTransactions", JSON.stringify({ "quote": quote,"pricing": pricing }), "_No_valid_attachments");
            console.error("No valid attachments found:", attachments);
          }
        }
      }
    }
  }

  isValidAttachment = (response) => {
    return (
      response &&
      response["s:Envelope"] &&
      response["s:Envelope"]["s:Body"] &&
      response["s:Envelope"]["s:Body"]["Insert_AttachmentResponse"] &&
      response["s:Envelope"]["s:Body"]["Insert_AttachmentResponse"]["Insert_AttachmentResult"] &&
      response["s:Envelope"]["s:Body"]["Insert_AttachmentResponse"]["Insert_AttachmentResult"]["a:int"]
    );
  };
  /**
   * wait - Waits a certain amount of time
   *
   * @param {int} waitTime time in milliseconds to wait
   * @returns fulfilled promise
   */
  wait = (waitTime) => new Promise((r) => setTimeout(r, waitTime));

  uploadAttachmentProcess = async (policy, quote, pricing, locale, i = 0, attachmentDetails = false) => {
    if (!attachmentDetails) {
      attachmentDetails = await EpicAPI.uploadAttachments(policy, quote, pricing, locale);
    }
    // console.time();
    await this.wait(3000);
    // console.timeEnd();

    const { attachmentID, fileSize, file64 } = attachmentDetails;
    const fileDescription = translateString(locale, "Certificate for Policy") + " " + policy.id + " - " + policy.code;
    
    try {
      const attachmentResponse = await EpicAPI.insertAttachment(quote.clientID, policy.id, attachmentID, fileSize, fileDescription);

      // console.log('attachmentResponse: ',{ attachmentResponse });
      
      await EpicAPI.writeActivityToLogs( JSON.stringify(attachmentResponse.data ?? attachmentResponse) ,"insertAttachment", JSON.stringify({ "policy": policy, "quote": quote, "pricing":pricing, "locale" :locale, "i": i }), "_insertAttachment_in_UAP");

      if (this.isValidAttachment(attachmentResponse)) {
        return { file64, fileSize, policyNumber: policy.id, fileDescription };
      }
    } catch (e) {
      await EpicAPI.writeToLogs(e, "insertAttachment", JSON.stringify({ policy, quote, pricing, locale, i }), "_insertAttachment_in_UAP");
      console.log(e);
    }
    // const attachmentResponse = await EpicAPI.insertAttachment(quote.clientID, policy.id, attachmentID, fileSize, fileDescription);
    //TODO: remove clg after testing resolves
    // console.log({ attachmentResponse });
    // if (this.isValidAttachment(attachmentResponse)) {
    //   return { file64, fileSize, policyNumber: policy.id, fileDescription };
    // }
    if (i > 10) {
      return false;
    }
    return this.uploadAttachmentProcess(policy, quote, pricing, locale, i + 1, attachmentDetails);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleCoupon = (coupon) => {
    coupon = this.state.coupon + " " + coupon;
    this.setState({ coupon });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
      this.props.history.push(this.state.nextPage);
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { quote, locale } = this.props;
    return (
      <Container className="Confirmation" as="section">
        <Row noGutters>
          <Col lg={8} className="quoteFormWrap">
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
            <div className="formDescription">
              {!quote ? (
                <></>
              ) : (
                <>
                  {false && quote.insertedTransactions ? (
                    <>
                      <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                      <h3>Error</h3>
                      <div className="description">
                        <p>It appears transactions have already been submitted for this account</p>
                        <p>
                          If you think there was an error, please <a href="https://advocisinsurance.ca/contact/">contact an admin</a>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                      <h3>{translateString(locale, "Please wait")}</h3>
                      <div className="description">
                        <p>{translateString(locale, "Please do not reload the page while the icon is spinning")}</p>
                        {!_.isEmpty(this.state.completed) ? (
                          <>
                            <h4>{translateString(locale, "Completed:")}</h4>
                            <ul>
                              {this.state.completed.map((completedTask, i) => {
                                return <li key={i}>{completedTask}</li>;
                              })}
                            </ul>
                          </>
                        ) : (
                          <></>
                        )}
                        <p>
                          {this.state.progress} <FontAwesomeIcon icon="circle-notch" spin={true} color="#00a664" />
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchForms,
  saveForms,
  saveFormLines,
})(CheckoutProcessing);
