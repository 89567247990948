import React, { Component } from "react";
import { Svg, Polygon, Path } from "@react-pdf/renderer";
export class PDFLogoFrench extends Component {
  // <defs>
  // <style>.cls-1{fill:#00a665;}.cls-2{fill:#313e48;}.cls-3{fill:#00a14b;}</style>
  // </defs>
  render() {
    return (
        <Svg 
        style={{ maxWidth: "167px", maxHeight: "39px" }}
        viewBox="0 0 271 46.59">
          <Polygon 
          class="cls-1" 
          fill="#00a665" 
          points="113.6 8.13 113.6 4.33 109.55 4.33 109.55 5.62 112.06 8.13 113.6 8.13"></Polygon>
          <Path 
          class="cls-2" 
          fill="#313e48" 
          d="M11.82,9.8,3.68,32H7.74l1.89-5.27H18L19.87,32h4.05L15.78,9.8Zm-.88,13.38,2.88-7.93,2.84,7.93Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-2" 
          fill="#313e48" 
          d="M83.49,16.93a9.6,9.6,0,0,0-9,0,7.61,7.61,0,0,0-2.91,3,8.72,8.72,0,0,0-1,4.15,8.58,8.58,0,0,0,1,4.12,7.64,7.64,0,0,0,2.91,3,9.6,9.6,0,0,0,9,0,7.64,7.64,0,0,0,2.91-3,8.58,8.58,0,0,0,1-4.12,8.72,8.72,0,0,0-1-4.15A7.61,7.61,0,0,0,83.49,16.93ZM82.13,27.71a4.24,4.24,0,0,1-6.24,0,5.47,5.47,0,0,1-1.13-3.61,5.62,5.62,0,0,1,1.13-3.66,4.22,4.22,0,0,1,6.24,0,5.62,5.62,0,0,1,1.13,3.66A5.47,5.47,0,0,1,82.13,27.71Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-2"
          fill="#313e48" 
          d="M102.22,29.08a4.68,4.68,0,0,1-3.56-1.41,5,5,0,0,1-1.34-3.57,5,5,0,0,1,1.3-3.62,4.83,4.83,0,0,1,3.63-1.36,8,8,0,0,1,3.66.89l.33-3.17a9,9,0,0,0-4.38-1,9.67,9.67,0,0,0-4.77,1.1,7.09,7.09,0,0,0-2.94,3,8.91,8.91,0,0,0-1,4.2,8.71,8.71,0,0,0,1,4.15,7.14,7.14,0,0,0,2.92,3,9.36,9.36,0,0,0,4.66,1.09,12,12,0,0,0,2.5-.25,8.45,8.45,0,0,0,2.08-.7L106,28A7.57,7.57,0,0,1,102.22,29.08Z" 
          transform="translate(-3.68 -4.97)">
          </Path><Path 
          fill="#313e48" 
          class="cls-2" 
          d="M136.05,27.59A3.86,3.86,0,0,0,135,24.75a9.47,9.47,0,0,0-3.43-1.95c-1-.36-1.65-.64-2.06-.84a3.07,3.07,0,0,1-1-.68,1.42,1.42,0,0,1-.35-1,1.37,1.37,0,0,1,.57-1.17,2.79,2.79,0,0,1,1.62-.41,6.2,6.2,0,0,1,2.31.44A13.3,13.3,0,0,1,135,20.42l.36-3.36a10.5,10.5,0,0,0-5.06-1.24,6.94,6.94,0,0,0-4.42,1.29,4,4,0,0,0-1.63,3.34,3.92,3.92,0,0,0,1.14,3A10,10,0,0,0,129,25.37a11.89,11.89,0,0,1,2.35,1.09,1.51,1.51,0,0,1,.65,1.32c0,1-.82,1.58-2.48,1.58A7.93,7.93,0,0,1,127,29a14.33,14.33,0,0,1-2.56-1.19l-.36,3.36a14.33,14.33,0,0,0,2.66.89,13.27,13.27,0,0,0,2.86.28,7.87,7.87,0,0,0,4.74-1.23A4.11,4.11,0,0,0,136.05,27.59Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          fill="#313e48" 
          class="cls-2" 
          d="M40.3,8.21v8.94A8,8,0,0,0,32,17a7,7,0,0,0-2.6,3,9.49,9.49,0,0,0-.88,4.12,9.52,9.52,0,0,0,.88,4.12,7,7,0,0,0,2.6,3,7.37,7.37,0,0,0,4.1,1.11A7.93,7.93,0,0,0,38.48,32a7.38,7.38,0,0,0,2-1l.07,1h3.82V10.72L41.84,8.21Zm-.77,19.54a3.35,3.35,0,0,1-2.89,1.33,3.54,3.54,0,0,1-3-1.37,5.68,5.68,0,0,1-1.06-3.61,5.73,5.73,0,0,1,1.06-3.62,3.56,3.56,0,0,1,3-1.36,3.35,3.35,0,0,1,2.89,1.33,6.15,6.15,0,0,1,1,3.65A6.15,6.15,0,0,1,39.53,27.75Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Polygon 
          fill="#313e48" 
          class="cls-2" 
          points="58.58 11.17 54.56 22.14 50.54 11.17 46.55 11.17 52.83 27.03 56.29 27.03 61.88 12.92 60.13 11.17 58.58 11.17"></Polygon>
          <Polygon 
          fill="#313e48" 
          class="cls-2" 
          points="109.55 11.17 109.55 27.03 113.6 27.03 113.6 13.68 111.09 11.17 109.55 11.17"></Polygon>
          <Path 
          class="cls-1" 
          fill="#00a665"
          d="M153.63,5,149.3,9.3l9.35,9.35L131.18,46.12a3.06,3.06,0,0,0,4.33,4.33l31.8-31.8Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-3" 
          fill="#00a14b"
          d="M173.05,33.16l.81-1a4.8,4.8,0,0,0,3.41,1.51,2.17,2.17,0,0,0,2.42-2v0c0-1-.48-1.64-2.68-2.42-2.51-.94-3.49-1.84-3.49-3.55v0a3.35,3.35,0,0,1,3.65-3.21,5.27,5.27,0,0,1,3.63,1.37l-.78,1a4.37,4.37,0,0,0-2.9-1.17,2,2,0,0,0-2.29,1.88v0c0,1,.54,1.66,2.82,2.49,2.46.92,3.36,1.9,3.36,3.51v0c0,1.94-1.59,3.3-3.76,3.3A5.92,5.92,0,0,1,173.05,33.16Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-3"
          fill="#00a14b"
          d="M182.93,30.27v-.09c0-2.75,1.61-4.67,3.72-4.67,2.27,0,3.62,1.94,3.62,4.66a4.88,4.88,0,0,1,0,.52h-6.06c.14,2,1.37,3.09,2.79,3.09a3.19,3.19,0,0,0,2.34-1l.71.79a4.1,4.1,0,0,1-3.1,1.35C184.73,34.89,182.93,33.13,182.93,30.27Zm6.09-.55c-.08-1.72-.9-3.12-2.37-3.12s-2.33,1.23-2.46,3.12Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-3"
          fill="#00a14b" 
          d="M192.54,25.68h1.24v2a3.19,3.19,0,0,1,3.1-2.11v1.35h-.07c-1.68,0-3,1.18-3,3.5V34.7h-1.24Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-3" 
          fill="#00a14b" 
          d="M198.12,25.68h1.36L202,33.09l2.56-7.41h1.32l-3.31,9.09h-1.12Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-3" 
          fill="#00a14b" 
          d="M208,22.36h1.38v1.47H208Zm.07,3.32h1.24v9h-1.24Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path 
          class="cls-3" 
          fill="#00a14b" 
          d="M211.74,30.29v-.12c0-2.74,1.77-4.66,4-4.66a3.83,3.83,0,0,1,2.84,1.23l-.75.93a2.91,2.91,0,0,0-2.11-1c-1.5,0-2.72,1.42-2.72,3.52v.08c0,2.13,1.25,3.5,2.77,3.5a2.91,2.91,0,0,0,2.12-1l.72.83a3.7,3.7,0,0,1-2.91,1.35C213.46,34.89,211.74,33,211.74,30.29Z" 
          transform="translate(-3.68 -4.97)"></Path>
          <Path class="cls-3"
          fill="#00a14b" 
          d="M220,30.27v-.09c0-2.75,1.61-4.67,3.72-4.67,2.27,0,3.62,1.94,3.62,4.66a4.88,4.88,0,0,1,0,.52h-6c.14,2,1.36,3.09,2.78,3.09a3.17,3.17,0,0,0,2.34-1l.71.79a4.08,4.08,0,0,1-3.1,1.35C221.83,34.89,220,33.13,220,30.27Zm6.09-.55c-.08-1.72-.9-3.12-2.37-3.12s-2.33,1.23-2.45,3.12Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#00a14b"  class="cls-3" d="M228.83,33.59l.65-.91a4.12,4.12,0,0,0,2.63,1.09c1,0,1.68-.56,1.68-1.41h0c0-.8-.74-1.21-1.95-1.72-1.44-.62-2.58-1.19-2.58-2.59v0a2.53,2.53,0,0,1,2.8-2.45,4.86,4.86,0,0,1,2.84,1l-.61.95a4,4,0,0,0-2.26-.85c-1,0-1.56.55-1.56,1.26v0c0,.76.78,1.14,2,1.66,1.48.66,2.52,1.28,2.52,2.65v0a2.66,2.66,0,0,1-2.92,2.6A5,5,0,0,1,228.83,33.59Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M177.6,39.18h1.28l4.62,12.19h-1.38l-1.18-3.19h-5.45l-1.18,3.19H173ZM180.51,47l-2.29-6.15L175.92,47Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M185.34,48.27V42.35h1.24V47.9c0,1.61.76,2.51,2.08,2.51a2.33,2.33,0,0,0,2.28-2.56v-5.5h1.25v9h-1.25V49.9a2.9,2.9,0,0,1-2.66,1.64C186.44,51.54,185.34,50.28,185.34,48.27Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M198,45.86l2.23-3.51h1.35l-2.87,4.38,3,4.64h-1.4L198,47.65l-2.34,3.72h-1.36l3-4.61-2.85-4.41h1.4Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M206.6,45.38v-.11c0-3.66,2.29-6.19,5.32-6.19a5,5,0,0,1,3.68,1.49l-.79,1a4.11,4.11,0,0,0-2.91-1.26c-2.23,0-4,2-4,5v.07c0,3,1.72,5,4,5a4.08,4.08,0,0,0,3-1.35l.78.9a5.19,5.19,0,0,1-3.84,1.66C208.84,51.54,206.6,49.06,206.6,45.38Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M217.13,46.94v-.12c0-2.64,1.69-4.64,4-4.64s4,2,4,4.62v.1c0,2.63-1.7,4.66-4,4.66S217.13,49.57,217.13,46.94Zm6.75,0v-.09c0-2-1.22-3.53-2.76-3.53s-2.71,1.51-2.71,3.52v.08c0,2,1.19,3.53,2.73,3.53S223.88,48.91,223.88,46.92Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M227.35,48.27V42.35h1.25V47.9c0,1.61.76,2.51,2.08,2.51A2.33,2.33,0,0,0,233,47.85v-5.5h1.25v9H233V49.9a2.9,2.9,0,0,1-2.66,1.64C228.46,51.54,227.35,50.28,227.35,48.27Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M237,42.35h1.24v2a3.19,3.19,0,0,1,3.1-2.11v1.35h-.07c-1.68,0-3,1.18-3,3.49v4.3H237Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M243.86,49.27V43.46h-1.09V42.35h1.09V39.72h1.25v2.63h2.35v1.11h-2.35v5.6a1.14,1.14,0,0,0,1.3,1.3,2.58,2.58,0,0,0,1.05-.24v1.07a3.05,3.05,0,0,1-1.35.31A2,2,0,0,1,243.86,49.27Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M249.76,39h1.39V40.5h-1.39Zm.07,3.32h1.25v9h-1.25Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M253.52,46.94v-.09c0-2.75,1.61-4.67,3.72-4.67,2.27,0,3.62,1.94,3.62,4.65a4.79,4.79,0,0,1,0,.52h-6.06c.14,2,1.37,3.1,2.79,3.1a3.2,3.2,0,0,0,2.34-1l.71.8a4.1,4.1,0,0,1-3.1,1.35C255.32,51.56,253.52,49.79,253.52,46.94Zm6.09-.56c-.09-1.71-.9-3.11-2.37-3.11s-2.34,1.23-2.46,3.11Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M263.12,42.35h1.25v2a3.19,3.19,0,0,1,3.1-2.11v1.35h-.07c-1.68,0-3,1.18-3,3.49v4.3h-1.25Z" transform="translate(-3.68 -4.97)"></Path>
          <Path fill="#313e48"  class="cls-2" d="M268.51,50.26l.65-.92a4.14,4.14,0,0,0,2.63,1.09c1,0,1.68-.55,1.68-1.4v0c0-.79-.74-1.21-2-1.71-1.44-.62-2.58-1.19-2.58-2.6v0a2.54,2.54,0,0,1,2.8-2.46,4.93,4.93,0,0,1,2.84,1l-.6,1a4,4,0,0,0-2.27-.85c-.95,0-1.56.56-1.56,1.27v0c0,.76.78,1.14,2,1.66,1.49.66,2.52,1.28,2.52,2.65v0a2.65,2.65,0,0,1-2.92,2.59A5,5,0,0,1,268.51,50.26Z" transform="translate(-3.68 -4.97)"></Path>
          </Svg> 
          );
  }
}

export default PDFLogoFrench;
