import React, {Component} from 'react';

export default class AccountPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
  }

  render() {
    return (
      <div className="">
      </div>
    );
  }
}
