const discounts_data =
  [
    {
      advocisMember: [{
        details: [{
          controlId: "advocisMember",
          formLabel: "Are you a current Advocis member?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
            dependencies: [{
              condition: "yes",
              controlId: "memberId",
              formLabel: "Enter your Advocis Member ID:",
              FormControl: [{
                autoFocus: false,
                type: "number",
                value: "",
                placeholder: ""
              }]
            }]
          }]
        }]
      }]
    },
    {
      firstLicensed: [
        {
          details: [
            {
              controlId: "firstLicensed",
              formLabel: "What year were you first licensed for Life and/or Mutual Funds?",
              FormControl: [
                {
                  type: "select",
                  options: ["Choose a Year..."]
                }
              ]
            }
          ]
        }
      ]
    }
  ];
export default discounts_data;
