import React, { Component } from 'react';
// import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import PrintFormGroup from '../components/PrintFormGroup';
import LoaderButton from "../components/loader";
import _ from 'lodash';

import { connect } from 'react-redux'
import { fetchQuote, saveQuote } from '../redux/actions/quote'
import { getQuote } from '../redux/reducers'

export const mapStateToProps = (state, ownProps) => {
  return {
    quote: getQuote(state),
  }
}
export class TalkToBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      Last: "",
      email: "",
      subject: "",
      message: "",

      isLoading: false,
      coupon: "",
    };
  }

  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);
  }

  setQuoteState(quote) {
    this.setState({
      FirstName: !!quote.FirstName ? quote.FirstName : "",
      Last: !!quote.Last ? quote.Last : "",
      email: !!quote.email ? quote.email : "",
    });
  }
  validateForm() {
    return (
      (true)
    );
  }
  handleChange = () => {
    // console.log("test")
  }
  render() {
    const { name, email, subject, isLoading } = { ...this.state };
    const select = [
      "This is about...",
      "... a New Policy",
      "... an Existing Policy",
      "... Payments/Installations",
      "... Potential or Existing Claim",
      "... Something else",
    ];
    const sendMessage = [
      {
        controlId: "name",
        formLabel: "Your Name",
        FormControl: [
          {
            autoFocus: false,
            type: "text",
            value: name,
            placeholder: ""
          }
        ]
      },
      {
        controlId: "email",
        formLabel: "Your Email",
        FormControl: [
          {
            autoFocus: false,
            type: "email",
            value: email,
            placeholder: ""
          }
        ]
      },
      {
        controlId: "subject",
        formLabel: "Subject",
        FormControl: [
          {
            autoFocus: false,
            type: "select",
            value: subject,
            options: select,
            placeholder: ""
          }
        ]
      },
      {
        controlId: "message",
        formLabel: "Your Message",
        FormControl: [
          {
            type: "textarea",
          }
        ]
      },
    ];
    return (
      <div className="TalkToBroker">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="chevron">Talk to a Broker</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 left">
              <div className="greenBox triangle">
                <h3>Call us Now</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.</p>
                <p className="bold">1-800-123-4567</p>
              </div>
              <div className="greenBox triangle">
                <h3>Important Email Addresses</h3>
                <p><a href="mailto:sales@advocisinsurance.ca" className="importantEmail">sales@advocisinsurance.ca</a></p>
                <p><a href="mailto:claims@advocisinsurance.ca" className="importantEmail">claims@advocisinsurance.ca</a></p>
                <p><a href="mailto:payments@advocisinsurance.ca" className="importantEmail">payments@advocisinsurance.ca</a></p>
              </div>
            </div>
            <div className="col-lg-7 right">
              <div className="greenBox">
                <div className="formDescription">
                  <h3>Send us a Message</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.</p>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <PrintFormGroup
                    parent="quote"
                    data={sendMessage}
                    handleChange={this.handleChange}
                    className="formatted"
                  />
                  <LoaderButton
                    block
                    disabled={!this.validateForm()}
                    type="submit"
                    text="Send"
                    isLoading={isLoading}
                  >
                  </LoaderButton>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote, saveQuote
})(TalkToBroker);
