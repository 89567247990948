export const uploadAttachmentXml = (file64) => {
  const xml = `<soapenv:Envelope 
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" 
  xmlns:ns="http://schemas.appliedsystems.com/epic/sdk/2009/07/" 
  xmlns:fil="http://webservices.appliedsystems.com/epic/sdk/2009/11/filetransfer/">
    <soapenv:Header>
      <ns:MessageHeader xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
        <ns:AuthenticationKey>${process.env.REACT_APP_EPIC_KEY}</ns:AuthenticationKey>
        <ns:DatabaseName>${process.env.REACT_APP_EPIC_DB}</ns:DatabaseName>
        <ns:Culture i:nil="true"/>
        <ns:IntegrationKey i:nil="true"/>
        <ns:UserCode i:nil="true"/>
      </ns:MessageHeader>
    </soapenv:Header>
    <soapenv:Body>
      <fil:Upload_Attachment_File>
        <fil:FileDataStream>
          ${file64}
        </fil:FileDataStream>
      </fil:Upload_Attachment_File>
    </soapenv:Body>
  </soapenv:Envelope>`;
  return xml;
};
