import { FETCH_QUOTE_REQUEST, SAVE_QUOTE_SUCCESS } from "../actionTypes";
import FirstAPI from "../../Assets/api/first";

export const saveQuote = (quote) => (dispatch, getState) => {
  // console.log('saving quote');
  dispatch({
    type: SAVE_QUOTE_SUCCESS,
    response: quote,
  });
};

export const fetchQuote = () => (dispatch, getState) => {
  // console.log('fetching quote');
  dispatch({
    type: FETCH_QUOTE_REQUEST,
  });
};

export const clearQuote = () => (dispatch, getState) => {
  console.log("clearquote");
  dispatch({
    type: SAVE_QUOTE_SUCCESS,
    response: {},
  });
};

export const fetchQuoteFromFirst =
  (quote, locale = "EN") =>
  (dispatch, getState) => {
    FirstAPI.getQuoteNumber(quote, locale)
      .then((data) => {
        if (data && data.quoteNumber) {
          quote.firstQuoteNumber = data.quoteNumber;
          dispatch({
            type: SAVE_QUOTE_SUCCESS,
            response: quote,
          });
        } else if (data && data.error) {
          // console.log("data error", data);
          quote.firstQuoteNumber = "error";
          dispatch({
            type: SAVE_QUOTE_SUCCESS,
            response: quote,
          });
        }
      })
      .catch((e) => console.log(e));
  };
