const checkout_steps =
  [
    {
      name: "Declarations",
      url: "/checkout"
    },
    {
      name: "Firm Info",
      url: "/checkout/information"
    },
    {
      name: "Licensing",
      url: "/checkout/licensing"
    },
    {
      name: "Review",
      url: "/checkout/review"
    },
    {
      name: "Checkout",
      url: "/checkout/payment"
    },
    {
      name: "Payment",
      url: "/checkout/payment-process"
    }
  ];
export default checkout_steps;
