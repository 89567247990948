import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container breadcrumb">
        <div className="row">
          <div className="col">
            You are here > <span>Manage Your Account</span>
          </div>
          <div className="col">
            <Link to="/logout" className="btn btn-tertiary edit float-right ">Logout</Link>
          </div>
        </div>
      </div>
    );
  }
}
