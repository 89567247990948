import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Wrap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (
      <div className={this.props.wrapClass}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(Wrap);
