import React, { Component } from "react";
import FormBottomNav from "../components/FormBottomNav";
import FormTopNav from "../components/FormTopNav";
import PrintFormGroup from "../components/PrintFormGroup";
import LoginPrompt from "../components/LoginPrompt";

import { Row, Col, Container, Button } from "react-bootstrap";
import steps from "../Assets/data/stepsData";
import PricingDetails from "../components/PricingDetails";
import _ from "lodash";

import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../redux/actions/quote";
import { getDiscounts, getPricing, getQuote, getLocale } from "../redux/reducers";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";
import EpicAPI from "../Assets/api/epic";
import GeneralModal from "../components/GeneralModal";
import xmlescape from "xml-escape";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    discounts: getDiscounts(state),
    pricing: getPricing(state),
  };
};

export class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      Middle: "",
      Last: "",
      email: "",
      location: "",
      manitoba: "",
      startDate: "",
      loginPrompt: false,
      clientError: false,
      isLoading: false,
      prevPage: "/",
      nextPage: "/quote/coverages",
      currentStep: 0,
      coupon: "",
      steps: steps,
      insertedTransactions: false,
    };
  }

  componentDidMount = async () => {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else {
      let FirstName = quote.FirstName;
      let Last = quote.Last;
      let email = quote.email;
      let location = quote.location === undefined ? "" : quote.location;
      // log initial location
      // console.log("Initial location is: " + location);
      let startDate = quote.startDate;
      this.setState({ quote, FirstName, Last, email, location, startDate });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { quote, saveQuote, locale, fetchLocale } = this.props;
    if (!locale) fetchLocale();
    if (!_.isEqual(quote, prevProps.quote)) {
      let FirstName = quote.FirstName;
      let Middle = "";
      if (quote.Middle) {
        Middle = quote.Middle;
      }
      let Last = quote.Last;
      let email = quote.email;

      let location = quote.location === undefined ? "" : quote.location;
      // log selected location
      // console.log("Selected location is: " + location);

      let startDate = quote.startDate;
      let manitoba = quote.manitoba;
      // if (prevProps.quote && quote.location !== prevProps.quote.location && quote.location === "MB") {
      //   manitoba = "Yes";
      //   const _quote = { ...quote };
      //   _quote.manitoba = "Yes";
      //   saveQuote({ ..._quote });
      // }
      this.setState({ quote, FirstName, Middle, Last, email, location, startDate, manitoba });
    }
    // console.log(this.state);
  }

  validateForm() {
    if (
      !!this.state.FirstName &&
      this.state.FirstName.length > 0 &&
      this.state.FirstName.trim().length > 0 &&
      !!this.state.Last &&
      this.state.Last.length > 0 &&
      this.state.Last.trim().length > 0 &&
      !!this.state.email &&
      this.state.email.length > 0 &&
      !!this.state.location &&
      this.state.location.length > 0 &&
      this.state.location !== "default" &&
      !!this.state.startDate &&
      this.state.startDate.length > 0 &&
      this.state.manitoba !== undefined
    ) {
      // console.log(this.state.startDate);
      const year = this.state.startDate.substring(0, 4);
      const month = this.state.startDate.substring(5, 7);
      const day = this.state.startDate.substring(8, 10);

      const startDate = new Date(year, parseInt(month) - 1, day);

      const localOffset = new Date().getTimezoneOffset();
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + localOffset / 60);

      const now = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      return startDate >= now;
    } else {
      return false;
    }
  }
  // handleChange = (event) => {
  //   this.setState({
  //     [event.target.id]: event.target.value,
  //   });
  // };

  // handleCoupon = (coupon) => {
  //   coupon = this.state.coupon + " " + coupon;
  //   this.setState({ coupon });
  // };

  handleSubmit = async (event) => {
    const { saveQuote } = this.props;
    const { email, location, startDate, quote, manitoba, insertedTransactions } = this.state;

    let clientID = null;

    event.preventDefault();
    this.setState({ isLoading: true });

    const FirstName = this.state.FirstName.trim();
    const Middle = this.state.Middle.trim();
    const Last = this.state.Last.trim();

    this.setState({ FirstName, Middle, Last });

    await EpicAPI.getClient({ FirstName: FirstName, Last: Last, email: xmlescape(email) }, false, true)
      .then((clientInfo) => {
        if (clientInfo && clientInfo.client_id) {
          clientID = clientInfo.client_id;
          if (clientInfo.client_lookup_code) {
            quote.client_lookup_code = clientInfo.client_lookup_code;
          }
          if (clientInfo.account_name) {
            quote.account_name = clientInfo.account_name;
          }
        } else if (clientInfo && _.isArray(clientInfo)) {
          clientID = clientInfo[0].client_id;
        }
      })
      .catch((e) => {
        console.log(e);
      });

    if (!clientID) {
      await EpicAPI.insertClient({
        FirstName: FirstName,
        Last: Last,
        Middle: Middle,
        email: xmlescape(email),
        // pass in location
        location: location,
      })
        .then((client_id) => {
          // console.log(client_id);
          clientID = client_id;
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (!!clientID) {
      try {
        saveQuote({ ...quote, FirstName, Middle, Last, email, location, startDate, clientID, manitoba, insertedTransactions });
        this.setState({ isLoading: false, clientError: false });
        this.props.history.push(this.state.nextPage);
      } catch (e) {
        console.log(e);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false, clientError: true });
    }
  };

  handleModalClose = () => {
    this.setState({ clientError: false });
  };

  render() {
    const { quote } = this.props;
    const { locale } = this.props;

    const { FirstName, Middle, Last, email, location, steps, startDate, currentStep, isLoading, prevPage, loginPrompt, clientError, manitoba } =
      this.state;
    const provinces = ["Select a Province...", "AB", "BC", "MB", "NB", "NL", "NS", "NU", "NT", "ON", "PE", "QC", "SK", "YT"];
    let quoteForm = [
      {
        controlId: "FirstName",
        formLabel: "What's your first name?",
        FormControl: [
          {
            autoFocus: false,
            type: "name",
            value: FirstName,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "Middle",
        formLabel: "What's your middle name?",
        FormControl: [
          {
            autoFocus: false,
            type: "name",
            value: Middle,
            placeholder: "",
            maxLength: "16",
          },
        ],
      },
      {
        controlId: "Last",
        formLabel: "What's your last name?",
        FormControl: [
          {
            autoFocus: false,
            type: "name",
            value: Last,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "email",
        formLabel: "What's your email?",
        FormControl: [
          {
            autoFocus: false,
            type: "email",
            value: email,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "location",
        formLabel: "Where is your Province of Residence?",
        FormControl: [
          {
            autoFocus: false,
            type: "select",
            value: location,
            options: provinces,
            placeholder: "",
          },
        ],
      },
      {
        controlId: "manitoba",
        formLabel: "Are you licensed to sell products in Manitoba?",
        FormControl: [
          {
            autoFocus: false,
            type: "radios",
            value: manitoba,
            options: ["No", "Yes"],
            placeholder: "",
          },
        ],
      },
      {
        controlId: "startDate",
        formLabel: "When do you need your coverage to start?",
        FormControl: [
          {
            required: true,
            type: "date",
            value: startDate,
            placeholder: "",
          },
        ],
      },
    ];
    //TODO: doesn't validate 60 days in future, just sets max that can be edited out.
    return (
      <Container className="MainQuote GetQuote" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            {loginPrompt ? <LoginPrompt link="/login" /> : null}
            <FormTopNav steps={steps} currentStep={currentStep} />
            <div className="formDescription Line Green bottom">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Get a Quote")}</h1>
                <PricingDetails />
              </div>
              <h3>
                {translateString(locale, "Hi")} {!(FirstName === "") ? FirstName : "there"},
              </h3>
              {!!quote && quote.renewal && (
                <p>{translateString(locale, "We’re going to help you get a renewal for E&O coverage as quickly as possible!")}</p>
              )}
              {(!quote || !quote.renewal) && (
                <p>{translateString(locale, "We’re going to help you get a quote for E&O coverage as quickly as possible!")}</p>
              )}
              <p>
                {translateString(
                  locale,
                  "To start, we just need a few things from you. Fill out the simple questions below and we’ll get you set up in no time at all."
                )}
              </p>
            </div>
            <form onSubmit={this.handleSubmit}>
              <PrintFormGroup parent="quote" data={quoteForm} />
              <FormBottomNav
                disabled={!this.validateForm()}
                isLoading={isLoading}
                prevText={translateString(locale, "Start Over")}
                prevPage={prevPage}
                className="Line Green top"
                required={["First Name", "Last Name", "Email", "Province", "Start date of coverage"]}
              />
            </form>
          </Col>
          <Col lg={"auto"} className="quotePricing sideBar"></Col>
        </Row>
        <GeneralModal show={clientError}>
          <h2>{translateString(locale, "Client Error")}</h2>
          <p>{translateString(locale, "We were unable to find or create a client record.")}</p>
          <p>{translateString(locale, "Please try again.")}</p>
          <Button
            variant="primary"
            onClick={() => {
              this.handleModalClose();
            }}>
            {translateString(locale, "OK")}
          </Button>
        </GeneralModal>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
})(Quote);
