import React, { Component } from 'react';
import FormTopNav from '../../components/FormTopNav';
import { Row, Col, Container } from "react-bootstrap";
import steps from '../../Assets/data/checkoutStepsData';
import EpicAPI from '../../Assets/api/epic'
import { connect } from 'react-redux'
import { fetchQuote, saveQuote } from '../../redux/actions/quote'
import { fetchForms, saveFormLines, saveForms } from '../../redux/actions/forms'
import _ from 'lodash'

import { getQuote,getPricing, getForms, getFormLines, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from '../../Assets/helpers/translations/translations';

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state),
  }
}

export class CheckoutConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: steps,
      currentStep: 7,
      isLoading: false,
    };
  }

  componentDidMount () {
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { fetchLocale } = this.props;
  }

  render() {
    const { locale } = this.props;
    return (
      <Container className="Confirmation" as="section">
        <Row noGutters>
          <Col lg={8} className="quoteFormWrap">
            <FormTopNav
              steps={this.state.steps}
              currentStep={this.state.currentStep}
            />
            <div className="formDescription">
              <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
              <h3>{translateString(locale, "Purchase Completed!")}</h3>
              <div className="description">
                <p>{translateString(locale, "Thank you for selecting Advocis Broker Services Inc. as your insurance broker.")}</p>
                <p>{translateString(locale, "To assist you in managing your policy, our secure portal provides you with access to all your insurance documents.")}</p>
                <p>{translateString(locale, "An email with sign up instructions will be sent to you shortly.")}</p>
                <p>{translateString(locale, "We look forward to assisting you with all your insurance needs.")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote, saveQuote, fetchForms, saveForms, saveFormLines, fetchLocale
})(CheckoutConfirmation);

