import React, { Component } from "react";
import { Svg, Polygon, Path } from "@react-pdf/renderer";
export class PDFLogo extends Component {
  render() {
    return (
      <Svg
        viewBox="0 0 457.36 92.11"
        style={{ maxWidth: "167px", maxHeight: "39px" }}
      >
        <Polygon
          class="cls-1"
          fill="#00a665"
          points="224.6 16.07 224.6 8.56 216.59 8.56 216.59 11.1 221.55 16.07 224.6 16.07"
        ></Polygon>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M37.74,19.52,21.64,63.4h8L33.41,53H49.89l3.75,10.4h8L45.56,19.52ZM36,46,41.68,30.3,47.3,46Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M179.43,33.62a19,19,0,0,0-17.71,0,15,15,0,0,0-5.76,6,17.25,17.25,0,0,0-2,8.21,16.87,16.87,0,0,0,2,8.15,15.11,15.11,0,0,0,5.76,5.9,19,19,0,0,0,17.71,0,15.09,15.09,0,0,0,5.75-5.9,16.76,16.76,0,0,0,2-8.15,17.13,17.13,0,0,0-2-8.21A15,15,0,0,0,179.43,33.62Zm-2.68,21.32a8.41,8.41,0,0,1-12.35,0,10.85,10.85,0,0,1-2.23-7.15,11.07,11.07,0,0,1,2.23-7.24,8.35,8.35,0,0,1,12.35,0A11.12,11.12,0,0,1,179,47.79,10.9,10.9,0,0,1,176.75,54.94Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M216.46,57.64a9.28,9.28,0,0,1-7-2.79,9.84,9.84,0,0,1-2.65-7.06,9.91,9.91,0,0,1,2.58-7.15A9.52,9.52,0,0,1,216.53,38a15.93,15.93,0,0,1,7.24,1.75l.64-6.27a17.9,17.9,0,0,0-8.66-2,19.11,19.11,0,0,0-9.43,2.16,14.11,14.11,0,0,0-5.82,5.89,17.8,17.8,0,0,0-1.94,8.31A17.49,17.49,0,0,0,200.5,56a14.24,14.24,0,0,0,5.78,5.86A18.56,18.56,0,0,0,215.49,64a24,24,0,0,0,4.95-.5,17.43,17.43,0,0,0,4.1-1.38l-.71-6.58A14.9,14.9,0,0,1,216.46,57.64Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M283.35,54.69a7.64,7.64,0,0,0-2.07-5.61,18.78,18.78,0,0,0-6.79-3.86c-1.89-.71-3.25-1.26-4.07-1.66a6.32,6.32,0,0,1-1.91-1.35,2.79,2.79,0,0,1-.67-1.94A2.66,2.66,0,0,1,269,38a5.42,5.42,0,0,1,3.2-.82,12.08,12.08,0,0,1,4.55.88,26.47,26.47,0,0,1,4.63,2.51l.71-6.65a20.75,20.75,0,0,0-10-2.44c-3.67,0-6.57.84-8.73,2.54a8,8,0,0,0-3.23,6.61,7.71,7.71,0,0,0,2.26,5.86,19.71,19.71,0,0,0,7.05,3.86A23.22,23.22,0,0,1,274,52.46a3,3,0,0,1,1.29,2.6c0,2.09-1.63,3.14-4.91,3.14a15.44,15.44,0,0,1-4.88-.78,28.14,28.14,0,0,1-5.07-2.36l-.71,6.65A28.26,28.26,0,0,0,265,63.47a26.74,26.74,0,0,0,5.66.56q6.07,0,9.37-2.44A8.08,8.08,0,0,0,283.35,54.69Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M94,16.38V34.06a15.68,15.68,0,0,0-16.52-.31,14.05,14.05,0,0,0-5.14,5.89,20,20,0,0,0,0,16.3,14.08,14.08,0,0,0,5.14,5.9A14.64,14.64,0,0,0,85.63,64a15.73,15.73,0,0,0,4.82-.66,14.82,14.82,0,0,0,3.91-2l.13,2h7.56V21.34l-5-5ZM92.52,55a6.6,6.6,0,0,1-5.72,2.64A7,7,0,0,1,81,54.94a11.32,11.32,0,0,1-2.1-7.15A11.32,11.32,0,0,1,81,40.64,7,7,0,0,1,86.8,38a6.61,6.61,0,0,1,5.72,2.63,12.1,12.1,0,0,1,1.9,7.21A12.08,12.08,0,0,1,92.52,55Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Polygon
          class="cls-2"
          fill="#313e48"
          points="115.83 22.09 107.88 43.78 99.93 22.09 92.04 22.09 104.45 53.43 111.3 53.43 122.34 25.55 118.88 22.09 115.83 22.09"
        ></Polygon>
        <Polygon
          class="cls-2"
          fill="#313e48"
          points="216.59 22.09 216.59 53.43 224.6 53.43 224.6 27.05 219.64 22.09 216.59 22.09"
        ></Polygon>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M318.11,10l-8.56,8.57L328,37l-54.3,54.31a6.06,6.06,0,1,0,8.56,8.57L345.17,37Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M358.11,44.8h8.11a8.19,8.19,0,0,1,5.92,2.05,5.45,5.45,0,0,1,1.58,3.94v.07a5.71,5.71,0,0,1-3.94,5.58c2.81.82,5,2.49,5,5.74v.11c0,4.07-3.32,6.46-8.35,6.46h-8.28Zm13,6.33c0-2.46-1.85-4-5-4h-5.41v8.42h5.17c3.18,0,5.27-1.58,5.27-4.35Zm-4.93,6.67h-5.51v8.63h5.82c3.45,0,5.64-1.58,5.64-4.32v-.06C372.14,59.38,370.05,57.8,366.19,57.8Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M379.43,50.93h2.46v3.93A6.29,6.29,0,0,1,388,50.69v2.67h-.14c-3.32,0-6,2.32-6,6.91v8.48h-2.46Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M390.28,60v-.24c0-5.2,3.35-9.17,7.94-9.17s7.9,3.94,7.9,9.14v.2c0,5.21-3.35,9.21-7.94,9.21S390.28,65.19,390.28,60Zm13.34,0v-.17c0-4-2.39-7-5.44-7s-5.37,3-5.37,6.94v.17c0,4,2.36,7,5.41,7S403.62,63.89,403.62,60Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M410.74,44.05h2.46V60.64l7.91-9.71h3l-6.26,7.49,6.5,10.33h-2.91l-5.27-8.38-2.94,3.46v4.92h-2.46Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M426.41,60v-.17c0-5.44,3.18-9.24,7.36-9.24,4.48,0,7.15,3.84,7.15,9.21a9.22,9.22,0,0,1,0,1h-12c.27,3.94,2.7,6.13,5.51,6.13a6.29,6.29,0,0,0,4.62-2l1.4,1.57a8.09,8.09,0,0,1-6.13,2.67C430,69.13,426.41,65.64,426.41,60Zm12-1.09c-.17-3.39-1.78-6.16-4.68-6.16-2.64,0-4.62,2.43-4.86,6.16Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-2"
          fill="#313e48"
          d="M445.4,50.93h2.46v3.93A6.29,6.29,0,0,1,454,50.69v2.67h-.14c-3.32,0-6,2.32-6,6.91v8.48H445.4Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M356.51,98.66l1.6-2a9.51,9.51,0,0,0,6.74,3c2.88,0,4.79-1.74,4.79-4v0c0-2-.95-3.25-5.3-4.79-5-1.85-6.91-3.62-6.91-7v-.07c0-3.59,3-6.33,7.22-6.33a10.42,10.42,0,0,1,7.18,2.7l-1.54,2a8.57,8.57,0,0,0-5.74-2.33c-2.74,0-4.52,1.68-4.52,3.73v.07c0,2.09,1.06,3.28,5.58,4.93,4.86,1.81,6.63,3.76,6.63,6.94v.07c0,3.83-3.14,6.54-7.42,6.54A11.76,11.76,0,0,1,356.51,98.66Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M376,92.94v-.17c0-5.44,3.19-9.24,7.36-9.24,4.48,0,7.15,3.84,7.15,9.21,0,.24,0,.72,0,1h-12c.27,3.94,2.7,6.13,5.51,6.13a6.28,6.28,0,0,0,4.62-2l1.4,1.57a8.06,8.06,0,0,1-6.12,2.67C379.6,102.08,376,98.59,376,92.94Zm12-1.09c-.17-3.39-1.78-6.16-4.69-6.16-2.63,0-4.62,2.43-4.86,6.16Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M395,83.88h2.47v3.93a6.28,6.28,0,0,1,6.12-4.17v2.67h-.14c-3.31,0-6,2.32-6,6.91v8.48H395Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M406.09,83.88h2.66l5,14.64,5.06-14.64h2.6l-6.53,18h-2.23Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M425.59,77.31h2.74v2.91h-2.74Zm.13,6.57h2.47V101.7h-2.47Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M433,93v-.24c0-5.41,3.49-9.21,7.94-9.21A7.58,7.58,0,0,1,446.56,86l-1.47,1.85a5.75,5.75,0,0,0-4.17-2c-3,0-5.38,2.81-5.38,6.95v.17c0,4.21,2.47,6.91,5.48,6.91a5.72,5.72,0,0,0,4.17-2l1.44,1.64a7.31,7.31,0,0,1-5.75,2.67C436.4,102.08,433,98.38,433,93Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M449.4,92.94v-.17c0-5.44,3.19-9.24,7.36-9.24,4.48,0,7.15,3.84,7.15,9.21,0,.24,0,.72,0,1h-12c.27,3.94,2.7,6.13,5.51,6.13a6.28,6.28,0,0,0,4.62-2l1.4,1.57a8.06,8.06,0,0,1-6.12,2.67C453,102.08,449.4,98.59,449.4,92.94Zm12.05-1.09c-.18-3.39-1.78-6.16-4.69-6.16-2.64,0-4.62,2.43-4.86,6.16Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
        <Path
          class="cls-1"
          fill="#00a665"
          d="M466.79,99.51l1.3-1.81a8.14,8.14,0,0,0,5.2,2.16c1.95,0,3.31-1.1,3.31-2.78v0c0-1.57-1.47-2.4-3.86-3.39-2.84-1.23-5.1-2.36-5.1-5.13v-.07c0-2.91,2.36-4.86,5.54-4.86a9.7,9.7,0,0,1,5.61,1.92L477.6,87.4a7.83,7.83,0,0,0-4.48-1.68c-1.89,0-3.08,1.1-3.08,2.5v.07c0,1.51,1.54,2.26,4,3.29,2.95,1.3,5,2.53,5,5.23v.07c0,3.18-2.57,5.13-5.78,5.13A9.91,9.91,0,0,1,466.79,99.51Z"
          transform="translate(-21.64 -9.97)"
        ></Path>
      </Svg>
    );
  }
}

export default PDFLogo;
