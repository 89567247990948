const checkout_data =
  [
    {
      DecD1: [{
        details: [{
          controlId: "DecD1",
          formLabel: "In the last five years, have you been the recipient of any allegations of professional negligence in writing or verbally?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      DecD2: [{
        details: [{
          controlId: "DecD2",
          formLabel: "Do you provide verbal/written advice to clients or receive direct client questions?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      DecD3: [{
        details: [{
          controlId: "DecD3",
          formLabel: "Do you sell or service products to clients?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      DecD4: [{
        details: [{
          controlId: "DecD4",
          formLabel: "Do you sign any policy or endorsement documents?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      DecD5: [{
        details: [{
          controlId: "DecD5",
          formLabel: "Do you have your own book of business or plan to own a book of business in the future?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      DecD6: [{
        details: [{
          controlId: "DecD6",
          formLabel: "Have you ever owned your own book of business?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
    {
      DecD7: [{
        details: [{
          controlId: "DecD7",
          formLabel: "Do you have discretionary control over client transactions?",
          FormControl: [{
            type: "buttons",
            buttons: [{
              value: "yes",
              options: ["yes", "no"]
            }],
          }]
        }]
      }]
    },
  ];
export default checkout_data;
