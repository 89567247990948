import { combineReducers } from 'redux';
import { FETCH_FORM_SUCCESS, SAVE_FORM_LINE_SUCCESS, SAVE_FORM_SUCCESS } from '../actionTypes'

export const forms = (state = {}, action) => {
    switch (action.type) {
        case FETCH_FORM_SUCCESS:
        case SAVE_FORM_SUCCESS:
            if (action.response) {
                return {
                    ...state,
                    forms: action.response
                };
            }
            return state;
        case SAVE_FORM_LINE_SUCCESS:
            if (action.response) {
                return {
                    ...state,
                    lines: action.response
                };
            }
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    forms
});

export const getForms = state => state.forms.forms;
export const getFormLines = state => state.forms.lines;
