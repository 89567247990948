import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class GeneralModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }
  render() {
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={this.props.show}
      >
        <Modal.Body>{this.props.children}</Modal.Body>
      </Modal>
    );
  }
}
