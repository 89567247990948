import React, { Component } from 'react';
import { Link } from "react-router-dom";


export default class AccountSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {

      pages: [
        {
          page: 'Account Home',
          url: '/account'
        },
        {
          page: 'Personal Information',
          url: '/account/information'
        },
        {
          page: 'Change Password',
          url: '/account/password'
        },
        {
          page: 'Payments',
          url: '/account/payments'
        },

      ]
    };
  }
  render() {
    const currentPath = window.location.pathname;
    const accountPages = this.state.pages.map((page, key) => {

      return (
        <li
          className={currentPath === page.url ? 'active' : null}
          key={key} >
          <Link to={page.url}>{page.page}</Link>
        </li >
      )
    });


    return (
      <ul className="menu-account-sidebar" >
        {accountPages}
      </ul>
    );
  }
}
