import {
  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE,
} from '../actionTypes'

import EpicAPI from '../../Assets/api/epic';

export const fetchPricing = () => (dispatch, getState) => {
  // console.log('fetching pricing');
  dispatch({
    type: FETCH_PRICING_REQUEST
  });

  return EpicAPI.getSupplementalScreen(process.env.REACT_APP_EPIC_PRICING_LINE_ID).then(response => {
      dispatch({
        type: FETCH_PRICING_SUCCESS,
        response
      });
      // console.log('response in fetchPricing:',response);
      return response;
    }
  ).catch(e => {
      console.log(e);
      dispatch({
        type: FETCH_PRICING_FAILURE,
      });
    }
  );
};

export const clearPricing = ()  => (dispatch, getState) => {
  dispatch({
    type: FETCH_PRICING_SUCCESS,
    response: [{},{}]
  });
}

