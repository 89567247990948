import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

import { connect } from "react-redux";
import { getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";


import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";


// export const mapStateToProps = (state, ownProps) => {
//   return {
//     locale: getLocale(state),
//   };
// };

const styles = StyleSheet.create({
  mainView: {
    marginTop: 10,
    marginBottom: 20,
    border: "1 solid #CCC",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: "Helvetica-Bold",
  },
  InfoRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: 7,
  },
  small: {
    display: "flex",
    flexDirection: "row",
    fontSize: 5,
    fontFamily: "Helvetica",
  },
  col3: {
    width: "15%",
    paddingBottom: 10,
    fontWeight: "bold",
  },
  col4: {
    width: "20%",
    paddingBottom: 10,
    fontWeight: "bold",
  },
  col10: {
    width: "50%",
    paddingBottom: 10,
    fontWeight: "bold",
  },
});
export class CertificateInfoBox extends Component {
  componentDidUpdate(prevProps, prevState) {
    // const { locale, fetchLocale } = this.props;
    // if (!locale) fetchLocale();
  }

  render() {
    const { locale } = this.props;
    moment.locale(locale);
    const firstName = this.props.quoteDetails.FirstName;
    const lastName = this.props.quoteDetails.Last;
    const middleInitial = this.props.quoteDetails.Middle;

    const insurer = translateString(locale, "Zurich insurance Company");

    const firms = this.props.quoteDetails.selectedCompanies.join(", ");

    const legalName = this.props.quoteDetails.legalName;

    let address = this.props.CustomForm.screens[0].fields[3].FieldValue; //street
    address += this.props.CustomForm.screens[0].fields[2].FieldValue ? ", " + this.props.CustomForm.screens[0].fields[2].FieldValue : ""; //city
    address += this.props.CustomForm.screens[0].fields[1].FieldValue ? ", " + this.props.CustomForm.screens[0].fields[1].FieldValue : ""; //province
    address += this.props.CustomForm.screens[0].fields[0].FieldValue ? ", " + this.props.CustomForm.screens[0].fields[0].FieldValue : ""; //postal

    const startTime = moment(this.props.policy.start_date).format("MMMM D, YYYY") + " 12:01 am";
    const endTime = moment(this.props.policy.end_date).format("MMMM D, YYYY") + " 12:01 am";

    return (
      <View style={styles.mainView}>
        <View style={styles.InfoRow}>
          <Text style={styles.col3}>{translateString(locale, "Insurer")}:</Text>
          <Text style={styles.col10}>{insurer}</Text>
          <Text style={styles.col3}>{translateString(locale, "Certificate Number")}:</Text>
          <Text style={styles.col4}>{this.props.policy.id}</Text>
        </View>
        <View style={styles.InfoRow}>
          <Text style={styles.col3}>{translateString(locale, "Insured")}:</Text>
          <Text style={styles.col10}>
            {firstName}
            {middleInitial ? " " + middleInitial[0] + ". " : " "}
            {lastName}
            {legalName ? " o/a " + legalName : ""}
          </Text>
        </View>
        <View style={styles.InfoRow}>
          <Text style={styles.col3}>{translateString(locale, "Address")}:</Text>
          <Text style={styles.col10}>{address}</Text>
        </View>
        <View style={styles.InfoRow}>
          <Text style={styles.col3}>{translateString(locale, "Firm Name")}:</Text>
          <Text style={styles.col10}>{firms}</Text>
        </View>
        <View style={styles.InfoRow}>
          <Text style={styles.col3}>{translateString(locale, "Effective Date")}:</Text>
          <Text style={styles.col10}>{startTime}</Text>
          <Text style={styles.col3}>{translateString(locale, "Expiry Date")}:</Text>
          <Text style={styles.col4}>{endTime}</Text>
        </View>
        {/* <View style={styles.small}>
          <Text>*All times shown are local times at the Insured’s postal address</Text>
        </View> */}
      </View>
    );
  }
}

// export default connect(mapStateToProps, {
//   fetchLocale,
// })(CertificateInfoBox);
export default CertificateInfoBox;
