import React, { Component } from 'react';
import EpicAPI from "../../Assets/api/epic";
import _ from 'lodash';

export default class Reference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }
  async componentDidMount() {
    const policies = [
      '155866',
      '124019',
      '124017',
      '155856',
      '155861',
      '155858',
      '155852',
      '155842',
      '155824',
      '155795',
      '155793',
      '155787',
      '155776',
      '155758',
      '155751',
      '155754',
      '155747',
      '155743',
      '155739',
      '155737',
      '155737',
      '155736',
      '155722',
      '155718',
      '124013',
      '124013',
      '155714',
      '155706',
      '124011',
      '124007',
      '155695',
      '155691',
      '155686',
      '155675',
      '155672',
      '155672',
      '155668',
      '155668',
      '155634',
      '155656',
      '155655',
      '155653',
      '155643',
      '155643',
      '155647',
      '155638',
      '155638',
      '155618',
      '155610',
      '155594',
      '155579',
      '155571',
      '155564',
      '155550',
      '155507',
      '155503',
      '155498',
      '155494',
      '155494',
      '155487',
      '155483',
      '155479',
      '155479',
      '155473',
      '155462',
      '155453',
      '155444',
      '155430',
      '155393',
      '155389',
      '155365',
      '155351',
      '155330',
      '155330',
      '155328',
      '155314',
      '155305',
      '155297',
      '155301',
      '155283',
      '155281',
      '155249',
      '155235',
      '155233',
      '155229',
      '155195',
      '155159',
      '155156',
      '155154',
      '155132',
      '155113',
      '155121',
      '155091',
      '155081',
      '155072',
      '155058',
      '155060',
      '155044',
      '155040',
      '155022',
      '155018',
      '154005',
      '154003',
      '154002',
      '153996',
      '153979',
      '153977',
      '153975',
      '153972',
      '153966',
      '153960',
      '153955',
      '153946',
      '153930',
      '153928',
      '153926',
      '153925',
      '153917',
      '153912',
      '153910',
      '153886',
      '153832',
      '153828',
      '153828',
      '153830',
      '153816',
      '153812',
      '153812',
      '153805',
      '153804',
      '153799',
      '153796',
      '153791',
      '153787',
      '153784',
      '153770',
      '153763',
      '153753',
      '153752',
      '153740',
      '153723',
      '153717',
      '153704',
      '153696',
      '153679',
      '153673',
      '153667',
      '153664',
      '153664',
      '153662',
      '153660',
      '153658',
      '153637',
      '153630',
      '153628',
      '153600',
      '153588',
      '153588',
      '153583',
      '153576',
      '153567',
      '153569',
      '153563',
      '153553',
      '153553',
      '153547',
      '153545',
      '153523',
      '153515',
      '153515',
      '153497',
      '153484',
      '153455',
      '153449',
      '153447',
      '153434',
      '153432',
      '153432',
      '153422',
      '153420',
      '153408',
      '153403',
      '153395',
      '153395',
      '153382',
      '153364',
      '153357',
      '153349',
      '153350',
      '153351',
      '153337',
      '153330',
      '153326',
      '153322',
      '153318',
      '153312',
      '153311',
      '153270',
      '153268',
      '143418',
      '153234',
      '153232',
      '153228',
      '153222',
      '153222',
      '153222',
      '123985',
      '153215',
      '153215',
      '153213',
      '153204',
      '153200',
      '153200',
      '153189',
      '153187',
      '153169',
      '153160',
      '153140',
      '153131',
      '153124',
      '153124',
      '153125',
      '153119',
      '153113',
      '153112',
      '153105',
      '153105',
      '153092',
      '153086',
      '153081',
      '153062',
      '153062',
      '153060',
      '153059',
      '153059',
      '153056',
      '153055',
      '153005',
      '153005',
      '153030',
      '153025',
      '153023',
      '153019',
      '153019',
      '153009',
      '153007',
      '153004',
      '153003',
      '152994',
      '152949',
      '152946',
      '152945',
      '152930',
      '152913',
      '152911',
      '152878',
      '152880',
      '152866',
      '152868',
      '152855',
      '152850',
      '152834',
      '152834',
      '152837',
      '152825',
      '152807',
      '152805',
      '152799',
      '152795',
      '152789',
      '152788',
      '152782',
      '152773',
      '152755',
      '152741',
      '152703',
      '152692',
      '152690',
      '152684',
      '152681',
      '152682',
      '152679',
      '152674',
      '152667',
      '152669',
      '152651',
      '152626',
      '152609',
      '152609',
      '152611',
      '152589',
      '152576',
      '152573',
      '152571',
      '152567',
      '152508',
      '152547',
      '152530',
      '152480',
      '152490',
      '152486',
      '152462',
      '152462',
      '152442',
      '152425',
      '152427',
      '152420',
      '152411',
      '152407',
      '152403',
      '152395',
      '152387',
      '152385',
      '152377',
      '152373',
      '152358',
      '123981',
      '152354',
      '152347',
      '152345',
      '152337',
      '152334',
      '152332',
      '152312',
      '152308',
      '152282',
      '152282',
      '152268',
      '152263',
      '152228',
      '152197',
      '152191',
      '152187',
      '152187',
      '152172',
      '152168',
      '152038',
      '152139',
      '152139',
      '152122',
      '152064',
      '152059',
      '152043',
      '152039',
      '152030',
      '152028',
      '152022',
      '152012',
      '151983',
      '151977',
      '151959',
      '151951',
      '151949',
      '151947',
      '151940',
      '151938',
      '151931',
      '151927',
      '151916',
      '151911',
      '151903',
      '151905',
      '151900',
      '151899',
      '151893',
      '151888',
      '151880',
      '151877',
      '151872',
      '151862',
      '151858',
      '151856',
      '151852',
      '151845',
      '151824',
      '151819',
      '151808',
      '151806',
      '151806',
      '151783',
      '151779',
      '151787',
      '151763',
      '151757',
      '151755',
      '151751',
      '151746',
      '151742',
      '151734',
      '151724',
      '151609',
      '151704',
      '151698',
      '151694',
      '151694',
      '151691',
      '151689',
      '151688',
      '151687',
      '151665',
      '151665',
      '151686',
      '151684',
      '151665',
      '151673',
      '151669',
      '151663',
      '151657',
      '151646',
      '151643',
      '151641',
      '151639',
      '151635',
      '151624',
      '151613',
      '151609',
      '151601',
      '151583',
      '151571',
      '151567',
      '151569',
      '151561',
      '151557',
      '151551',
      '151542',
      '151537',
      '151535',
      '151533',
      '151527',
      '151521',
      '151511',
      '151506',
      '151491',
      '151485',
      '151465',
      '151432',
      '151424',
      '151414',
      '151407',
      '151403',
      '151396',
      '151392',
      '151387',
      '151376',
      '151375',
      '151373',
      '151357',
      '151353',
      '151333',
      '151283',
      '151281',
      '151259',
      '151251',
      '151235',
      '151250',
      '151242',
      '151205',
      '151201',
      '151188',
      '151179',
      '151163',
      '151149',
      '151140',
      '151134',
      '151129',
      '151127',
      '151125',
      '151119',
      '151111',
      '151086',
      '151025',
      '151024',
      '151016',
      '151006',
      '151003',
      '150995',
      '150991',
      '150987',
      '150982',
      '150971',
      '150967',
      '150952',
      '150831',
      '150950',
      '150949',
      '150944',
      '150923',
      '150918',
      '150886',
      '150866',
      '150864',
      '150857',
      '150853',
      '150850',
      '150829',
      '150821',
      '150819',
      '150809',
      '150809',
      '150807',
      '150799',
      '150797',
      '150793',
      '150789',
      '150730',
      '150730',
      '150735',
      '123957',
      '150742',
      '150743',
      '150470',
      '150687',
      '150683',
      '123951',
      '150679',
      '150630',
      '150622',
      '150620',
      '150613',
      '150609',
      '150601',
      '150599',
      '150595',
      '150587',
      '150583',
      '150579',
      '150499',
      '150577',
      '150573',
      '150567',
      '150571',
      '150496',
      '150488',
      '150484',
      '150481',
      '150481',
      '150410',
      '150404',
      '150404',
      '150400',
      '150392',
      '150362',
      '150332',
      '150288',
      '150288',
      '150276',
      '150264',
      '150260',
      '150253',
      '150245',
      '150228',
      '150216',
      '150212',
      '150205',
      '150205',
      '150208',
      '150197',
      '150193',
      '150189',
      '150187',
      '150185',
      '150183',
      '150173',
      '150153',
      '150143',
      '150139',
      '150132',
      '150123',
      '150119',
      '150100',
      '150092',
      '150088',
      '150072',
      '150065',
      '150046',
      '149910',
      '150034',
      '150030',
      '148197',
      '150018',
      '150012',
      '150009',
      '150006',
      '150000',
      '123948',
      '149994',
      '149947',
      '149928',
      '149875',
      '149924',
      '143418',
      '149902',
      '149896',
      '149872',
      '149843',
      '149848',
      '149827',
      '149823',
      '149813',
      '149785',
      '149772',
      '149763',
      '149764',
      '149738',
      '149749',
      '149739',
      '149714',
      '149698',
      '149700',
      '148671',
      '148670',
      '148669',
      '148650',
      '148648',
      '148636',
      '148630',
      '148628',
      '148620',
      '148599',
      '148597',
      '148593',
      '148590',
      '148588',
      '148579',
      '148568',
      '148560',
      '148554',
      '148538',
      '148536',
      '148489',
      '148481',
      '148468',
      '148447',
      '148443',
      '148409',
      '148401',
      '148396',
      '148369',
      '148367',
      '148351',
      '148314',
      '148311',
      '143418',
      '148288',
      '148276',
      '148270',
      '148264',
      '148262',
      '148236',
      '148201',
      '148177',
      '148118',
      '148098',
      '148090',
      '148077',
      '148069',
      '148067',
      '148056',
      '148044',
      '148035',
      '147996',
      '147996',
      '147971',
      '147967',
      '147949',
      '147943',
      '147927',
      '147872',
      '147869',
      '147858',
      '147856',
      '147830',
      '147813',
      '147809',
      '147774',
      '147766',
      '147748',
      '147735',
      '147726',
      '147723',
      '147709',
      '147680',
      '147665',
      '147632',
      '147630',
      '147624',
      '147622',
      '147547',
      '147582',
      '147581',
      '146875',
      '147525',
      '147521',
      '147519',
      '147469',
      '147504',
      '147495',
      '147491',
      '147451',
      '147493',
      '147489',
      '147479',
      '147471',
      '147463',
      '147429',
      '147420',
      '147412',
      '147391',
      '147387',
      '147373',
      '147371',
      '147351',
      '147345',
      '147343',
      '147329',
      '147295',
      '147278',
      '147267',
      '147265',
      '147228',
    ];
    const list = []
    for (let i = 0; i < policies.length; i++) {
      const lineID = await EpicAPI.getLine(policies[i]);
      console.log({ policyID: policies[i], lineID });
      const screen = await EpicAPI.getCustomForm(lineID);
      console.log({ screen });
      let paid_field = _.find(screen.screens[6].fields, (field) => field.FieldName === "PaidFlag");
      list.push(`${policies[i]} - Paid ${paid_field.FieldValue}`)
      this.setState({ list })
    }
  }
  render() {
    return (
      <div className="">
        {this.state.list.map(item => {
          return <div className="">{item}</div>;
        })}
      </div>
    );
  }
}
