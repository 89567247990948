import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PolicyCard from "../../components/PolicyCard";
import { Link } from "react-router-dom";
import { matchPath, Redirect } from "react-router";
import EpicAPI from "../../Assets/api/epic";
import _ from "lodash";

export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      clientPolicies: null,
      clientInfo: null,
      fetchFail: false,
    };
  }
  componentDidMount() {
    const match = matchPath(window.location.pathname, {
      path: "/account/:id",
      exact: true,
      strict: true,
    });
    if (match) {
      this.setState({ clientID: match.params.id });

      let client = {
        id: match.params.id,
      };
      EpicAPI.getClient(client)
        .then((clientInfo) => {
          // console.log(d)
          this.setState({ clientInfo });
          // this.setState({ clientLoading: false });
          // console.log("info", this.state.clientInfo);
        })
        .catch((e) => {
          console.log(e);
          this.setState({ fetchFail: true });
        });

      // console.log(client);
      EpicAPI.getClientPolicies(client)
        .then((p) => {
          let clientPolicies = _.values(p);
          clientPolicies.sort((a, b) => {
            const dateA = new Date(a.expiration_date);
            const dateB = new Date(b.expiration_date);
            return dateA - dateB;
          });
          // console.log("policies", clientPolicies);
          this.setState({ clientPolicies });
          // this.setState({ policiesLoading: false });
        })
        .catch((e) => {
          this.setState({ fetchFail: true });
          console.log(e);
        });
      // EpicAPI.getSupplementalScreen(104527).then(d =>
      //   console.log(d)
      // ).catch(e =>
      //   console.log(e)
      // );
    } else {
      this.setState({ clientID: null });
    }
  }
  handleRenewal = () => {
    this.props.history.push("/quote");
  };
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  render() {
    if (this.state.clientID === null) {
      return <Redirect to="/account" />;
    } else {
      return (
        <>
          {this.state.clientInfo === null ||
            this.state.clientPolicies === null ? (
            <div className="account account--main">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "600px" }}
              >
                {!this.state.fetchFail ? (
                  <div className="loading">
                    <div className="loading__text">Loading</div>
                    <div className="loading__icon"></div>
                  </div>
                ) : (
                  <div className="failedLoad">Failed to load Data.</div>
                )}
              </div>
            </div>
          ) : (
            <div className="account account--main">
              <Container>
                <Row>
                  <Col>
                    <h1 className="account-page-title">Manage Your Account</h1>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  {/* <Col md={2}>
                <AccountSidebar current="account" />
              </Col> */}
                  <Col md={12}>
                    <Container>
                      <Row>
                        <Col md={12} style={{ paddingBottom: "1rem" }}>
                          <div className="account-info">
                            <div className="account-info__info">
                              <div className="account-info__header">
                                <h4>
                                  Contact Information
                                  {/* <Link to="/account/information">Edit</Link> */}
                                </h4>
                              </div>
                              <ul>
                                {this.state.clientInfo.account_name ? (
                                  <li>{this.state.clientInfo.account_name}</li>
                                ) : null}
                                {this.state.clientInfo.primary_contact_email ? (
                                  <li>
                                    {
                                      this.state.clientInfo
                                        .primary_contact_email
                                    }
                                  </li>
                                ) : null}
                                {this.state.clientInfo.account_value.number ? (
                                  <li>
                                    {this.formatPhoneNumber(
                                      this.state.clientInfo.account_value.number
                                    )}
                                  </li>
                                ) : null}
                                <li>Advocis Member #{this.state.clientID}</li>
                              </ul>
                            </div>
                            {/* <div className="account-info__discounts">
                              <div className="account-info__header">
                                <h4>
                                  Discounts{" "}
                                  <Link to="/account/information">Edit</Link>
                                </h4>
                                <ul>
                                  <li>Advocis Member #{this.state.clientID}</li>
                                </ul>
                              </div>
                            </div> */}
                            {/* <div className="account-info__payment-preferences">
                              <div className="account-info__header">
                                <h4>
                                  Payment Preferences{" "}
                                  <Link to="/account/payments">Edit</Link>
                                </h4>
                                <ul>
                                  <li>Monthly Premiums</li>
                                  <li>Credit Card ending in 0008</li>
                                </ul>
                              </div>
                            </div> */}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Container style={{ margin: "0", padding: "0" }}>
                            <Row noGutters>
                              <Col xs={9}>
                                <h2 className="policy-section-title">
                                  Your Policies
                                </h2>
                              </Col>
                              <Col xs={3} style={{ textAlign: "right" }}>
                                <Link to="/quote">Add a Policy</Link>
                              </Col>
                            </Row>
                            <Row>
                              {this.state.clientPolicies.map((policy, i) => {
                                return (
                                  <Col
                                    key={i}
                                    sm={12}
                                    lg={4}
                                    style={{ marginBottom: "2rem" }}
                                  >
                                    <PolicyCard
                                      policy={policy}
                                      user={this.state.clientInfo}
                                      handleRenewal={this.handleRenewal}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      );
    }
  }
}
