export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const SET_USER_REQUEST = 'SET_USER_REQUEST';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAILURE = 'SET_USER_FAILURE';

export const FETCH_LOCALE_REQUEST = 'FETCH_LOCALE_REQUEST';
export const FETCH_LOCALE_SUCCESS = 'FETCH_LOCALE_SUCCESS';
export const FETCH_LOCALE_FAILURE = 'FETCH_LOCALE_FAILURE';

export const SET_LOCALE_REQUEST = 'SET_LOCALE_REQUEST';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_FAILURE = 'SET_LOCALE_FAILURE';

export const FETCH_QUOTE_REQUEST = 'FETCH_QUOTE_REQUEST';
export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS';
export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';

export const SAVE_QUOTE_REQUEST = 'SAVE_QUOTE_REQUEST';
export const SAVE_QUOTE_SUCCESS = 'SAVE_QUOTE_SUCCESS';
export const SAVE_QUOTE_FAILURE = 'SAVE_QUOTE_FAILURE';

export const FETCH_PRICING_REQUEST = 'FETCH_PRICING_REQUEST';
export const FETCH_PRICING_SUCCESS = 'FETCH_PRICING_SUCCESS';
export const FETCH_PRICING_FAILURE = 'FETCH_PRICING_FAILURE';

export const FETCH_FORM_FAILURE = 'FETCH_FORM_FAILURE';
export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const SAVE_FORM_FAILURE = 'SAVE_FORM_FAILURE';
export const SAVE_FORM_REQUEST = 'SAVE_FORM_REQUEST';
export const SAVE_FORM_SUCCESS = 'SAVE_FORM_SUCCESS';
export const SAVE_FORM_LINE_SUCCESS = 'SAVE_FORM_LINE_SUCCESS';
