import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Loader = ({
  isLoading,
  text,
  loadingText,
  className = "",
  btnClass = "primary",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    variant={btnClass}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <FontAwesomeIcon icon="circle-notch" className="spinning" />}
    {!isLoading ? text : loadingText}
  </Button>;
export default Loader;
