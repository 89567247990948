import React, { Component } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../components/loader"

import _ from 'lodash';

import { connect } from 'react-redux'
import { fetchQuote, saveQuote } from '../redux/actions/quote'
import { getQuote } from '../redux/reducers'

export const mapStateToProps = (state, ownProps) => {
  return {
    quote: getQuote(state),
  }
}
export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const { quote, fetchQuote } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setQuoteState(quote);

  }

  componentDidUpdate(prevProps, prevState) {
    const { quote } = this.props;
    if (!_.isEqual(quote, prevProps.quote)) this.setQuoteState(quote);

  }

  setQuoteState(quote) {
    this.setState({
      // coverages: !!quote.coverages ? quote.coverages : coverages,
      // cyber: !!quote.cyber ? quote.cyber : cyber,
      ...quote
    });
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
      this.props.history.push("/")
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  handleLogout = async event => {
    this.props.handleLogout();
    this.props.history.push("/login");
  }

  handleSignup = () => {
    this.props.history.push("/signup");
  }

  handlePasswordReset = () => {
    this.props.history.push("/reset-password");
  }

  render() {
    return (
      <div className="Login">
        <div className="formWrapper">
          <div className="Form" >
            <h1 className="chevron">Login</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <form onSubmit={this.handleSubmit}>
              <FormGroup controlId="email">
                <FormLabel>Your Email</FormLabel>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="password">
                <FormLabel>Your Password</FormLabel>
                <FormControl
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
              </FormGroup>
              <LoaderButton
                block
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Login"
                loadingText="Logging in…"

              />
              {/* <Button
              block
              onClick={this.handleSignup}

            >
              Signup
                    </Button>
            <Button
              block
              onClick={this.handlePasswordReset}
            >
              Forgot password?
                    </Button>
            <br />
            <br />
            <Button
              block
              onClick={this.handleLogout}
            >
              Logout
                    </Button> */}
            </form>
          </div>
          <div onClick={this.handlePasswordReset} className="resetPassword">Forgot your password?  Click here</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchQuote, saveQuote
})(Login);