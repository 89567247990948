import { combineReducers } from 'redux';
import { FETCH_PRICING_SUCCESS } from '../actionTypes'

export const pricing = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PRICING_SUCCESS:
            if (action.response) {
                let response = { ...state };
                if(action.response[0]) {
                    response = { ...response, pricing: action.response[0] }
                }
                if(action.response[1]) {
                    response = { ...response, discounts: action.response[1] }
                }
                return response;
            }
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    pricing
});

export const getPricing = state => state.pricing.pricing;
export const getDiscounts = state => {
    // console.log(state);
    return state.pricing.discounts;
}
