import { combineReducers } from 'redux';

import localeReducer, * as fromLocaleReducers from './locale';
import userReducer, * as fromUserReducers from './user';
import quoteReducer, * as fromQuoteReducers from './quote';
import pricingReducer, * as fromPricingReducers from './pricing';
import formsReducer, * as fromFormsReducers from './forms';

export const getLocale = (state) => fromLocaleReducers.getLocale(state.locale);
export const getUser = (state) => fromUserReducers.getUser(state.user);
export const getQuote = (state) => fromQuoteReducers.getQuote(state.quote);
export const getPricing = (state) => fromPricingReducers.getPricing(state.pricing);
export const getDiscounts = (state) => fromPricingReducers.getDiscounts(state.pricing);
export const getForms = (state) => fromFormsReducers.getForms(state.forms);
export const getFormLines = (state) => fromFormsReducers.getFormLines(state.forms);

/**
 * reducers - combineReducers
 *
 * our "root" reducer setup, combines our auth, users, .... etc.. reducers
 * this is the reducer that we set up our inital store with.
 *
 */
export default combineReducers({
    locale: localeReducer,
    user: userReducer,
    quote: quoteReducer,
    pricing: pricingReducer,
    forms: formsReducer,
});
