import React, { Component } from "react";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import { Row, Col, Container, Button } from "react-bootstrap";
import Coupon from "../../components/Coupon";
import PricingDetails from "../../components/PricingDetails";
import PrintFormGroup from "../../components/PrintFormGroup";
import coverages from "../../Assets/data/coveragesData";
import cyber from "../../Assets/data/cyberData";
import steps from "../../Assets/data/stepsData";

import _ from "lodash";
import { connect } from "react-redux";
import { fetchQuote, saveQuote } from "../../redux/actions/quote";
import { fetchPricing } from "../../redux/actions/pricing";
import { getQuote, getPricing, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from '../../Assets/helpers/translations/translations';
import GeneralModal from "../../components/GeneralModal";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state),
  };
};
export class QuoteCoverages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      prevPage: "/quote",
      nextPage: "/quote/discounts",
      currentStep: 1,
      steps: steps,
      Modals: [
        {
          cyberflag: false,
        },
      ],
    };
  }

  componentDidMount() {
    const { quote, pricing, fetchQuote, fetchPricing } = this.props;
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else this.setState({ quote });

    if (!pricing || _.isEmpty(pricing)) fetchPricing();
    else this.setState({ pricing });
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, pricing, locale, fetchLocale  } = this.props;

    if (!locale) fetchLocale();

    if (!_.isEqual(quote, prevProps.quote)) {
      this.setState({ quote });
      this.handleExclusiveToggles(quote, prevProps.quote);
    }
    if (!_.isEqual(pricing, prevProps.pricing)) {
      this.setState({ pricing });
    }
    // console.log("coverages state:", this.state);
  }

  validateForm() {
    if (!this.state.hasOwnProperty("quote")) {
      return false;
    }
    const lifeInsurance = this.state.quote.hasOwnProperty("lifeInsurance") ? this.state.quote.lifeInsurance : false;
    const lifeInsurancePrice = this.state.quote.hasOwnProperty("lifeInsurancePrice") ? this.state.quote.lifeInsurancePrice : false;
    const lifeInsuranceLite = this.state.quote.hasOwnProperty("lifeInsuranceLite") ? this.state.quote.lifeInsuranceLite : false;
    const lifeInsuranceLitePrice = this.state.quote.hasOwnProperty("lifeInsuranceLitePrice") ? this.state.quote.lifeInsuranceLitePrice : false;
    const iiroc = this.state.quote.hasOwnProperty("iiroc") ? this.state.quote.iiroc : false;
    const iirocPrice = this.state.quote.hasOwnProperty("iirocPrice") ? this.state.quote.iirocPrice : false;
    const unlicensedPlanners = this.state.quote.hasOwnProperty("unlicensedPlanners") ? this.state.quote.unlicensedPlanners : false;
    const unlicensedPlannersPrice = this.state.quote.hasOwnProperty("unlicensedPlannersPrice") ? this.state.quote.unlicensedPlannersPrice : false;
    const directorsAndOfficers = this.state.quote.hasOwnProperty("directorsAndOfficers") ? this.state.quote.directorsAndOfficers : false;
    const directorsAndOfficersPrice = this.state.quote.hasOwnProperty("directorsAndOfficersPrice")
      ? this.state.quote.directorsAndOfficersPrice
      : false;
    const cyberPrivacy = this.state.quote.hasOwnProperty("cyberPrivacy") ? this.state.quote.cyberPrivacy : false;
    const cyberPrivacyPrice = this.state.quote.hasOwnProperty("cyberPrivacyPrice") ? this.state.quote.cyberPrivacyPrice : false;
    const licensedAdmin = this.state.quote.hasOwnProperty("licensedAdmin") ? this.state.quote.licensedAdmin : false;
    const licensedAdminPrice = this.state.quote.hasOwnProperty("licensedAdminPrice") ? this.state.quote.licensedAdminPrice : false;

    // console.log(
    //   (lifeInsurance && lifeInsurancePrice) ||
    //   (iiroc && iirocPrice) ||
    //   (unlicensedPlanners && unlicensedPlannersPrice) ||
    //   (directorsAndOfficers && directorsAndOfficersPrice) ||
    //   (cyberPrivacy && cyberPrivacyPrice) ||
    //   (licensedAdmin && licensedAdminPrice)
    // )

    return (
      (lifeInsurance && lifeInsurancePrice) ||
      (iiroc && iirocPrice) ||
      (unlicensedPlanners && unlicensedPlannersPrice) ||
      (directorsAndOfficers && directorsAndOfficersPrice) ||
      (cyberPrivacy && cyberPrivacyPrice) ||
      (licensedAdmin && licensedAdminPrice) ||
      (lifeInsuranceLite && lifeInsuranceLitePrice)
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  toggleShowPrice = (bool) => {
    this.setState({ showPrice: bool });
  };

  handleModalShow = (key, e) => {
    // e.preventDefault();
    //console.log(e);
    let Modals = { ...this.state.Modals };
    Modals[0][key] = true;
    this.setState({
      Modals,
    });
  };

  handleModalClose = (key) => {
    let Modals = { ...this.state.Modals };
    Modals[0][key] = false;
    this.setState({
      Modals,
    });
  };

  handleExclusiveToggles = (newQuote, oldQuote) => {
    console.log("handling toggles...");
    const { saveQuote } = this.props;

    let n = { ...newQuote };
    const o = { ...oldQuote };

    if (n.unlicensedPlanners !== o.unlicensedPlanners) {
      if (n.unlicensedPlanners) {
        n.unlicensedPlannersPrice = 1000000;
        // n.lifeInsurance = false;
        // n.iiroc = false;
        n.licensedAdmin = false;
        // n.directorsAndOfficers = false;
        n.cyberPrivacy = true;
        if (!n.cyberPrivacyPrice) n.cyberPrivacyPrice = 25000;
      }
    }
    if (n.lifeInsurance !== o.lifeInsurance) {
      if (n.lifeInsurance) {
        n.lifeInsurancePrice = 1000000;
        // n.unlicensedPlanners = false;
        n.lifeInsuranceLite = false;
        n.licensedAdmin = false;
        n.cyberPrivacy = true;
        if (!n.cyberPrivacyPrice) n.cyberPrivacyPrice = 25000;
      }
    }

    if (n.lifeInsuranceLite !== o.lifeInsuranceLite) {
      if (n.lifeInsuranceLite) {
        n.lifeInsuranceLitePrice = 1000000;
        // n.unlicensedPlanners = false;
        n.lifeInsurance = false;
        n.licensedAdmin = false;
        n.cyberPrivacy = false;
        if (!n.cyberPrivacyPrice) n.cyberPrivacyPrice = 25000;
      }
    }

    if (n.iiroc !== o.iiroc) {
      if (n.iiroc) {
        n.iirocPrice = 1000000;
        n.unlicensedPlanners = false;
        n.licensedAdmin = false;
        n.cyberPrivacy = true;
        if (!n.cyberPrivacyPrice) n.cyberPrivacyPrice = 25000;
      }
    }

    if (n.licensedAdmin !== o.licensedAdmin) {
      if (n.licensedAdmin) {
        n.licensedAdminPrice = 1000000;
        n.lifeInsurance = false;
        n.lifeInsuranceLite = false;
        n.iiroc = false;
        n.unlicensedPlanners = false;
        n.cyberPrivacy = false;
      }
    }

    if (n.directorsAndOfficers !== o.directorsAndOfficers) {
      if (n.directorsAndOfficers) {
        n.cyberPrivacy = true;
        if (!n.cyberPrivacyPrice) n.cyberPrivacyPrice = 25000;
      }
    }

    if (n.cyberPrivacy) {
      if (!n.cyberPrivacyPrice) n.cyberPrivacyPrice = 25000;
      if (n.licensedAdmin) {
        n.cyberPrivacy = false;
        n.cyberPrivacyPrice = false;
      }
    }

    saveQuote(n);
  };

  handleCoupon = (coupon) => {
    coupon = this.state.coupon + " " + coupon;
    // console.log("coupon", coupon);
    this.setState({ coupon });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { quote } = this.props;
    if (!!quote.cyberPrivacy && !!quote.cyberPrivacyPrice) {
      if (!quote.unlicensedPlanners && !quote.lifeInsurance && !quote.iiroc && !quote.licensedAdmin && !quote.lifeInsuranceLite) {
      // if (!quote.unlicensedPlanners && !quote.lifeInsurance && !quote.licensedAdmin) {
        this.handleModalShow("cyberflag");
        return;
      }
    }
    this.setState({ isLoading: true });
    try {
      this.setState({ isLoading: false });
      this.props.history.push(this.state.nextPage);
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { steps, currentStep, prevPage, isLoading } = this.state;
    const { locale } = this.props;

    return (
      <Container className="Coverages" as="section">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={steps} currentStep={currentStep} />
            <div className="formDescription">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale,'Get a Quote')} </h1>
                <PricingDetails />
              </div>
              <h3>{translateString(locale,'Coverages')}</h3>
              <p>{translateString(locale,'Please select the coverage(s) you need from the choices below:')}</p>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="coveragesFields">
                <PrintFormGroup parent="coverages" data={coverages} />
              </div>
              {1 === 2 && (
                <div className="cyberPrivacyFields Line Green top p10">
                  <div className="description">
                    <p className="italic text-sm">
                    {translateString(locale,'Basic Cyber coverage ($25,000 limit) is included at no additional cost with all policies, except for Licensed Administrative Assistants. For an additional premium, higher coverage can be purchased.')}
                    </p>
                  </div>
                  <PrintFormGroup parent="cyber" data={cyber} />
                </div>
              )}
              <FormBottomNav
                disabled={!this.validateForm(this.props.quote)}
                isLoading={isLoading}
                prevText={translateString(locale,"Go Back")}
                prevPage={prevPage}
                className="Line Green top"
                required={["Coverage"]}
              />
            </form>
          </Col>
          <Col lg={"auto"} className="quotePricing sideBar">
            <Coupon handleCoupon={() => this.handleCoupon} />
            <PricingDetails />
          </Col>
        </Row>
        <GeneralModal show={this.state.Modals[0].cyberflag}>
          <p> 
            {translateString(locale,'Please note Security & Privacy is not offered as a standalone insurance product.')}</p>
            {translateJSXElement(locale, 'coverages.information')}
          <Button
            variant="primary"
            onClick={(e) => {
              this.handleModalClose("cyberflag", e);
            }}>
            {translateString(locale,'OK')}
          </Button>
        </GeneralModal>
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchPricing,
})(QuoteCoverages);
